<template>
  <slot />
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppTableAccordion",
  components: {},
  props: {}
});
</script>

<style scoped></style>

import { ILocationPackagesSort } from "@/hooks/esim/location-packages/types/locationPackage.types";
import { sortOrders } from "@/enums/main/sortOrders";
import { ComputedRef } from "vue";

export const getLocationPackagesSortConfig = (
  currentSortKeyLocationName: ComputedRef<string>
): ILocationPackagesSort => ({
  byLocationName: {
    order: sortOrders.asc,
    keyName: currentSortKeyLocationName.value,
    keyNameInModel: "name"
  },

  byUpdatedAt: {
    order: sortOrders.turnOff,
    keyName: "updatedAt",
    keyNameInModel: "updatedBy.fullName"
  }
});

import { Commit } from "vuex";
import { api } from "@/api/Api";
import { IEsimProfile } from "@/api/services/esim/profile/profile.types";

export interface esimProfilesState {
  esimList: IEsimProfile[];
  profilesTotal: number;
}

const initialState: esimProfilesState = {
  esimList: [],
  profilesTotal: 0
};

export const esimProfilesModule = {
  state(): esimProfilesState {
    return {
      ...initialState
    };
  },

  mutations: {
    setEsimProfiles(state: esimProfilesState, dataTypes: IEsimProfile[]) {
      state.esimList = dataTypes;
    },
    setProfilesTotal(state: esimProfilesState, total: number) {
      state.profilesTotal = total;
    }
  },

  getters: {
    esimProfilesList: ({ esimList }: esimProfilesState): IEsimProfile[] =>
      esimList,
    profilesTotal: ({ profilesTotal }: esimProfilesState): number =>
      profilesTotal
  },
  actions: {
    async fetchEsimProfilesList(
      { commit }: { commit: Commit },
      userId: number
    ) {
      const { success, message, data } = await api.getUserEsimProfileList(
        userId
      );
      if (data) {
        commit("setEsimProfiles", data);
        commit("setProfilesTotal", data.length);
      }
      return { success, message };
    }
  }
};

import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from "@/styles/main.scss";
import vClickOutside from "@/libs/vClickOutside";
import VueNotificationList from "@dafcoe/vue-notification";
import "@dafcoe/vue-notification/dist/vue-notification.css";

router.beforeEach((to, _, next) => {
  window.scrollTo(0, 0);
  const authorized: boolean = store.getters.authorized;

  if (authorized) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});

const app = createApp(App);
app.use(store);
app.use(VueNotificationList);
app.use(router);

app.config.globalProperties.styles = styles;
app.directive("clickOutside", vClickOutside);
app.mount("#app");

import { useBaseAppLayout } from "@/hooks/layout/useBaseAppLayout";
import {
  locationStatuses,
  TLocationStatus
} from "@/api/services/locations/locationTypes";
import { Ref, ref, watch } from "vue";
export interface ILocationTableOptions {
  initialSearchQuery: string;
}

const filterOptions = [
  locationStatuses.all,
  locationStatuses.active,
  locationStatuses.disabled
];

export function locationsRoot({ initialSearchQuery }: ILocationTableOptions) {
  const selectedFilterValue: Ref<TLocationStatus> = ref(filterOptions[0]);

  const layoutRefs = useBaseAppLayout({
    initialSearchQuery,
    filterOptions: filterOptions
  });

  watch(
    () => layoutRefs.selectedFilterOption.value,
    option => {
      if (option) {
        selectedFilterValue.value = option.name as TLocationStatus;
      }
    },
    { deep: true, immediate: true }
  );

  return {
    filterOptions,
    selectedFilterValue,
    ...layoutRefs
  };
}

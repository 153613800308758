import { apiRequestType, IApiResponse } from "@/api/interfaces/IApi";
import {
  INotes,
  INotesData,
  INotesResponse
} from "@/api/interfaces/notes/common";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import { notesQuery } from "@/hooks/notes/useNotes";
import {
  ChangeStatusNoteDto,
  CreateNoteDto
} from "@/store/interfaces/notes/CreateNote.dto";

export const notesService = {
  async fetchNotesList(
    request: apiRequestType,
    query: notesQuery,
    id: number
  ): Promise<IApiResponse> {
    const parsed = queryObjectToUrlString(query);
    const response = await request(`/notes/${id}?${parsed}`, "get");
    const data = <INotes>response.data;
    return { ...response, data };
  },
  async addNotes(
    request: apiRequestType,
    id: number,
    reqData: CreateNoteDto
  ): Promise<IApiResponse> {
    const response = await request(`/notes/${id}`, "post", reqData);
    const data = <INotes>response.data;
    return { ...response, data };
  },
  async changeStatusNotes(
    request: apiRequestType,
    id: number,
    noteId: number,
    reqData: ChangeStatusNoteDto
  ): Promise<IApiResponse> {
    const response = await request(`/notes/${id}/${noteId}`, "put", reqData);
    const data = <INotes>response.data;
    return { ...response, data };
  },
  async deleteNote(
    request: apiRequestType,
    id: number,
    noteId: number
  ): Promise<IApiResponse> {
    const response = await request(`/notes/${id}/${noteId}`, "delete");
    const data = <INotes>response.data;
    return { ...response, data };
  }
};

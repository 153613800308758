
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { CreateRefundDto } from "@/store/interfaces/payments/CreateRefund.dto";
export default defineComponent({
  name: "CreateRefundModal",
  components: { AppButton, Dialog },
  inheritAttrs: false,
  props: {
    opened: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    id: { type: Number, default: 0 },
    data: {
      type: Object as PropType<CreateRefundDto>,
      required: true
    },
    photo: {
      type: String,
      required: false
    },
    title: { type: String, required: true },
    isShow: { type: Boolean, default: true }
  },

  emits: ["confirm", "delete"],

  computed: {
    avatar() {
      return this.photo;
    }
  }
});

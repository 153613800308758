import { routes } from "@/router/routes";

export const router = {
  state() {
    return {
      routes: [
        ...routes.map(route => {
          return { ...route, shown: false };
        })
      ]
    };
  },

  getters: {
    routes: state => state.routes
  },

  mutations: {
    resetRoutes(state) {
      state.routes = [
        ...routes.map(route => {
          return { ...route, shown: false };
        })
      ];
    },

    setActiveRoute(state, { matched }) {
      const path = matched[0].path;

      state.routes.forEach(route => {
        const isActive = route.path.includes(path);

        route.active = isActive;

        if (isActive && route.parentId) {
          let activeChild = state.routes.find(
            r => r.parentId === route.parentId
          );

          let isEnd = false;
          let parents = [];

          while (!isEnd) {
            const parent = state.routes.find(
              r => activeChild.parentId === r.id
            );

            if (parent) {
              parents.push(parent);
              activeChild = parent;
            } else {
              isEnd = true;
            }
          }

          parents.forEach(p => {
            p.active = true;
          });
        }
      });
    }
  }
};

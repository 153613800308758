import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3be116fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-cell-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SortButton = _resolveComponent("SortButton")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: 
      `pseudo-table--cell pseudo-table--cell-size--${_ctx.size} ${
        _ctx.sort ? 'sortable' : ''
      }`
    
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sortHandler && _ctx.sortHandler(...args)))
  }), [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.name), 1),
    (_ctx.sort)
      ? (_openBlock(), _createBlock(_component_SortButton, {
          key: 0,
          sort: _ctx.sortOrder
        }, null, 8, ["sort"]))
      : _createCommentVNode("", true)
  ], 16))
}
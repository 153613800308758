
import { defineComponent, PropType } from "vue";
import { ITab } from "@/store/interfaces/common";
import AppTabsBody from "@/components/AppTabs/AppTabsBody.vue";
import AppTabsHead from "@/components/AppTabs/AppTabsHead.vue";

export default defineComponent({
  name: "AppTabs",
  components: { AppTabsHead, AppTabsBody },
  props: {
    tabs: { type: Array as PropType<ITab[]>, required: true },
    modelValue: { type: Number, default: 0 },
    animate: { type: Boolean, default: true },
    isMargin: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],

  data() {
    return {
      selectedTabIdx: this.modelValue
    };
  },

  computed: {
    tabsHead() {
      return this.tabs.map(({ title }: ITab) => title);
    }
  },

  watch: {
    selectedTabIdx(idx: number) {
      if (idx !== this.modelValue) {
        this.$emit("update:modelValue", idx);
      }
    }
  }
});

<template>
  <div class="inline-accordion" :class="{ opened }">
    <div
      class="inline-accordion--heading d-flex"
      :class="`${headingInnerClassnames}`"
      @click="toggle"
    >
      <span class="inline-accordion--heading-slot-content">
        <slot name="header" />
      </span>

      <SortButton />
    </div>

    <div class="inline-accordion--body">
      <CollapsableCore :opened="opened" :collapsed-height="0">
        <slot name="content" />
      </CollapsableCore>
    </div>
  </div>
</template>

<script>
import SortButton from "@/components/ui/SortButton";
import CollapsableCore from "@/components/ui/Collapsable/CollapsableCore";
export default {
  name: "InlineAccordion",
  components: { CollapsableCore, SortButton },
  props: {
    initialOpened: { type: Boolean, default: false },
    headingInnerClassnames: { type: String, default: "" }
  },
  emits: ["open", "hide"],
  data() {
    return {
      opened: false
    };
  },
  watch: {
    initialOpened(opened) {
      this.opened = opened;
    }
  },
  methods: {
    toggle() {
      const currentOpened = !this.opened;
      const event = currentOpened ? "open" : "hide";

      this.$emit(event);
      this.opened = currentOpened;
    }
  }
};
</script>

<style scoped lang="scss">
.inline-accordion {
  $iconWidth: 18px;
  &--heading {
    cursor: pointer;
    width: 100%;
    transition: 0.25s;

    &-slot-content {
      width: calc(100% - #{$iconWidth} - 10px);
    }

    &:hover {
      color: $color-primary-blue;
    }

    .icon {
      margin-left: auto;
    }
  }

  &.opened {
    .sort-button {
      transform: rotate(180deg);
    }
  }
}
</style>

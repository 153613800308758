<template>
  <DarkOverlay :shown="shown" @click="onClickOutside" />

  <div
    :class="{
      'tooltip-dropdown': true,
      disabled,
      shown
    }"
  >
    <div ref="dropdownTrigger" class="tooltip-dropdown-trigger" @click="toggle">
      <slot v-if="$slots.trigger" name="trigger" />
      <SortButton v-else :sort="shown ? sortOrders.asc : sortOrders.desc" />
    </div>

    <div
      ref="dropdownContent"
      class="tooltip-dropdown-content"
      :data-show="shown"
    >
      <div ref="dropdownContentWrapper" class="tooltip-dropdown-inner">
        <div class="arrow" data-popper-arrow>
          <img src="@/assets/img/tooltip-arrow.svg" alt="arrow" />
        </div>

        <div
          ref="tooltipScrollWrapper"
          class="tooltip-dropdown-inner__content"
          :style="{ ['max-height']: maxHeight, 'min-width': width, width }"
        >
          <slot v-if="shown" name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { sortOrders } from "@/enums/main/sortOrders";
import SortButton from "@/components/ui/SortButton";
import DarkOverlay from "@/components/ui/DarkOverlay";
import gsap from "gsap";

export default {
  name: "TooltipDropdown",
  components: { DarkOverlay, SortButton },
  props: {
    disabled: { type: Boolean, default: false },
    strategy: {
      type: String,
      validator: s => ["absolute", "fixed"].indexOf(s) !== -1,
      default: "absolute"
    },
    maxHeight: {
      type: String,
      validator: s => {
        if (!s.length) return true;
        return s.includes("px");
      },
      default: ""
    },
    width: {
      type: String,
      default: "300px"
    },
    position: {
      type: String,
      default: "bottom-end"
    },
    show: { type: Boolean, default: false }
  },
  emits: ["hide"],

  data() {
    return {
      sortOrders,
      popperInstance: null,
      shown: this.show
    };
  },

  computed: {
    dropdownContentWrapper() {
      return this.$refs.dropdownContentWrapper;
    }
  },

  watch: {
    show(newState) {
      this.shown = newState;
    },

    shown(isShown) {
      const duration = 350;

      if (this.popperInstance) {
        this.popperInstance?.update?.();
      }

      if (this.dropdownContentWrapper) {
        gsap.to(this.dropdownContentWrapper, {
          scale: isShown ? 1 : 0,
          duration: duration / 1000
        });
      }

      // if (isShown) {
      //   setTimeout(this.scrollToActive, duration);
      // }
    }
  },

  beforeUnmount() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  },

  mounted() {
    const {
      dropdownTrigger,
      dropdownContent,
      dropdownContentWrapper
    } = this.$refs;

    setTimeout(() => {
      this.popperInstance.update();
    }, 200);

    if (dropdownContentWrapper) {
      gsap.set(dropdownContentWrapper, {
        scale: 0
      });
    }

    if (dropdownTrigger && dropdownContent) {
      const options = {
        placement: this.position,
        strategy: this.strategy,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [20, 10]
            }
          },
          { name: "eventListeners", enabled: true },
          {
            name: "flip",
            options: {
              fallbackPlacements: []
            }
          }
        ]
      };

      this.popperInstance = createPopper(
        dropdownTrigger,
        dropdownContent,
        options
      );
    }
  },

  methods: {
    scrollToActive() {
      const { tooltipScrollWrapper } = this.$refs;

      if (!tooltipScrollWrapper) {
        return;
      }

      const options = tooltipScrollWrapper.querySelectorAll(
        "[data-tooltip-option]"
      );

      const $options = Array.from(options);

      if (!$options || !$options.length) {
        return;
      }

      const optionHeight = $options[0].getBoundingClientRect()?.height || 0;
      let activeIdx = 0;

      $options.forEach(($option, idx) => {
        if ($option.dataset.active === "true") {
          activeIdx = idx;
        }
      });

      const activeOptionYPosition = optionHeight * activeIdx;

      tooltipScrollWrapper.scrollTo({
        top: activeOptionYPosition,
        left: 0,
        behavior: "smooth"
      });
    },

    hide() {
      this.shown = false;
      this.$emit("hide");
    },

    toggle() {
      if (!this.disabled) {
        this.shown = !this.shown;
      }
    },

    onClickOutside() {
      this.hide();
    }
  }
};
</script>

<style scoped lang="scss">
.tooltip-dropdown-trigger {
  width: max-content;
}

.tooltip-dropdown {
  width: max-content;
  &.shown {
    z-index: 99999999999999;
  }
  &-trigger {
    width: max-content;
  }

  &.disabled {
    .tooltip-dropdown-trigger {
      cursor: default;

      * {
        cursor: inherit;
      }
    }
  }
  &-content,
  .tooltip-dropdown-inner {
    z-index: 99999;
  }
  .tooltip-dropdown-inner {
    box-shadow: $shadow-700;
    padding: 1rem;
    z-index: 99999;
    background-color: #fff;
    border-radius: 12px;
    position: relative;
    transform-origin: top right;
    transition: height 0.2s;

    &__content {
      overflow: auto;
      padding-bottom: 0;

      &::-webkit-scrollbar {
        width: 5px;
      }
    }
  }
}

.arrow {
  position: absolute;
  width: 30px;
  height: 10px;
  z-index: 9999;
  right: 20px;
  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.tooltip-dropdown-content {
  opacity: 0;
  pointer-events: none;
  &[data-popper-placement^="top"] {
    .tooltip-dropdown-inner {
      transform-origin: bottom right;
    }
    .arrow {
      bottom: -6px;
      img {
        transform: translate(-50%, 0) rotate(180deg);
      }
    }
  }

  &[data-popper-placement^="bottom"] .arrow {
    top: -10px;
  }

  &[data-popper-placement^="left"] .arrow {
    right: 20px;
  }
  &[data-popper-placement^="right"] .arrow {
    left: -20px;
  }

  &[data-show="true"] {
    opacity: 1;
    pointer-events: initial;
  }

  &[data-show="false"] {
    transform: scale(0);
    transition-duration: 0.2s;
  }
}
</style>


import FileUploader from "@/components/ui/FileUploader.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "AvatarUpload",
  components: { FileUploader },
  props: {
    title: {
      type: String,
      default: "We recommend to upload image with the following presets:"
    },
    size: { type: String, default: "160x160" },
    format: { type: String, default: "PNG" },
    placeholder: {
      type: [String, File],
      required: false,
      default: ""
    },
    direction: {
      type: String,
      validator: (d: string) => ["row", "column"].indexOf(d) !== -1,
      default: "row"
    }
  },
  emits: { fileChanged: null }
});

<template>
  <div class="package d-flex align-items-center justify-content-between">
    <div>
      <p v-if="title" class="package-description mt-1">
        {{ title }}
      </p>
    </div>
    <div class="d-flex">
      <AppButton
        type="primary-transparent"
        size="icon"
        @click="$emit('edit', id)"
      >
        <SvgIcon icon="pen" />
      </AppButton>

      <AppButton
        type="danger-transparent"
        size="icon"
        @click="$emit('delete', id)"
      >
        <SvgIcon icon="trash" />
      </AppButton>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
import AppButton from "@/components/ui/Buttons/AppButton";
export default {
  name: "EsimPackageCard",
  components: { AppButton, SvgIcon },
  props: {
    id: { type: [String, Number], required: true },
    title: { type: String, required: true }
  },
  emits: ["edit", "delete"]
};
</script>

<style scoped lang="scss">
.package {
  box-shadow: $shadow-700;
  border-radius: $border-radius;
  font-size: 1.07rem;
  padding: 0.5em 1.33em;

  position: relative;
  transform: translate3d(0, 0, 0);
}
</style>

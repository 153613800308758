<template>
  <div class="d-flex align-items-center justify-content-end">
    <AppButton
      ref="actionEdit"
      type="primary-transparent"
      size="icon"
      @click="editHandler"
    >
      <SvgIcon icon="pen" />
    </AppButton>

    <AppButton
      v-if="!hideButtonRemove"
      ref="actionDelete"
      type="danger-transparent"
      size="icon"
      @click="removeHandler"
    >
      <SvgIcon icon="trash" />
    </AppButton>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon.vue";
import AppButton from "./Buttons/AppButton.vue";

export default {
  name: "Status",
  components: { SvgIcon, AppButton },
  props: {
    userActions: {
      type: Boolean,
      default: false
    },
    hideButtonRemove: {
      type: Boolean,
      default: false
    },
    id: { type: Number, default: 0 }
  },
  emits: ["remove", "edit"],
  data() {
    return {};
  },
  methods: {
    removeHandler() {
      this.$emit("remove", this.id);
    },
    editHandler() {
      this.$emit("edit", this.id);
    }
  }
};
</script>

<style scoped lang="scss">
.actions {
  &__btns {
    display: flex;
  }
}
.status {
  font-size: 1rem;

  &-pen {
    color: $color-primary-blue;
  }
  &-delete {
    color: $color-danger;
  }

  &-false {
    color: $grey-100;
  }
}
</style>


import { defineComponent, PropType } from "vue";
import SortButton from "@/components/ui/SortButton.vue";
import { TableCellMixin } from "@/mixins/TableCellMixin";
import { sortItem } from "@/enums/main/sortOrders";

export default defineComponent({
  name: "TableHeadCell",
  components: { SortButton },
  mixins: [TableCellMixin],
  inheritAttrs: false,
  emits: { sort: null },
  props: {
    sort: {
      type: Object as PropType<sortItem | null>,
      default: null
    },

    onSort: {
      // todo: is deprecated. Will be removed. Use emit
      type: Function,
      required: false
    }
  },

  computed: {
    sortOrder() {
      return this.sort?.order || "";
    }
  },

  methods: {
    sortHandler(): void {
      if (this.sort) {
        this.$emit("sort", this.sort.keyName);
      }
    }
  }
});

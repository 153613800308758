import { IApiResponse } from "@/api/interfaces/IApi";
import { IZone } from "@/api/services/packages/esim/location-packages/types";
import { Store } from "vuex";

export interface editEsimPlanEsimServerDto {
  planId: number;
  serverId: number;
  typeId: number;
}
export async function updatePlanEsimServer(
  data: editEsimPlanEsimServerDto,
  value: string,
  store: Store<any>
): Promise<IApiResponse> {
  const { success, message } = await store.dispatch("changePlanEsimServer", {
    data: { ...data },
    value
  });

  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }

  return success;
}

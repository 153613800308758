
import SvgIcon from "../../../SvgIcon.vue";
import { defineComponent } from "vue";
import {
  countryCodes,
  getCountryByCode
} from "@/validator/utilles/getCountryCodes";
import TooltipDropdown from "@/components/ui/TooltipDropdown.vue";
import AppInput from "@/components/Form/AppInput.vue";
import CountryFlag from "@/components/ui/Country/CountryFlag.vue";

export default defineComponent({
  components: { AppInput, TooltipDropdown, CountryFlag, SvgIcon },
  props: {
    modelValue: { type: String, required: true },
    id: { type: String, required: true },
    label: { type: String, required: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
    countryISO: { type: String, default: "ua" }
  },

  emits: ["update:modelValue"],

  data() {
    return {
      selectedAreaWidth: 60,
      phone: "",
      activeCountryIso: "",
      codesList: countryCodes.map(({ iso, code }) => ({ iso, code }))
    } as {
      selectedAreaWidth: number;
      phone: string;
      codesList: { iso: string; code: string }[];
      activeCountryIso: string;
    };
  },

  computed: {
    activeCode(): string | null {
      return (
        this.codesList.find(({ iso }) => iso === this.activeCountryIso)?.code ||
        null
      );
    }
  },

  watch: {
    phone(phone: string) {
      let resPhone = "";
      if (phone.length) {
        resPhone = `${this.activeCode}${phone}`;
      }

      this.$emit("update:modelValue", resPhone);
    },

    activeCode: {
      handler() {
        this.$nextTick(() => {
          this.setSelectedAreaWidth();
        });
      },
      immediate: true
    },
    countryISO: {
      handler(data: string) {
        this.activeCountryIso = data;
      },
      immediate: true
    }
  },

  methods: {
    setSelectedAreaWidth() {
      const selectedArea: HTMLElement | null = this.$refs
        .selectedArea as HTMLElement;

      this.selectedAreaWidth =
        selectedArea?.getBoundingClientRect?.()?.width || 60;
    },

    changeHandler(value: string): void {
      this.phone = value;
    },

    selectCountryCode(iso: string) {
      this.activeCountryIso = iso;
    }
  }
});

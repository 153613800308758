import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: _ctx.editingData?.title || _ctx.editingData?.type,
    opened: _ctx.opened,
    onHide: _ctx.hide
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_AppInput, {
            id: "question-title",
            modelValue: _ctx.editData.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editData.title) = $event)),
            "input-type": "material",
            label: "Title",
            type: "text",
            name: "question-title"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_AppInput, {
              id: "category-description",
              modelValue: _ctx.editData.description,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editData.description) = $event)),
              label: "Description",
              "input-type": "material",
              "is-textarea": true,
              style: {"height":"215px"}
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createVNode(_component_AppButton, {
          loading: _ctx.loading,
          disabled: !_ctx.valid,
          type: "secondary",
          size: "xl",
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Edit ")
          ]),
          _: 1
        }, 8, ["loading", "disabled", "onClick"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "opened", "onHide"]))
}
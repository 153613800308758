<template>
  <transition name="fade">
    <div
      v-if="isShow"
      :class="`dark-overlay ${overlayIsShown ? 'shown' : ''}`"
    />
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "DarkOverlay",
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    isInsert: { type: [Boolean, null], default: null }
  },

  data() {
    return {
      isShow: false,
      insert: this.isInsert !== null ? this.isInsert : false,
      body: document.body,
      scrollTop: 0
    };
  },

  computed: {
    ...mapGetters(["overlayIsShown"])
  },
  watch: {
    shown(status) {
      this.isShow = status;

      if (status && this.isInsert === null) {
        this.insert = this.overlayIsShown;
      }

      if (!status) {
        if (!this.insert) {
          this.$store.dispatch("toggleOverlay", status);
        }
      } else {
        this.$store.dispatch("toggleOverlay", status);
      }
    }
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },

  beforeUnmount() {
    this.$store.dispatch("toggleOverlay", false);
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapMutations(["toggleOverlay"]),
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop;
    }
    // togglePageScroll(status) {
    //   const styles = {
    //     top: status ? `-${this.scrollTop}px` : 0,
    //     overflowY: status ? "scroll" : "auto",
    //     position: status ? "fixed" : "relative",
    //     width: "100%"
    //   };
    //
    //   Object.entries(styles).forEach(([prop, value]) => {
    //     this.body.style[prop] = value;
    //   });
    //
    //   if (status) {
    //     window.removeEventListener("scroll", this.handleScroll);
    //   } else {
    //     window.addEventListener("scroll", this.handleScroll);
    //   }
    //
    //   window.scrollTo({
    //     top: this.scrollTop,
    //     behavior: "instant"
    //   });
    // }
  }
};
</script>

<style lang="scss">
.overlay-overflow {
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.dark-overlay {
  background-color: rgba(#000, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  border-radius: inherit;
  z-index: $overlay;

  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>


import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import FullscreenLoader from "@/components/ui/FullscreenLoader.vue";
import EmptyBlock from "@/components/ui/EmptyBlock.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";

export default defineComponent({
  name: "AppTableController",
  components: { BtnContent, AppButton, EmptyBlock, FullscreenLoader },
  props: {
    tableLoading: {
      type: Boolean,
      default: false
    },

    useLoader: {
      type: Boolean,
      default: true
    },

    showTable: {
      type: Boolean,
      default: true
    },

    searchQuery: {
      type: String,
      default: ""
    },

    emptyKey: {
      type: String,
      required: true
    },

    emptyButtonText: { type: String, default: "" },

    emptyIcon: {
      type: String,
      default: ""
    }
  },
  emits: {
    emptyButtonClick: null
  },

  data() {
    return {
      tableLoad: false,
      loadTimeout: null as ReturnType<typeof setTimeout> | null
    };
  },

  watch: {
    getTableLoad: {
      handler(data) {
        this.loadTimeout && clearTimeout(this.loadTimeout);
        this.loadTimeout = setTimeout(
          () => {
            this.tableLoad = data;
          },
          data ? 0 : 500
        );
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(["appLoading"]),
    emptyText(): string {
      if (!this.searchQuery) {
        return `There are no ${this.emptyKey} created yet!`;
      }

      return `No ${this.emptyKey} found by request "${this.searchQuery}"`;
    },
    getTableLoad() {
      return !this.showTable || this.tableLoading || this.appLoading;
    }
  }
});

import { Commit } from "vuex";
import { api } from "@/api/Api";
import { IApiResponse, IBaseQuery } from "@/api/interfaces/IApi";
import { IBrandsQuery, IDevice } from "./interfaces/suported-device/IDevice";
import { IBrand } from "./interfaces/suported-device/IBrand";
import {
  IBrandsResponse,
  IDevicesResponse
} from "@/api/interfaces/suported-devices/common";
import { AddDeviceDto } from "@/api/interfaces/suported-devices/devices.dto";
import { brandRequest, IBrands } from "@/api/interfaces/brands/IBrands";

export interface suportedDevicesState {
  devicesList: IDevice[];
  totalDevicesCount: number;
  totalBrandsCount: number;
  brandsList: IBrand[];
}
export const suportedDevicesModule = {
  state(): suportedDevicesState {
    return {
      devicesList: [],
      totalDevicesCount: 0,
      totalBrandsCount: 0,
      brandsList: []
    };
  },

  getters: {
    devicesList: ({ devicesList }: suportedDevicesState): IDevice[] => {
      return [...devicesList];
    },
    brandsList: ({ brandsList }: suportedDevicesState): IBrand[] => {
      return [...brandsList];
    },
    totalDevicesCount: ({ totalDevicesCount }: suportedDevicesState): number =>
      totalDevicesCount,
    totalBrandsCount: ({ totalBrandsCount }: suportedDevicesState): number =>
      totalBrandsCount
  },

  mutations: {
    setDevices(state: suportedDevicesState, devices: IDevice[]) {
      state.devicesList = devices;
    },
    setBrands(state: suportedDevicesState, brands: IBrand[]) {
      state.brandsList = brands;
    },
    setTotalDevices(state: suportedDevicesState, total: number) {
      state.totalDevicesCount = total;
    },
    setTotalBrands(state: suportedDevicesState, total: number) {
      state.totalBrandsCount = total;
    }
  },

  actions: {
    async fetchDevicesList(
      { commit }: { commit: Commit },
      { query, id }: { query: IBaseQuery; id: number }
    ): Promise<IDevicesResponse> {
      const response: IDevicesResponse = await api.fetchDevices(query, id);
      const { data } = response;
      if (data) {
        commit("setDevices", data.devices);
        commit("setTotalDevices", data.total);
      }

      return response;
    },
    async fetchBrandsList(
      { commit }: { commit: Commit },
      { query }: { query: IBrandsQuery }
    ): Promise<IBrandsResponse> {
      const response: IBrandsResponse = await api.fetchBrands(query);
      const { data } = response;
      if (data) {
        commit("setBrands", data.brands);
        commit("setTotalBrands", data.total);
      }

      return response;
    },
    async addDevice(
      { commit }: { commit: Commit },
      { query, file }: { query: AddDeviceDto; file: File }
    ): Promise<IApiResponse> {
      const response: IApiResponse = await api.addDevice(query, file);

      return response;
    },

    async editDevice(
      { commit }: { commit: Commit },
      { query, id, file }: { query: AddDeviceDto; id: number; file: File }
    ): Promise<IApiResponse> {
      const response: IApiResponse = await api.editDevice(query, id, file);

      return response;
    },

    async removeDevice(
      { commit }: { commit: Commit },
      { id }: { id: number }
    ): Promise<IApiResponse> {
      const response: IApiResponse = await api.removeDevice(id);

      return response;
    },
    async addBrand(
      { commit }: { commit: Commit },
      { data: reqData, file: file }: { data: IBrands; file: any }
    ): Promise<IApiResponse> {
      const response: any = await api.addBrand(reqData, file);

      return response;
    },

    async editBrand(
      { commit }: { commit: Commit },
      { data: reqData, id }: { data: IBrands; id: number }
    ): Promise<IApiResponse> {
      const response: any = await api.editBrand(reqData, id);

      return response;
    },

    async removeBrand(
      { commit }: { commit: Commit },
      { id }: { id: number }
    ): Promise<brandRequest> {
      const response: any = await api.removeBrand(id);
      return response;
    }
  }
};

<template>
  <label ref="textarea-wrapper" class="d-block textarea-wrapper">
    <AppInput
      :id="id"
      v-model="val"
      rows="1"
      class="autosize"
      :is-textarea="true"
      :style="{
        height: `${fieldHeight}px`
      }"
      v-bind="$attrs"
      @input="onChange"
      @change="onChange"
    />
    <AppInput
      :id="id + 'hidden'"
      v-bind="$attrs"
      ref="hidden"
      v-model="val"
      :is-textarea="true"
      rows="1"
      class="hidden hidden-textarea"
      :hidden="true"
    />
  </label>
</template>

<script>
import AppInput from "@/components/Form/AppInput";
import { getId } from "@/utills/getId";
export default {
  name: "AutosizeTextarea",
  components: { AppInput },
  inheritAttrs: false,
  props: {
    modelValue: { type: String, default: "" },
    id: { type: String, default: getId("input") }
  },
  data() {
    return { fieldHeight: 0, val: this.modelValue };
  },
  computed: {
    hidden() {
      if (!this.$refs["textarea-wrapper"]) {
        return null;
      }

      return this.$refs["textarea-wrapper"].querySelector(".hidden-textarea");
    }
  },

  watch: {
    val() {
      this.$emit("update:modelValue", this.val);
      this.updateHeight();
    }
  },

  mounted() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  },
  methods: {
    onChange(evt) {
      this.$emit("update:modelValue", evt.target.value);
      this.updateHeight();
    },
    updateHeight() {
      if (this.hidden) {
        setTimeout(() => {
          this.fieldHeight = this.hidden.scrollHeight;
        }, 10);
      }
    }
  }
};
</script>

<style lang="scss">
.textarea-wrapper {
  position: relative;
  overflow: hidden;
}
textarea {
  resize: none;
  overflow: hidden;
  font-size: 1em;
  @extend %hideScrollBar;

  &.hidden {
    overflow: auto;
    @extend %hideScrollBar;
    opacity: 0 !important;
    position: absolute !important;
    pointer-events: none !important;
  }
}
</style>

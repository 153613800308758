export const statuses = {
  active: "active",
  inactive: "inactive",
  blocked: "blocked"
};

export const accountStatuses = {
  verified: "verified",
  unverified: "unverified",
  blocked: "blocked"
};

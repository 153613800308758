import { reversedStatusCodes } from "@/api/contants/statusCodes";
import { IObjectKeys } from "@/interfaces/IObjectKeys";

export const serverErrors: IObjectKeys = {
  credentialsNotValid: "Email or password is wrong",
  accountBlocked: "User account was blocked",
  error_occurred_when_login: "Error occurred when login",
  is_already_exist: "Is already exist",
  user_create_error: "Server error",
  code_expired_or_invalid: "Invalid code.",
  email_is_not_verified: "Email is not verified"
};

export function getErrorByStatusCode(code: string): string {
  const errType: string = reversedStatusCodes[code.toString()] || "";
  return (
    serverErrors[errType] || `Server error occurred with status code ${code}`
  );
}

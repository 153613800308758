import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionsTable = _resolveComponent("QuestionsTable")!
  const _component_DeleteBrandDialog = _resolveComponent("DeleteBrandDialog")!
  const _component_CreateQuestionDialog = _resolveComponent("CreateQuestionDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_QuestionsTable, {
      "table-title": 'Questions',
      "categories-list": _ctx.categoriesList,
      "questions-list": _ctx.questionsList,
      "total-rows-count": _ctx.totalQuestionCount,
      "view-configuration": _ctx.viewConfiguration,
      sort: _ctx.sortConfig,
      onChangeViewConfiguration: _ctx.changeViewConfigurationHandler,
      onChangeTab: _ctx.changeTabHandler,
      onOpenForm: _ctx.openForm,
      onRemovingQuestion: _ctx.removingQuestion,
      onEditingQuestion: _ctx.editingQuestion
    }, null, 8, ["categories-list", "questions-list", "total-rows-count", "view-configuration", "sort", "onChangeViewConfiguration", "onChangeTab", "onOpenForm", "onRemovingQuestion", "onEditingQuestion"]),
    _createVNode(_component_DeleteBrandDialog, {
      id: _ctx.editData.id,
      title: _ctx.editData.title,
      opened: _ctx.deleteQuestionDialogOpened,
      loading: _ctx.isLoading,
      "is-show": false,
      hide: false,
      onHide: _ctx.cleanData,
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.cleanData
    }, null, 8, ["id", "title", "opened", "loading", "onHide", "onConfirm", "onCancel"]),
    _createVNode(_component_CreateQuestionDialog, {
      title: _ctx.modalTitle,
      "current-category": _ctx.selectedTab,
      categories: _ctx.selectMenu,
      opened: _ctx.createQuestionDialogOpened,
      data: _ctx.editData?.id ? _ctx.editData : null,
      loading: _ctx.isLoading,
      onSubmit: _ctx.editAction,
      onOpenAddForm: _ctx.createQuestionDialogOpened,
      onSelectMenuItem: _ctx.selectCategory,
      onHide: _ctx.cleanData,
      onCancel: _ctx.cleanData
    }, null, 8, ["title", "current-category", "categories", "opened", "data", "loading", "onSubmit", "onOpenAddForm", "onSelectMenuItem", "onHide", "onCancel"])
  ], 64))
}
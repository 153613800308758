<template>
  <DarkOverlay :shown="opened" @click="opened = false" />

  <div class="choose-period-select" :class="{ opened }">
    <div class="choose-period-select--head" @click="opened = !opened">
      <div class="choose-period-select--icon">
        <svg-icon icon="calendar" />
      </div>

      <span class="choose-period-select--selected">{{ selectedDate }}</span>

      <SortButton :sort="opened ? 'b' : 'a'" />
    </div>
    <div class="choose-period-select--body">
      <ul ref="list" class="choose-period--list">
        <li
          v-for="{ checked, id, name } in options"
          :key="id"
          class="choose-period--list-item"
          :class="{ checked }"
          @click="checkHandler(id)"
        >
          <span>{{ name }}</span>
        </li>
      </ul>

      <div
        ref="parentEl"
        class="choose-period-calendar"
        :class="{
          opened: calendarOpened
        }"
        :style="{
          top: `${listHeight}px`
        }"
      >
        <svg
          id="calendar-bridge"
          width="21"
          height="91"
          viewBox="0 0 21 91"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_5131_790"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="21"
            height="91"
          >
            <path d="M0 0H21V91H0V0Z" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_5131_790)">
            <path
              d="M190 -97V-133C190 -139.627 184.627 -145 178 -145H23C16.3726 -145 11 -139.627 11 -133V-97V-8.5V4C11 10.6274 5.6274 16 -1 16H-353.5C-360.127 16 -365.5 21.3726 -365.5 28V414.5C-365.5 421.127 -360.127 426.5 -353.5 426.5H-1C5.6274 426.5 11 421.127 11 414.5V90C11 83.3726 16.3726 78 23 78H178C184.627 78 190 72.6274 190 66V-97Z"
              fill="white"
            />
          </g>
        </svg>

        <DatePicker
          :parent-el="parentEl"
          @init="calendarInitHandler"
          @open="calendarOpened = true"
          @close="calendarOpened = false"
          @select="d => (date = d)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DarkOverlay from "../DarkOverlay.vue";
import DatePicker from "./DatePicker.vue";
import SortButton from "../SortButton.vue";
import SvgIcon from "../SvgIcon.vue";
import gsap from "gsap";
import { useCalendar } from "@/mixins/useCalendar";
const BASE_TEXT = "Choose period";
export default {
  name: "ChoosePeriodSelect",
  components: { SvgIcon, SortButton, DatePicker, DarkOverlay },
  mixins: [useCalendar],
  emits: {
    filterChange: null
  },
  data() {
    return {
      opened: false,
      calendarOpened: false,
      selectedDate: BASE_TEXT,
      parentEl: null,
      listHeight: 0,
      options: [
        { id: "today", name: "Today", value: "today", checked: false },
        { id: "last-week", name: "Last week", value: "week", checked: false },
        {
          id: "last month",
          name: "Last month",
          value: "month",
          checked: false
        },
        { id: "custom", name: "Custom", value: "custom", checked: false }
      ]
    };
  },

  watch: {
    opened(opened) {
      this.toggle(opened);
    },
    calendarOpened(opened) {
      if (opened && this.calendarNode) {
        this.calendarNode.click();
      }
    },
    date(data) {
      if (data) {
        this.selectedDate = data.date;
        this.$emit("filterChange", {
          type: "custom",
          from: data.value.activeFrom,
          to: data.value.activeTo
        });
        this.opened = false;
      }
    }
  },
  mounted() {
    const { list } = this.$refs;
    this.parentEl = this.$refs.parentEl;
    if (list) {
      gsap.set(list, {
        height: this.opened ? "auto" : 0
      });
    }
  },
  methods: {
    checkHandler(id) {
      const selected = this.options.find(opt => opt.id === id);
      this.options.forEach(opt => {
        opt.checked = opt.id === id;
      });

      if (selected) {
        if (selected.id === "custom") {
          this.calendarOpened = true;
        } else {
          this.selectedDate = selected.name;
        }
      }
      if (selected.value !== "custom") {
        this.$emit("filterChange", {
          type: selected.value,
          from: "",
          to: ""
        });
        this.opened = false;
      }
    },
    toggle(opened = !this.opened) {
      const { list } = this.$refs;
      if (list) {
        gsap.to(list, {
          height: opened ? "auto" : 0,
          duration: 0.2,
          done: () => {
            setTimeout(() => {
              this.listHeight = parseInt(window.getComputedStyle(list).height);
            }, 200);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.choose-period-select {
  background: $color-contrast-light;
  width: 200px;
  border-radius: $border-radius;

  position: relative;
  box-shadow: $shadow-700;

  &.opened {
    transform: translate3d(0, 0, 0);
    z-index: $aboveOverlay;

    border-bottom: {
      left-radius: 0;
      right-radius: 0;
    }
  }
  &--head {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--head {
    display: flex;
    align-items: center;
    height: $field-height;
    cursor: pointer;
  }

  &--body {
    position: relative;
    width: 100%;
  }

  &--icon {
    margin-right: 10px;
  }

  &--selected {
    width: calc(100% - 1rem - 10px - 10px - 18px);
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.choose-period--list {
  overflow: hidden;
  position: absolute;
  top: 0;
  background: $color-contrast-light;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 99999;

  border-bottom: {
    left-radius: $border-radius;
    right-radius: $border-radius;
  }

  &-item {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: calc(1rem + 10px);
    cursor: pointer;

    font-size: 15px;
    line-height: 1;
    &.checked {
      color: $color-primary-blue;
    }

    @media (any-hover: hover) {
      transition: 0.2s;
      &:hover {
        color: $color-primary-blue;
      }
    }

    &:first-child {
      padding-top: 20px;
    }
    &:last-child {
      padding-bottom: 20px;
    }
  }
}

.choose-period-calendar {
  position: absolute;
  right: 100%;

  transform: translateY(-79px);
  transition: 0.35s;

  transform-origin: top right;

  width: 365px;
  height: 420px;

  .litepicker {
    border-top-right-radius: 0 !important;
    transform: translateY(16px);
  }

  &:not(.opened) {
    transform: translate(-20px, -80px) scale(0);
  }

  #calendar-bridge {
    position: absolute;
    top: 0;
    right: -10px;
    z-index: 99999;
  }
}
</style>

import { Store } from "vuex";
export const uploadEsimDirectoryImages = async (
  store: Store<any>,
  data: { type: string; file: File }
) => {
  const { success, message, data: resData } = await store.dispatch(
    "uploadEsimDirectoryImage",
    data
  );

  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }
  return resData.url;
};


import { IEsimServer } from "@/api/services/profiles/profiles.types";
import {
  esimDirectoriesModalsInterface,
  esimDirectoriesTitles,
  TEsimDirectoryTypes
} from "@/hooks/esim/directories/types/esimDirectories.types";
import { defineComponent, PropType } from "vue";
import AddValidityPerionModal from "./AddValidityPerionModal.vue";
import AddDirectoryModal from "./AddDirectoryModal.vue";
import AddDirectoryZones from "./AddDirectoryZones.vue";
import AddPlanModal from "./AddPlanModal.vue";
import AddEsimTypeModal from "./AddEsimTypeModal.vue";
import { IDataType } from "@/api/services/packages/esim/location-packages/types";
import AddDirectoryServers from "./AddDirectoryServers.vue";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import DeleteConfirmDialog from "@/components/ui/Modal/Dialog/instances/DeleteConfirmDialog.vue";
import AddTrafficAmountModal from "@/components/Packages/eSIM/Directory/AddTrafficAmountModal.vue";
export default defineComponent({
  name: "EsimDirectoryDialogsController",
  components: {
    AddTrafficAmountModal,
    AddValidityPerionModal,
    AddDirectoryModal,
    AddDirectoryZones,
    AddPlanModal,
    AddEsimTypeModal,
    AddDirectoryServers,
    DeleteConfirmDialog
  },
  props: {
    id: { type: Number as PropType<number | null>, default: null },
    editData: {
      type: Object as PropType<IEsimDirectoryDefault>,
      default: null
    },
    modalTitle: { type: String, default: "" },
    modals: {
      type: Object as PropType<esimDirectoriesModalsInterface>,
      required: true
    },
    type: { type: String as PropType<TEsimDirectoryTypes>, default: "" },
    esimServersAll: {
      type: Array as PropType<IEsimServer[]>,
      default: () => []
    },
    esimServersFree: {
      type: Array as PropType<IEsimServer[]>,
      default: () => []
    },
    dataTypes: {
      type: Array as PropType<IDataType[]>,
      default: () => []
    },
    uploader: {
      type: Function,
      default: () => {}
    }
  },
  emits: {
    submit: null,
    cleanData: null,
    uploadImage: null
  },
  computed: {
    title() {
      return esimDirectoriesTitles[this.type];
    },
    newEditData() {
      return this.editData;
    }
  },
  methods: {
    submitHandler(data: IEsimDirectoryDefault) {
      this.$emit("submit", data, this.id);
      this.cleanDataHandler();
    },
    cleanDataHandler() {
      this.$emit("cleanData");
    },
    uploadImageHandler(data: any) {
      return this.uploader(data);
    }
  }
});

<template>
  <div
    ref="scrollWrapper"
    class="scroll-wrapper"
    :style="`max-height: ${maxHeight}px`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "CustomScrollbar",
  props: {
    maxHeight: { type: Number, required: true },
    scrollbarOptions: { type: Object, default: () => ({}) }
  }
};
</script>

<style lang="scss">
.scroll-wrapper::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 100px;
}

.scroll-wrapper::-webkit-scrollbar {
  width: 3px;
  height: 0;
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background: $color-primary-blue;
  border-radius: 100px;
}

.scroll-wrapper {
  overflow: scroll;
  scrollbar-gutter: stable;
}
</style>

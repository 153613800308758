import { Store } from "vuex";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
export const esimDirectoryActions = async (
  store: Store<any>,
  data: IEsimDirectoryDefault,
  id: number | null,
  link: string
): Promise<boolean> => {
  await store.dispatch("setAppLoading", true);

  const { success, message } = await store.dispatch("actionWithDirectory", {
    data,
    id,
    link
  });
  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }

  await store.dispatch("setAppLoading", false);

  return success;
};

import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { computed, watch } from "vue";
import { ILocationsCtx } from "@/hooks/locations/new/useLocations";
import { TLocationKeys } from "@/store/locations";

const viewConfiguration: ITableViewConfiguration = {
  page: 1,
  perPage: 10
};

export function useGetLocations<T extends TLocationKeys>(
  ctx: ILocationsCtx<T>
) {
  const { store, tabs, searchQuery, selectedFilterValue } = ctx;

  const requestOptions = computed(() => {
    return {
      viewConfiguration: ctx.viewConfiguration,
      locationType: tabs.selectedTab,
      name: searchQuery,
      status: selectedFilterValue || ""
    };
  });

  async function requestLocations() {
    await store.dispatch("setAppLoading", true);

    if (!requestOptions.value.locationType?.value.title) return;

    const query: {
      page: number;
      perPage: number;
      extended: boolean;
      name?: string;
      status?: string;
    } = {
      ...requestOptions.value.viewConfiguration?.value,
      extended: true
    };

    const name = requestOptions.value.name;
    const status = requestOptions.value.status;

    if (status.value && status.value !== "all") {
      query.status = status.value;
    }

    if (name.value.length) {
      query.name = name.value;
    }

    const { success, message } = await store.dispatch("fetchLocationsList", {
      locationType: requestOptions.value.locationType?.value.title || "",
      query
    });

    await store.dispatch("setAppLoading", false);

    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }
  }

  watch(
    [() => requestOptions, () => ctx.viewConfiguration.value],
    requestLocations,
    {
      deep: true,
      immediate: true
    }
  );
  // requestLocations();
  return {
    requestLocations,
    viewConfiguration
  };
}

<template>
  <div
    :class="
      `nav-item d-flex align-items-center ${
        isTopLevel ? 'nav-item--top-level' : ''
      } `
    "
  >
    <svg-icon v-if="icon" :icon="icon" />
    <span class="nav-item-name">{{ name }}</span>
  </div>
</template>
<script>
import SvgIcon from "@/components/ui/SvgIcon";

export default {
  name: "NavItem",
  components: { SvgIcon },
  props: {
    icon: String,
    name: {
      type: String,
      required: true
    },
    isTopLevel: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-item {
  @include NavItem();
  transition: 0.25s;

  .icon {
    font-size: 1.4rem;
    & + {
      .nav-item-name {
        padding-left: 1.4em;
      }
    }
  }
}

.router-link-exact-active {
  .nav-item {
    @include NavItem(true);
  }
}
</style>

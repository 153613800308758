export const fileRender = file => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("File dont provided");
    }

    const reader = new FileReader();

    reader.onload = ({ target }) => {
      if (!target) {
        reject("Something wrong");
      }

      resolve(target.result);
    };

    reader.readAsDataURL(file);
  });
};

<template>
  <aside class="sidebar">
    <!--    <DarkOverlay />-->
    <div class="sidebar-content">
      <div class="sidebar-content--heading">
        <Logo />
      </div>

      <div class="sidebar-content--body">
        <NavigationList :navigation-list="showRoutes" />
      </div>
    </div>
  </aside>
</template>
<script>
import Logo from "@/components/ui/Logo";
import { mapGetters } from "vuex";
import NavigationList from "@/components/Navigation/NavigationList";
import DarkOverlay from "@/components/ui/DarkOverlay";

export default {
  name: "PageSidebar",
  // eslint-disable-next-line vue/no-unused-components
  components: { DarkOverlay, NavigationList, Logo },
  computed: {
    ...mapGetters(["routes"]),
    showRoutes() {
      return this.routes.filter(({ showInSidebar }) => showInSidebar);
    },
    _styles() {
      return this.styles;
    }
  },

  watch: {
    routes(v) {
      console.log("r", v);
    }
  }
};
</script>

<style lang="scss">
.sidebar {
  background: $gradient-primary;
  position: relative;
  a {
    color: inherit;
    @media (any-hover: hover) {
      transition: 0.25s font-weight;
      &:hover {
        font-weight: 600;
        color: inherit;
      }
    }
  }

  &-content {
    max-height: 100vh;
    position: sticky;
    top: 0;
    color: $color-contrast-light;
    overflow: auto;
    @extend %hideScrollBar;

    &--heading {
      background: $dark-bg;

      display: flex;
      align-items: center;

      height: $header-height;
      padding: 10px $sidebar-inner-padding-x * 2;
    }

    &--body {
      padding-left: $sidebar-inner-padding-x;
      padding-top: $main-content-padding-top;

      .router-link-active {
        @include NavItem(true);
        background: $active-menu-item-gradient;
        color: $color-contrast-dark !important;
      }
    }
  }
}
</style>

import { reactive } from "vue";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";

export type changeViewConfigurationType = (
  newConfiguration: ITableViewConfiguration
) => ITableViewConfiguration;

export type useTableViewConfigurationData = {
  configuration: ITableViewConfiguration;
  changeViewConfiguration: changeViewConfigurationType;
};

export function useTableViewConfiguration(
  initialViewConfiguration: ITableViewConfiguration
): useTableViewConfigurationData {
  const configuration: ITableViewConfiguration = reactive(
    initialViewConfiguration
  );

  return {
    configuration,
    changeViewConfiguration(newConfiguration: ITableViewConfiguration) {
      Object.entries(newConfiguration).forEach(([key, value]) => {
        configuration[key] = value;
      });

      return configuration;
    }
  };
}

export const packages = {
  state() {
    return {
      states: [
        "Alabama",
        "Alaska",
        "Arizona",
        "California ",
        "Colorado",
        "Connecticut",
        "Delaware"
      ].map(s => ({ id: s, name: s })),
      cities: [
        "Arvin",
        "Beverly Hills",
        "Culver City",
        "Kingsburg",
        "Los Angeles",
        "Mountain View"
      ].map(c => ({
        id: c,
        name: c
      })),

      packages: [
        {
          regionId: "usa",
          packageId: "pack/usa",
          packageName: "California, US",
          plans: [
            {
              planId: "pack/usa/plan1",
              planValidity: "1 month (one-time payment)",
              planType: "Landline Only",
              planServices: {
                minutes: 100,
                sms: 50
              },
              planDefaultPrice: "$ 45.99",
              planDiscountPercent: 10,
              planPriceWithDiscount: "$ 39,60",
              withRequiredVerification: true,
              planFeatures: [
                {
                  title: "Unlimited incoming Calls, SMS, MMS",
                  selected: true
                },
                {
                  title: "Unlimited Voicemail & Call recordings",
                  selected: true
                },
                {
                  title: "Outgoing Calls",
                  selected: true
                },
                {
                  title: "Outgoing SMS",
                  selected: true
                },
                {
                  title: "Outgoing MMS",
                  selected: true
                },
                {
                  title: "Outgoing FAX",
                  selected: false
                }
              ]
            },
            {
              planId: "pack/usa/plan2",
              planValidity: "2 month (one-time payment)",
              planType: "Landline Only",
              planServices: {
                minutes: 100,
                sms: 50
              },
              planServiceTypes: ["mobile", "landline"],
              planDefaultPrice: "$ 45.99",
              planDiscountPercent: 10,
              planPriceWithDiscount: "$ 39,60",
              withRequiredVerification: true,
              planFeatures: [
                {
                  title: "Unlimited incoming Calls, SMS, MMS",
                  selected: true
                },
                {
                  title: "Unlimited Voicemail & Call recordings",
                  selected: true
                },
                {
                  title: "Outgoing Calls",
                  selected: true
                },
                {
                  title: "Outgoing SMS",
                  selected: true
                },
                {
                  title: "Outgoing MMS",
                  selected: true
                },
                {
                  title: "Outgoing FAX",
                  selected: false
                }
              ]
            }
          ]
        }
      ]
    };
  },
  getters: {
    states: state => state.states,
    cities: state => state.cities,
    // eslint-disable-next-line no-unused-vars
    packagesByRegionId: state => regionId => {
      return state.packages;
    },
    // eslint-disable-next-line no-unused-vars
    plansByRegionId: state => regionId => {
      return state.packages[0]?.plans;
    }
  }
};


import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
interface EditData {
  id: number;
  title?: string;
  description: string;
  type?: string;
}
export default defineComponent({
  name: "EditAboutDialog",
  components: {
    AppButton,
    AppInput,
    Dialog
  },
  emits: {
    submit: null,
    hide: null,
    selectMenuItem: null
  },
  props: {
    // disabled: { type: Boolean, default: true },
    editingData: {
      type: Object as PropType<EditData>,
      required: false
    },
    opened: { type: Boolean, default: false },
    title: { type: String, default: "" },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  inheritAttrs: false,
  data() {
    return {
      editData: {} as EditData,
      avatar: "" as string | File,
      selectedBrand: 0 as number,
      valid: false as boolean
    };
  },
  computed: {
    editModalTitle() {
      return this.title;
    }
  },
  watch: {
    editingData(data) {
      if (data) {
        this.editData = { ...data };
      }
    },
    editData: {
      handler(data: EditData) {
        if (data?.title && data?.description !== "") {
          this.valid = true;
        }
      },
      deep: true
    }
  },
  methods: {
    submit() {
      this.$emit("submit", this.editData);
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    },
    hide() {
      // this.editData = {} as EditData;
      this.$emit("hide");
    },
    addDevice() {
      if (this.valid) {
        this.$emit("submit", {
          ...this.editData
        });
      }
    }
  }
});

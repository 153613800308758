
import { ComputedRef, defineComponent, PropType, toRefs, computed } from "vue";
import {
  ITableBodyRow,
  ITableHeadCell,
  ITableHeadTab
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AppToolbarTable from "@/components/Table/ts/AppToolbarTable.vue";
import {
  useTableWithOutViewConfiguration,
  useTableWithTabs
} from "@/hooks/tables/useTable";
import { IAbout } from "@/store/interfaces/about-product/IAbouts";
import { ITab } from "@/store/interfaces/common";

type tableHeadCells = ITableHeadCell[];

export type adminsTableSort = {
  byName: sortItem;
  byType: sortItem;
};

export default defineComponent({
  name: "AboutProductTable",
  components: { AppToolbarTable },

  emits: {
    changeTab: null,
    openAddForm: null,
    deleteDeviceAction: null,
    editDeviceAction: null
  },
  setup(props, { emit }) {
    const { byName, byType } = toRefs(props.sort);

    const initialTab = {
      id: 1,
      name: ""
    };

    const initialSortOptions: adminsTableSort = {
      byName: { order: sortOrders.turnOff, keyName: "title" },
      byType: { order: sortOrders.turnOff, keyName: "type" }
    };

    // table model
    const aboutList: ComputedRef<IAbout[]> = computed(() => props.aboutList);

    const tableRefs = useTableWithOutViewConfiguration({
      initialSortOptions,
      model: aboutList,
      generateRows(aboutList: IAbout[]): ITableBodyRow[] {
        return aboutList.map(
          (device: IAbout): ITableBodyRow => {
            const {
              id,
              // icon,
              title,
              type
            } = device;
            let sort;
            if (selectedTab.id === 3) {
              sort = {
                keyName: byType?.value.keyName,
                value: type,
                order: byType?.value.order
              };
            } else {
              sort = {
                keyName: byName?.value.keyName,
                value: title,
                order: byName?.value.order
              };
            }
            return {
              cells: [
                {
                  sort,
                  label: title || type
                },
                {},
                {},
                {},
                {
                  userActions: true
                }
              ],
              id
            };
          }
        );
      }
    });

    const tableWithTabsRefs = useTableWithTabs({
      initialTab,
      onTabChanged(tab: ITableHeadTab): void {
        emit("changeTab", tab);
      }
    });

    const { rows, changeSorting, viewConfiguration, currentSort } = tableRefs;

    const { changeTab, selectedTab } = tableWithTabsRefs;

    return {
      rows,
      changeTab,
      selectedTab,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    aboutList: {
      type: Array as PropType<IAbout[]>,
      required: true
    },

    tableTitle: {
      type: String,
      default: ""
    },

    hideButton: {
      type: Boolean,
      required: true
    },

    tabsList: {
      type: Object as PropType<ComputedRef<ITab[]>>,
      required: true
    },

    sort: {
      type: Object as PropType<adminsTableSort>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },
  data() {
    return {};
  },
  computed: {
    tableHeadTable() {
      const { byName, byType } = this.currentSort;
      let sort;
      if (this.selectedTab.id === 3) {
        sort = {
          keyName: byType.keyName,
          order: byType.order
        };
      } else {
        sort = {
          keyName: byName.keyName,
          order: byName.order
        };
      }
      return [
        {
          sort,
          size: "xl",
          label: "Title",
          id: "title"
        },
        {
          size: "xl",
          label: "",
          id: ""
        },
        {
          size: "md",
          label: "",
          id: ""
        },
        {
          size: "sm",
          label: "",
          id: ""
        },
        {
          size: "xs",
          label: "actions",
          id: "action"
        }
      ] as tableHeadCells;
    }
  },
  methods: {
    openForm() {
      this.$emit("openAddForm");
    },
    deleteDeviceAction(id: number) {
      this.$emit("deleteDeviceAction", id);
    },
    editDeviceAction(id: number) {
      this.$emit("editDeviceAction", id);
    }
  }
});

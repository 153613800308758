import { Commit, Dispatch } from "~~/vuex";
import { IUserDetailsData } from "@/store/interfaces/users/IUserShortData";
import { api } from "@/api/Api";
import { IUserDetailsResponse } from "@/api/interfaces/users/IUserDetailsResponse";
import { appStorage } from "@/storage/storage";

type userDateFieldType = string | number | null;
type userGetters = {
  currentUserData: IUserDetailsData;
  currentUserDataField(
    state: IUserDetailsData
  ): (fieldName: string) => userDateFieldType;
};

const id = appStorage.get("userId");

const initialState: IUserDetailsData = {
  email: null,
  fullName: null,
  id: id ? Number(id) : null,
  type: null,
  image: null
};

export const user = {
  state(): IUserDetailsData {
    return { ...initialState };
  },

  mutations: {
    setUserData(state: IUserDetailsData, data: IUserDetailsData | null): void {
      if (!data) {
        state = { ...initialState };
        return;
      }

      const id: number | null = data.id;
      appStorage.set("userId", id ? id.toString() : "");

      Object.entries(data).forEach(([key, value]) => {
        state[key] = value;
      });
    }
  },

  getters: {
    currentUserData(state: IUserDetailsData): IUserDetailsData {
      return state;
    },

    currentUserDataField(
      state: IUserDetailsData
    ): (fieldName: string) => userDateFieldType {
      return (fieldName: string): userDateFieldType => state[fieldName] || null;
    }
  },

  actions: {
    async fetchUserData({
      commit,
      getters,
      dispatch
    }: {
      commit: Commit;
      getters: userGetters;
      dispatch: Dispatch;
    }): Promise<IUserDetailsResponse> {
      const data: IUserDetailsData = getters.currentUserData;

      if (Object.values(data).every(data => data)) {
        return {
          success: true,
          status: { code: 0 },
          message: "",
          data
        };
      }

      if (!data.id) {
        return await dispatch("logout");
      }

      // fetch and return new data by user
      const {
        success,
        data: response,
        message,
        status
      } = await api.fetchUserData(data.id);

      const dataIUserDetailsResponse = <IUserDetailsData>response;
      commit("setUserData", { ...dataIUserDetailsResponse, id: data.id });

      return {
        success,
        status,
        message,
        data: dataIUserDetailsResponse
      };
    }
  }
};

import {
  ILocationPackage,
  ILocationPlan
} from "@/api/services/packages/esim/location-packages/types";
import { ITableBodyRow } from "@/components/Table/ts/interfaces/TableStructure";
import { dateConverter } from "@/utills/dateConverter";
import { infoWithImage } from "@/components/Table/ts/interfaces/common";

export const generateLocationPackagesRows = () => {
  return function(packagesList: ILocationPackage[] = []): ITableBodyRow[] {
    return packagesList.map(($package: ILocationPackage) => {
      const {
        name,
        icon = "",
        regionPlans,
        countryPlans,
        subregionPlans,
        createdAt,
        updatedBy
      } = $package;

      const locationPlans = regionPlans || countryPlans || subregionPlans;

      const packageCount: number = !locationPlans
        ? 0
        : locationPlans.reduce((acc: number, locationPlan: ILocationPlan) => {
            acc += locationPlan.plan.packages.length;
            return acc;
          }, 0);

      const createdDate: Date = new Date(createdAt);

      const updatedByCell: {
        infoWithImage?: infoWithImage;
        label?: string;
      } = {};

      if (updatedBy?.fullName) {
        const { fullName, accessImage: image } = updatedBy;

        updatedByCell.infoWithImage = {
          title: fullName,
          image
        };
      } else {
        updatedByCell.label = "-";
      }

      return {
        cells: [
          { country: { icon, name } },
          { label: `${packageCount} packages` },
          { label: dateConverter(createdDate) },
          { ...updatedByCell }
        ]
      };
    });
  };
};

<template>
  <div
    tabindex="0"
    class="form-control-wrapper"
    :data-focused="focused"
    @focusin="
      () => {
        childFocusHandler(true);
      }
    "
    @focusout="
      () => {
        childFocusHandler(false);
      }
    "
  >
    <svg-icon :icon="icon" />
    <FormControl
      v-bind="$attrs"
      :name="name"
      @input="evt => $emit('change', evt)"
      @focusin="
        () => {
          childFocusHandler(true);
        }
      "
      @focusout="
        () => {
          childFocusHandler(false);
        }
      "
    />
  </div>
</template>

<script>
import FormControl from "@/components/Form/FormControl";
import SvgIcon from "@/components/ui/SvgIcon";

export default {
  name: "FormControlWithIcon",
  components: { SvgIcon, FormControl },
  props: {
    icon: {
      type: String,
      required: true
    },
    name: { type: String, default: "" }
  },
  emits: {
    change: null
  },

  data() {
    return {
      focused: false
    };
  },

  methods: {
    childFocusHandler(status) {
      this.focused = status;
    }
  }
};
</script>

<style scoped lang="scss">
.form-control-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  &:focus {
    border: none;
    outline: none;
  }

  .form-control {
    font-size: inherit;
  }

  .icon {
    font-size: 1.15em;
    margin-right: 0.73em;
  }
}
</style>


import { defineComponent, PropType } from "vue";
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppTabsHead from "@/components/AppTabs/AppTabsHead.vue";
import AppTabsBody from "@/components/AppTabs/AppTabsBody.vue";
import { ITab } from "@/store/interfaces/common";

export default defineComponent({
  name: "TabsDialog",
  components: { AppTabsBody, AppTabsHead, Dialog },
  inheritAttrs: false,

  props: {
    opened: { type: Boolean, default: false },
    tabs: {
      type: Array as PropType<ITab[]>,
      required: true
    },

    modelValue: {
      type: Number,
      default: 0
    }
  },
  emits: ["hide", "update:modelValue"],
  data() {
    return {
      selectedTabIdx: this.modelValue
    };
  },

  computed: {
    tabsHead() {
      return this.tabs.map(({ title }: ITab) => title);
    }
  },

  watch: {
    selectedTabIdx(idx) {
      if (idx === this.modelValue) return;
      this.$emit("update:modelValue", idx);
    },

    modelValue(idx) {
      if (idx === this.selectedTabIdx) return;
      this.selectedTabIdx = idx;
    }
  },

  methods: {
    hide() {
      this.$emit("hide");
    }
  }
});


import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { categoriesData } from "@/hooks/faq/useFaq";
interface AddData {
  title: string;
  category: ICategories;
  icon: string | File;
  active: boolean;
}

interface EditData {
  id: number;
  title: string;
  description: string;
  category: categoryData;
  active: boolean;
}

interface categoryData {
  id: string;
  selected: boolean;
  title: string;
  name: string;
}

export default defineComponent({
  name: "CreateQuestionDialog",
  components: {
    AppButton,
    AppInput,
    Dialog,
    SelectInput,
    AppCheckbox
  },
  emits: {
    submit: null,
    hide: null,
    selectMenuItem: null
  },
  props: {
    // disabled: { type: Boolean, default: true },
    data: {
      type: Object as PropType<EditData>,
      required: false
    },
    opened: { type: Boolean, default: false },
    currentCategory: {
      type: Object as PropType<{ id: number }>,
      required: true
    },
    title: { type: String, default: "" },
    categories: { type: Array as PropType<categoriesData[]>, required: true },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  inheritAttrs: false,
  data() {
    return {
      editData: {} as EditData,
      avatar: "" as string | File,
      selectedBrand: 0 as number,
      valid: false as boolean
    };
  },
  computed: {
    editModalTitle() {
      return this.title;
    }
  },
  watch: {
    data(data: EditData): void {
      if (data) {
        this.editData = { ...data };
        this.selectCategoryHandler(this.currentCategory.id);
      } else {
        this.editData = {} as EditData;
      }
    },
    currentCategory: {
      handler(category) {
        this.selectCategoryHandler(category.id);
      },
      deep: true
    },
    editData: {
      handler(data: AddData) {
        if (
          data?.title?.length > 4 &&
          this?.categories.some(el => el.selected) &&
          data?.icon !== ""
        ) {
          this.valid = true;
        }
      },
      deep: true
    }
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    },
    hide() {
      this.editData = {} as EditData;
      this.$emit("hide");
    },
    selectCategoryHandler(id: number) {
      this.$emit("selectMenuItem", id);
    },
    action() {
      if (this.valid) {
        this.$emit(
          "submit",
          {
            ...this.editData,
            category: this.categories.find(categories => categories.selected)
              ?.id
          },
          this.editData.id
        );
      }
    }
  }
});

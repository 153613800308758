<template>
  <div ref="progressBar" :class="`progressbar progressbar-${type}`">
    <div class="progressbar-text">
      <span v-if="$slots.content"><slot name="content"/></span>
      <span v-else class="percent-text">{{ leftPercent }}<span class="percent">%</span>
      </span>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ProgressBar = require("progressbar.js");

export default {
  name: "CircleProgressbar",
  props: {
    // progress: { type: Number, default: 0 },
    count: { type: Number, required: true },
    total: { type: Number, required: true },
    strokeWidth: { type: Number, default: 5 },
    tailWidth: { type: Number, default: 2 },
    type: { type: String, default: "primary" },
    tailColor: { type: String, required: false },
    strokeFill: { type: String, required: false }
  },
  computed: {
    progress() {
      return (this.count * 100) / this.total / 100;
    },

    left() {
      return 1 - this.used;
    },
    leftPercent() {
      return parseInt(this.left * 100);
    },

    used() {
      return (this.count * 100) / this.total / 100;
    },

    usedPercent() {
      return this.used * 100;
    },

    colors() {
      return {
        strokeColor: this.strokeFill
          ? this.strokeFill
          : this.type === "primary"
          ? this.styles.primaryColor
          : this.styles.successColor,

        trailColor: this.tailColor
          ? this.tailColor
          : this.type === "primary"
          ? "#EAF2FD"
          : "#E9F7EF"
      };
    }
  },

  watch: {
    left() {
      this.updateProgress();
    }
  },

  unmounted() {
    setTimeout(() => {
      if (this.progressInstance) {
        this.progressInstance.destroy();
        this.progressInstance = null;
      }
    }, 200);
  },

  mounted() {
    const { progressBar } = this.$refs;
    this.progressInstance = new ProgressBar.Circle(progressBar, {
      strokeWidth: this.strokeWidth,
      easing: "easeInOut",
      duration: 1400,
      color: this.colors.strokeColor,
      trailColor: this.colors.trailColor ?? "#eee",
      trailWidth: this.tailWidth,
      svgStyle: {
        strokeLinecap: "round"
      }
    });
    if (this.total !== this.count) this.updateProgress();
  },

  methods: {
    updateProgress() {
      if (this.progressInstance) {
        this.progressInstance.animate(this.left);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.progressbar {
  position: relative;
  &-primary {
    color: $color-primary-blue;
  }

  &-secondary {
    color: $color-success;
  }
}

.progressbar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .percent {
    font-size: 0.625em;
  }
}
</style>

import { apiRequestType } from "@/api/interfaces/IApi";
import {
  IRefreshTokenResponse,
  refreshTokenDataType
} from "@/api/interfaces/ILoginResponse";

import { getTokenString } from "@/utills/getTokenString";

export async function refreshTokenService(
  request: apiRequestType,
  refreshToken: string
): Promise<IRefreshTokenResponse> {
  const response = await request("/auth/refresh", "get", {
    headers: {
      Authorization: getTokenString(refreshToken),
      isRefresh: true
    }
  });

  const data: refreshTokenDataType = <refreshTokenDataType>response.data || {
    token: null,
    refreshToken: null
  };

  return { ...response, data };
}

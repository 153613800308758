import { ValidatorResponse } from "@/validator/ValidatorResponse";
import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";
import {
  getValidationError,
  validationTypesEnum
} from "@/validator/errorMessages";

export function notEmptyString(value: string): IValidatorResponse {
  return new ValidatorResponse(
    value.length > 0,
    getValidationError("**", validationTypesEnum.required)
  );
}

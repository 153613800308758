
import { defineComponent } from "@vue/runtime-core";
import AppPaymentsTable from "@/components/Table/ts/AppPaymentsTable.vue";
import { usePayments } from "@/hooks/payments/usePayments";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import CreateRefundModal from "@/components/ui/Modal/Dialog/instances/CreateRefundModal.vue";
import { CreateRefundDto } from "@/store/interfaces/payments/CreateRefund.dto";

export default defineComponent({
  name: "Payments",
  components: {
    AppPaymentsTable,
    CreateRefundModal
  },

  props: {
    currentPage: { type: Number, default: 1 },
    perPage: { type: Number, default: 10 }
  },

  setup() {
    const store = useStore();
    const viewConfig = {
      paymentType: "All",
      status: "All",
      date: "",
      page: 1,
      perPage: 10
    };
    const filter = {
      type: "",
      from: "",
      to: ""
    };
    const options = {
      viewConfig,
      filter
    };
    const {
      requestPaymentsHandler,
      changeViewConfigurationHandler,
      groupByDate,
      createRefund,
      changeFilterHandler
    } = usePayments(options);
    watch(viewConfig, () => requestPaymentsHandler());
    requestPaymentsHandler();
    return {
      changeFilterHandler,
      requestPaymentsHandler,
      viewConfig,
      paymentsList: computed(() => store.getters.paymentList),
      paymentsTotal: computed(() => store.getters.totalPayment),
      changeViewConfigurationHandler,
      groupByDate,
      createRefund
    };
  },
  data() {
    return {
      refundData: {} as CreateRefundDto,
      refundDialog: false,
      isLoading: false,
      userImage: ""
    };
  },

  computed: {
    newData() {
      return this.groupByDate(this.paymentsList);
    }
  },
  methods: {
    openRefundModal(data: CreateRefundDto, photo: string) {
      this.refundData = data;
      this.refundDialog = true;
      this.userImage = photo;
    },
    async onRefundConfirm(data: CreateRefundDto) {
      this.isLoading = true;
      const success = await this.createRefund(data);
      if (success) {
        await this.clearData();
        await this.requestPaymentsHandler();
      }
      this.isLoading = false;
    },
    clearData() {
      this.refundData = {} as CreateRefundDto;
      this.refundDialog = false;
      this.userImage = "";
    }
  }
});

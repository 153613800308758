import { sorting } from "@/hooks/tables/useTable";
import { ITableHeadCell } from "./../../../../components/Table/ts/interfaces/TableStructure";
export const generateEsimDirectoryTableHead = (
  withStatus: boolean,
  currentSort: sorting
): ITableHeadCell[] => {
  const { byTitle, byStatus } = currentSort;
  const sortTitle = {
    keyName: byTitle.keyName,
    order: byTitle.order
  };
  if (withStatus) {
    return [
      {
        sort: sortTitle,
        size: "fluid",
        label: "Title",
        id: "title"
      },
      {
        size: "md",
        label: "status",
        id: "title"
      },
      {
        size: "xs",
        label: "actions",
        id: "action"
      }
    ];
  } else {
    return [
      {
        sort: sortTitle,
        size: "fluid",
        label: "Title",
        id: "title"
      },
      {
        size: "xs",
        label: "actions",
        id: "action"
      }
    ];
  }
};

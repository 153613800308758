import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse,
  IBaseQuery
} from "@/api/interfaces/IApi";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  IBrandsResponse,
  IDevicesResponse
} from "@/api/interfaces/suported-devices/common";
import {
  IBrandsQuery,
  IDevice,
  IDeviceBody
} from "@/store/interfaces/suported-device/IDevice";
import { IBrandsBody } from "@/store/interfaces/suported-device/IBrand";
import { AddDeviceDto } from "@/api/interfaces/suported-devices/devices.dto";

export const suportedDevicesService = {
  async fetchDevicesList(
    request: apiRequestType,
    query: IBaseQuery,
    id: number
  ): Promise<IDevicesResponse> {
    const queryString: string = queryObjectToUrlString(query);

    const response = await request(
      `/supported-devices/brand-devices-admin/${id}?${queryString}`,
      "get"
    );
    const data: IDeviceBody = <IDeviceBody>response.data;

    return {
      ...response,
      data
    };
  },
  async addDevice(
    request: apiRequestFileType,
    query: AddDeviceDto,
    file: File
  ): Promise<IApiResponse> {
    const queryString: string = queryObjectToUrlString(query);

    const response = await request(
      `/supported-devices/device?${queryString}`,
      "post",
      file,
      "file"
    );
    const data: IDeviceBody = <IDeviceBody>response.data;

    return {
      ...response,
      data
    };
  },
  async removeDevice(
    request: apiRequestType,
    id: number
  ): Promise<IApiResponse> {
    const response = await request(`/supported-devices/device/${id}`, "delete");
    const data: IDeviceBody = <IDeviceBody>response.data;

    return {
      ...response,
      data
    };
  },
  async editDevice(
    request: apiRequestFileType,
    query: AddDeviceDto,
    id: number,
    file: File
  ): Promise<IApiResponse> {
    const queryString: string = queryObjectToUrlString(query);
    const response = await request(
      `/supported-devices/device/${id}?${queryString}`,
      "patch",
      file,
      "file"
    );
    const data: IDeviceBody = <IDeviceBody>response.data;
    return {
      ...response,
      data
    };
  },
  async editDeviceWithOutFile(
    request: apiRequestType,
    query: AddDeviceDto,
    id: number
  ): Promise<IApiResponse> {
    const queryString: string = queryObjectToUrlString(query);

    const response = await request(
      `supported-devices/device/${id}?${queryString}`,
      "patch"
    );
    const data: IBrandsBody = <IBrandsBody>response.data;

    return {
      ...response,
      data
    };
  },
  async fetchBrandsList(
    request: apiRequestType,
    query: IBrandsQuery
  ): Promise<IBrandsResponse> {
    const queryString: string = queryObjectToUrlString(query);

    const response = await request(
      `/supported-devices/brands-admin?${queryString}`,
      "get"
    );
    const data: IBrandsBody = <IBrandsBody>response.data;

    return {
      ...response,
      data
    };
  }
};

import { graphic } from "echarts";

export function getChartGradient(from, to) {
  return new graphic.LinearGradient(0, 0, 0, 1, [
    {
      color: from,
      offset: 0
    },
    {
      color: to,
      offset: 1
    }
  ]);
}

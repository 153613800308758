import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";
import { ValidatorResponse } from "@/validator/ValidatorResponse";
import {
  getValidationError,
  validationTypesEnum
} from "@/validator/errorMessages";

export function number(value: string): IValidatorResponse {
  let status = parseInt(value) >= 0;

  if (status) {
    status = /[0-9]/.test(value);
  }

  return new ValidatorResponse(
    status,
    getValidationError("**", validationTypesEnum.number)
  );
}

export type CountryCodeType = {
  name: string;
  iso: string;
  code: string;
  priority: number;
  areaCodes: string[] | null;
};

export const countryCodes: CountryCodeType[] = [
  {
    name: "Afghanistan (‫افغانستان‬‎)",
    iso: "af",
    code: "93",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Albania (Shqipëri)",
    iso: "al",
    code: "355",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Algeria (‫الجزائر‬‎)",
    iso: "dz",
    code: "213",
    priority: 0,
    areaCodes: null
  },
  {
    name: "American Samoa",
    iso: "as",
    code: "1",
    priority: 5,
    areaCodes: ["684"]
  },
  {
    name: "Andorra",
    iso: "ad",
    code: "376",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Angola",
    iso: "ao",
    code: "244",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Anguilla",
    iso: "ai",
    code: "1",
    priority: 6,
    areaCodes: ["264"]
  },
  {
    name: "Antigua and Barbuda",
    iso: "ag",
    code: "1",
    priority: 7,
    areaCodes: ["268"]
  },
  {
    name: "Argentina",
    iso: "ar",
    code: "54",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Armenia (Հայաստան)",
    iso: "am",
    code: "374",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Aruba",
    iso: "aw",
    code: "297",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Ascension Island",
    iso: "ac",
    code: "247",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Australia",
    iso: "au",
    code: "61",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Austria (Österreich)",
    iso: "at",
    code: "43",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Azerbaijan (Azərbaycan)",
    iso: "az",
    code: "994",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Bahamas",
    iso: "bs",
    code: "1",
    priority: 8,
    areaCodes: ["242"]
  },
  {
    name: "Bahrain (‫البحرين‬‎)",
    iso: "bh",
    code: "973",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Bangladesh (বাংলাদেশ)",
    iso: "bd",
    code: "880",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Barbados",
    iso: "bb",
    code: "1",
    priority: 9,
    areaCodes: ["246"]
  },
  {
    name: "Belarus (Беларусь)",
    iso: "by",
    code: "375",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Belgium (België)",
    iso: "be",
    code: "32",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Belize",
    iso: "bz",
    code: "501",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Benin (Bénin)",
    iso: "bj",
    code: "229",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Bermuda",
    iso: "bm",
    code: "1",
    priority: 10,
    areaCodes: ["441"]
  },
  {
    name: "Bhutan (འབྲུག)",
    iso: "bt",
    code: "975",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Bolivia",
    iso: "bo",
    code: "591",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    iso: "ba",
    code: "387",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Botswana",
    iso: "bw",
    code: "267",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Brazil (Brasil)",
    iso: "br",
    code: "55",
    priority: 0,
    areaCodes: null
  },
  {
    name: "British Indian Ocean Territory",
    iso: "io",
    code: "246",
    priority: 0,
    areaCodes: null
  },
  {
    name: "British Virgin Islands",
    iso: "vg",
    code: "1",
    priority: 11,
    areaCodes: ["284"]
  },
  {
    name: "Brunei",
    iso: "bn",
    code: "673",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Bulgaria (България)",
    iso: "bg",
    code: "359",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Burkina Faso",
    iso: "bf",
    code: "226",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Burundi (Uburundi)",
    iso: "bi",
    code: "257",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Cambodia (កម្ពុជា)",
    iso: "kh",
    code: "855",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Cameroon (Cameroun)",
    iso: "cm",
    code: "237",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Canada",
    iso: "ca",
    code: "1",
    priority: 1,
    areaCodes: [
      "204",
      "226",
      "236",
      "249",
      "250",
      "289",
      "306",
      "343",
      "365",
      "387",
      "403",
      "416",
      "418",
      "431",
      "437",
      "438",
      "450",
      "506",
      "514",
      "519",
      "548",
      "579",
      "581",
      "587",
      "604",
      "613",
      "639",
      "647",
      "672",
      "705",
      "709",
      "742",
      "778",
      "780",
      "782",
      "807",
      "819",
      "825",
      "867",
      "873",
      "902",
      "905"
    ]
  },
  {
    name: "Cape Verde (Kabu Verdi)",
    iso: "cv",
    code: "238",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Caribbean Netherlands",
    iso: "bq",
    code: "599",
    priority: 1,
    areaCodes: ["3", "4", "7"]
  },
  {
    name: "Cayman Islands",
    iso: "ky",
    code: "1",
    priority: 12,
    areaCodes: ["345"]
  },
  {
    name: "Central African Republic (République centrafricaine)",
    iso: "cf",
    code: "236",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Chad (Tchad)",
    iso: "td",
    code: "235",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Chile",
    iso: "cl",
    code: "56",
    priority: 0,
    areaCodes: null
  },
  {
    name: "China (中国)",
    iso: "cn",
    code: "86",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Christmas Island",
    iso: "cx",
    code: "61",
    priority: 2,
    areaCodes: ["89164"]
  },
  {
    name: "Cocos (Keeling) Islands",
    iso: "cc",
    code: "61",
    priority: 1,
    areaCodes: ["89162"]
  },
  {
    name: "Colombia",
    iso: "co",
    code: "57",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Comoros (‫جزر القمر‬‎)",
    iso: "km",
    code: "269",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    iso: "cd",
    code: "243",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Congo (Republic) (Congo-Brazzaville)",
    iso: "cg",
    code: "242",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Cook Islands",
    iso: "ck",
    code: "682",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Costa Rica",
    iso: "cr",
    code: "506",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Côte d’Ivoire",
    iso: "ci",
    code: "225",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Croatia (Hrvatska)",
    iso: "hr",
    code: "385",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Cuba",
    iso: "cu",
    code: "53",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Curaçao",
    iso: "cw",
    code: "599",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Cyprus (Κύπρος)",
    iso: "cy",
    code: "357",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Czech Republic (Česká republika)",
    iso: "cz",
    code: "420",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Denmark (Danmark)",
    iso: "dk",
    code: "45",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Djibouti",
    iso: "dj",
    code: "253",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Dominica",
    iso: "dm",
    code: "1",
    priority: 13,
    areaCodes: ["767"]
  },
  {
    name: "Dominican Republic (República Dominicana)",
    iso: "do",
    code: "1",
    priority: 2,
    areaCodes: ["809", "829", "849"]
  },
  {
    name: "Ecuador",
    iso: "ec",
    code: "593",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Egypt (‫مصر‬‎)",
    iso: "eg",
    code: "20",
    priority: 0,
    areaCodes: null
  },
  {
    name: "El Salvador",
    iso: "sv",
    code: "503",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    iso: "gq",
    code: "240",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Eritrea",
    iso: "er",
    code: "291",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Estonia (Eesti)",
    iso: "ee",
    code: "372",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Eswatini",
    iso: "sz",
    code: "268",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Ethiopia",
    iso: "et",
    code: "251",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    iso: "fk",
    code: "500",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Faroe Islands (Føroyar)",
    iso: "fo",
    code: "298",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Fiji",
    iso: "fj",
    code: "679",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Finland (Suomi)",
    iso: "fi",
    code: "358",
    priority: 0,
    areaCodes: null
  },
  {
    name: "France",
    iso: "fr",
    code: "33",
    priority: 0,
    areaCodes: null
  },
  {
    name: "French Guiana (Guyane française)",
    iso: "gf",
    code: "594",
    priority: 0,
    areaCodes: null
  },
  {
    name: "French Polynesia (Polynésie française)",
    iso: "pf",
    code: "689",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Gabon",
    iso: "ga",
    code: "241",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Gambia",
    iso: "gm",
    code: "220",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Georgia (საქართველო)",
    iso: "ge",
    code: "995",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Germany (Deutschland)",
    iso: "de",
    code: "49",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Ghana (Gaana)",
    iso: "gh",
    code: "233",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Gibraltar",
    iso: "gi",
    code: "350",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Greece (Ελλάδα)",
    iso: "gr",
    code: "30",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Greenland (Kalaallit Nunaat)",
    iso: "gl",
    code: "299",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Grenada",
    iso: "gd",
    code: "1",
    priority: 14,
    areaCodes: ["473"]
  },
  {
    name: "Guadeloupe",
    iso: "gp",
    code: "590",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Guam",
    iso: "gu",
    code: "1",
    priority: 15,
    areaCodes: ["671"]
  },
  {
    name: "Guatemala",
    iso: "gt",
    code: "502",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Guernsey",
    iso: "gg",
    code: "44",
    priority: 1,
    areaCodes: ["1481", "7781", "7839", "7911"]
  },
  {
    name: "Guinea (Guinée)",
    iso: "gn",
    code: "224",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Guinea-Bissau (Guiné Bissau)",
    iso: "gw",
    code: "245",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Guyana",
    iso: "gy",
    code: "592",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Haiti",
    iso: "ht",
    code: "509",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Honduras",
    iso: "hn",
    code: "504",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Hong Kong (香港)",
    iso: "hk",
    code: "852",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Hungary (Magyarország)",
    iso: "hu",
    code: "36",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Iceland (Ísland)",
    iso: "is",
    code: "354",
    priority: 0,
    areaCodes: null
  },
  {
    name: "India (भारत)",
    iso: "in",
    code: "91",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Indonesia",
    iso: "id",
    code: "62",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Iran (‫ایران‬‎)",
    iso: "ir",
    code: "98",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Iraq (‫العراق‬‎)",
    iso: "iq",
    code: "964",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Ireland",
    iso: "ie",
    code: "353",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Isle of Man",
    iso: "im",
    code: "44",
    priority: 2,
    areaCodes: ["1624", "74576", "7524", "7924", "7624"]
  },
  {
    name: "Israel (‫ישראל‬‎)",
    iso: "il",
    code: "972",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Italy (Italia)",
    iso: "it",
    code: "39",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Jamaica",
    iso: "jm",
    code: "1",
    priority: 4,
    areaCodes: ["876", "658"]
  },
  {
    name: "Japan (日本)",
    iso: "jp",
    code: "81",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Jersey",
    iso: "je",
    code: "44",
    priority: 3,
    areaCodes: ["1534", "7509", "7700", "7797", "7829", "7937"]
  },
  {
    name: "Jordan (‫الأردن‬‎)",
    iso: "jo",
    code: "962",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Kazakhstan (Казахстан)",
    iso: "kz",
    code: "7",
    priority: 1,
    areaCodes: ["33", "7"]
  },
  {
    name: "Kenya",
    iso: "ke",
    code: "254",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Kiribati",
    iso: "ki",
    code: "686",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Kosovo",
    iso: "xk",
    code: "383",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Kuwait (‫الكويت‬‎)",
    iso: "kw",
    code: "965",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Kyrgyzstan (Кыргызстан)",
    iso: "kg",
    code: "996",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Laos (ລາວ)",
    iso: "la",
    code: "856",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Latvia (Latvija)",
    iso: "lv",
    code: "371",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Lebanon (‫لبنان‬‎)",
    iso: "lb",
    code: "961",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Lesotho",
    iso: "ls",
    code: "266",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Liberia",
    iso: "lr",
    code: "231",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Libya (‫ليبيا‬‎)",
    iso: "ly",
    code: "218",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Liechtenstein",
    iso: "li",
    code: "423",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Lithuania (Lietuva)",
    iso: "lt",
    code: "370",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Luxembourg",
    iso: "lu",
    code: "352",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Macau (澳門)",
    iso: "mo",
    code: "853",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Macedonia (FYROM) (Македонија)",
    iso: "mk",
    code: "389",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Madagascar (Madagasikara)",
    iso: "mg",
    code: "261",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Malawi",
    iso: "mw",
    code: "265",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Malaysia",
    iso: "my",
    code: "60",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Maldives",
    iso: "mv",
    code: "960",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Mali",
    iso: "ml",
    code: "223",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Malta",
    iso: "mt",
    code: "356",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Marshall Islands",
    iso: "mh",
    code: "692",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Martinique",
    iso: "mq",
    code: "596",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Mauritania (‫موريتانيا‬‎)",
    iso: "mr",
    code: "222",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Mauritius (Moris)",
    iso: "mu",
    code: "230",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Mayotte",
    iso: "yt",
    code: "262",
    priority: 1,
    areaCodes: ["269", "639"]
  },
  {
    name: "Mexico (México)",
    iso: "mx",
    code: "52",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Micronesia",
    iso: "fm",
    code: "691",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Moldova (Republica Moldova)",
    iso: "md",
    code: "373",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Monaco",
    iso: "mc",
    code: "377",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Mongolia (Монгол)",
    iso: "mn",
    code: "976",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Montenegro (Crna Gora)",
    iso: "me",
    code: "382",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Montserrat",
    iso: "ms",
    code: "1",
    priority: 16,
    areaCodes: ["664"]
  },
  {
    name: "Morocco (‫المغرب‬‎)",
    iso: "ma",
    code: "212",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Mozambique (Moçambique)",
    iso: "mz",
    code: "258",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Myanmar (Burma) (မြန်မာ)",
    iso: "mm",
    code: "95",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Namibia (Namibië)",
    iso: "na",
    code: "264",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Nauru",
    iso: "nr",
    code: "674",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Nepal (नेपाल)",
    iso: "np",
    code: "977",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Netherlands (Nederland)",
    iso: "nl",
    code: "31",
    priority: 0,
    areaCodes: null
  },
  {
    name: "New Caledonia (Nouvelle-Calédonie)",
    iso: "nc",
    code: "687",
    priority: 0,
    areaCodes: null
  },
  {
    name: "New Zealand",
    iso: "nz",
    code: "64",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Nicaragua",
    iso: "ni",
    code: "505",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Niger (Nijar)",
    iso: "ne",
    code: "227",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Nigeria",
    iso: "ng",
    code: "234",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Niue",
    iso: "nu",
    code: "683",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Norfolk Island",
    iso: "nf",
    code: "672",
    priority: 0,
    areaCodes: null
  },
  {
    name: "North Korea (조선 민주주의 인민 공화국)",
    iso: "kp",
    code: "850",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Northern Mariana Islands",
    iso: "mp",
    code: "1",
    priority: 17,
    areaCodes: ["670"]
  },
  {
    name: "Norway (Norge)",
    iso: "no",
    code: "47",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Oman (‫عُمان‬‎)",
    iso: "om",
    code: "968",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Pakistan (‫پاکستان‬‎)",
    iso: "pk",
    code: "92",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Palau",
    iso: "pw",
    code: "680",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Palestine (‫فلسطين‬‎)",
    iso: "ps",
    code: "970",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Panama (Panamá)",
    iso: "pa",
    code: "507",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Papua New Guinea",
    iso: "pg",
    code: "675",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Paraguay",
    iso: "py",
    code: "595",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Peru (Perú)",
    iso: "pe",
    code: "51",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Philippines",
    iso: "ph",
    code: "63",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Poland (Polska)",
    iso: "pl",
    code: "48",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Portugal",
    iso: "pt",
    code: "351",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Puerto Rico",
    iso: "pr",
    code: "1",
    priority: 3,
    areaCodes: ["787", "939"]
  },
  {
    name: "Qatar (‫قطر‬‎)",
    iso: "qa",
    code: "974",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Réunion (La Réunion)",
    iso: "re",
    code: "262",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Romania (România)",
    iso: "ro",
    code: "40",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Russia (Россия)",
    iso: "ru",
    code: "7",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Rwanda",
    iso: "rw",
    code: "250",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Saint Barthélemy",
    iso: "bl",
    code: "590",
    priority: 1,
    areaCodes: null
  },
  {
    name: "Saint Helena",
    iso: "sh",
    code: "290",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Saint Kitts and Nevis",
    iso: "kn",
    code: "1",
    priority: 18,
    areaCodes: ["869"]
  },
  {
    name: "Saint Lucia",
    iso: "lc",
    code: "1",
    priority: 19,
    areaCodes: ["758"]
  },
  {
    name: "Saint Martin (Saint-Martin (partie française))",
    iso: "mf",
    code: "590",
    priority: 2,
    areaCodes: null
  },
  {
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    iso: "pm",
    code: "508",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso: "vc",
    code: "1",
    priority: 20,
    areaCodes: ["784"]
  },
  {
    name: "Samoa",
    iso: "ws",
    code: "685",
    priority: 0,
    areaCodes: null
  },
  {
    name: "San Marino",
    iso: "sm",
    code: "378",
    priority: 0,
    areaCodes: null
  },
  {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    iso: "st",
    code: "239",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    iso: "sa",
    code: "966",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Senegal (Sénégal)",
    iso: "sn",
    code: "221",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Serbia (Србија)",
    iso: "rs",
    code: "381",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Seychelles",
    iso: "sc",
    code: "248",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Sierra Leone",
    iso: "sl",
    code: "232",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Singapore",
    iso: "sg",
    code: "65",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Sint Maarten",
    iso: "sx",
    code: "1",
    priority: 21,
    areaCodes: ["721"]
  },
  {
    name: "Slovakia (Slovensko)",
    iso: "sk",
    code: "421",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Slovenia (Slovenija)",
    iso: "si",
    code: "386",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Solomon Islands",
    iso: "sb",
    code: "677",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Somalia (Soomaaliya)",
    iso: "so",
    code: "252",
    priority: 0,
    areaCodes: null
  },
  {
    name: "South Africa",
    iso: "za",
    code: "27",
    priority: 0,
    areaCodes: null
  },
  {
    name: "South Korea (대한민국)",
    iso: "kr",
    code: "82",
    priority: 0,
    areaCodes: null
  },
  {
    name: "South Sudan (‫جنوب السودان‬‎)",
    iso: "ss",
    code: "211",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Spain (España)",
    iso: "es",
    code: "34",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Sri Lanka (ශ්‍රී ලංකාව)",
    iso: "lk",
    code: "94",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Sudan (‫السودان‬‎)",
    iso: "sd",
    code: "249",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Suriname",
    iso: "sr",
    code: "597",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Svalbard and Jan Mayen",
    iso: "sj",
    code: "47",
    priority: 1,
    areaCodes: ["79"]
  },
  {
    name: "Sweden (Sverige)",
    iso: "se",
    code: "46",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Switzerland (Schweiz)",
    iso: "ch",
    code: "41",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Syria (‫سوريا‬‎)",
    iso: "sy",
    code: "963",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Taiwan (台灣)",
    iso: "tw",
    code: "886",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Tajikistan",
    iso: "tj",
    code: "992",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Tanzania",
    iso: "tz",
    code: "255",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Thailand (ไทย)",
    iso: "th",
    code: "66",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Timor-Leste",
    iso: "tl",
    code: "670",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Togo",
    iso: "tg",
    code: "228",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Tokelau",
    iso: "tk",
    code: "690",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Tonga",
    iso: "to",
    code: "676",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Trinidad and Tobago",
    iso: "tt",
    code: "1",
    priority: 22,
    areaCodes: ["868"]
  },
  {
    name: "Tunisia (‫تونس‬‎)",
    iso: "tn",
    code: "216",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Turkey (Türkiye)",
    iso: "tr",
    code: "90",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Turkmenistan",
    iso: "tm",
    code: "993",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Turks and Caicos Islands",
    iso: "tc",
    code: "1",
    priority: 23,
    areaCodes: ["649"]
  },
  {
    name: "Tuvalu",
    iso: "tv",
    code: "688",
    priority: 0,
    areaCodes: null
  },
  {
    name: "U.S. Virgin Islands",
    iso: "vi",
    code: "1",
    priority: 24,
    areaCodes: ["340"]
  },
  {
    name: "Uganda",
    iso: "ug",
    code: "256",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Ukraine (Україна)",
    iso: "ua",
    code: "380",
    priority: 0,
    areaCodes: null
  },
  {
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    iso: "ae",
    code: "971",
    priority: 0,
    areaCodes: null
  },
  {
    name: "United Kingdom",
    iso: "gb",
    code: "44",
    priority: 0,
    areaCodes: null
  },
  {
    name: "United States",
    iso: "us",
    code: "1",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Uruguay",
    iso: "uy",
    code: "598",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Uzbekistan (Oʻzbekiston)",
    iso: "uz",
    code: "998",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Vanuatu",
    iso: "vu",
    code: "678",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Vatican City (Città del Vaticano)",
    iso: "va",
    code: "39",
    priority: 1,
    areaCodes: ["06698"]
  },
  {
    name: "Venezuela",
    iso: "ve",
    code: "58",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Vietnam (Việt Nam)",
    iso: "vn",
    code: "84",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Wallis and Futuna (Wallis-et-Futuna)",
    iso: "wf",
    code: "681",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Western Sahara (‫الصحراء الغربية‬‎)",
    iso: "eh",
    code: "212",
    priority: 1,
    areaCodes: ["5288", "5289"]
  },
  {
    name: "Yemen (‫اليمن‬‎)",
    iso: "ye",
    code: "967",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Zambia",
    iso: "zm",
    code: "260",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Zimbabwe",
    iso: "zw",
    code: "263",
    priority: 0,
    areaCodes: null
  },
  {
    name: "Åland Islands",
    iso: "ax",
    code: "358",
    priority: 1,
    areaCodes: ["18"]
  }
].sort((a, b) => {
  return a.iso > b.iso ? 1 : a.iso < b.iso ? -1 : 0;
});

export function getCountryByCode(code: string): CountryCodeType | null {
  debugger;
  return (
    countryCodes.find(
      ({ iso }) => iso.toLocaleLowerCase() === code.toLocaleLowerCase()
    ) || null
  );
}

import { Store, useStore } from "vuex";
import {
  ILocationsTabs,
  locationsTabs
} from "@/hooks/locations/new/modules/locations.tabs";
import { locationType } from "@/hooks/locations/new/modules/location.type";
import { locationsRoot } from "@/hooks/locations/new/modules/locations.root";
import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { TLocationStatus } from "@/api/services/locations/locationTypes";
import { useGetLocations } from "./modules/locations.get";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { ILocationsStateLists, TLocationKeys } from "@/store/locations";
import {
  locationTypeToSingle,
  packageLocationTypes,
  TPackageLocation,
  TPackageLocationSingle
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { useLocationsTable } from "./modules/locations.table";
import { updateLocation } from "./modules/location.update";
import { ITab } from "@/store/interfaces/common";

export interface ILocationsCtx<
  T extends TLocationKeys = packageLocationTypes.countries
> {
  tabs: ILocationsTabs;
  store: Store<any>;
  filterOptions: string[];
  searchQuery: Ref<string>;
  selectedFilterValue: Ref<TLocationStatus | null>;
  requestLocations?: () => void;
  viewConfiguration: Ref<ITableViewConfiguration>;
  locationsList: ComputedRef<ILocationsStateLists[T]>;
  onViewConfigChanged: (viewConfiguration: ITableViewConfiguration) => void;
  locationTypeSingle: ComputedRef<TPackageLocationSingle>;
}

export interface ILocationsOptions {
  initialSearchQuery?: string;
}

export function useLocations<T extends TLocationKeys>(opt: ILocationsOptions) {
  const { initialSearchQuery = "" } = opt;

  const tabs = locationsTabs();
  const store = useStore();
  const ctx: ILocationsCtx<T> = {
    tabs,
    store,
    locationTypeSingle: computed(() => {
      return locationTypeToSingle[
        tabs.selectedTab.value.title as keyof typeof packageLocationTypes
      ] as TPackageLocationSingle;
    }),
    filterOptions: [],
    searchQuery: ref(initialSearchQuery),
    selectedFilterValue: ref(null),
    onViewConfigChanged: () => {
      console.log("Change");
    },

    viewConfiguration: ref({ page: 1, perPage: 10 }),
    locationsList: computed(() => {
      return store.getters.getLocations(tabs.selectedTab.value.title);
    }) as ComputedRef<ILocationsStateLists[T]>
  };

  const root = locationsRoot({
    initialSearchQuery: initialSearchQuery
  });

  ctx.filterOptions = root.filterOptions;
  ctx.searchQuery = root.searchQuery;
  ctx.selectedFilterValue = root.selectedFilterValue;

  const locations = locationType(ctx);
  const { requestLocations, viewConfiguration } = useGetLocations(ctx);

  ctx.requestLocations = requestLocations;

  ctx.onViewConfigChanged = ({ page, perPage }: ITableViewConfiguration) => {
    viewConfiguration.page = page;
    viewConfiguration.perPage = perPage;

    ctx.viewConfiguration.value.page = page;
    ctx.viewConfiguration.value.perPage;
  };

  const tableRefs = useLocationsTable(ctx);
  const updateLocationRefs = updateLocation(ctx);

  watch(
    () => ctx.tabs.selectedTab.value,
    () => {
      tableRefs?.changeViewConfiguration({
        page: 1,
        perPage: viewConfiguration.perPage
      });
    },
    { deep: true }
  );

  return {
    ...ctx,
    ...locations,
    ...root,
    ...tableRefs,
    ...updateLocationRefs,
    totalLocations: computed(() => store.getters.totalLocations),
    changeTabHandler(tab: ITab) {
      ctx.tabs.selectedTab.value = { ...tab };
    },

    locationType: computed(
      () => tabs.selectedTab.value.title as TPackageLocation
    )
  };
}

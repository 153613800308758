<template>
  <label
    :for="id"
    :class="
      `d-flex settings-item settings-item--color-${colorScheme} ${
        disabled ? 'settings-item--disabled' : ''
      }`
    "
  >
    <span class="settings-item-text">
      <span v-if="!$slots.title" class="settings-item-title">{{ title }}</span>
      <span v-else class="settings-item-title">
        <slot name="title" />
      </span>
      <span v-if="description" class="settings-item-description">
        {{ description }}
      </span>
    </span>
    <AppSwitch :id="id" :disabled="disabled" v-bind="$attrs" />
  </label>
</template>

<script>
import AppSwitch from "@/components/Form/AppSwitch";
export default {
  name: "SettingsItem",
  components: { AppSwitch },
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    title: { type: String, required: false },
    description: { type: String, required: false },
    disabled: { type: Boolean, default: false },
    colorScheme: {
      type: String,
      default: "default",
      validator: sch => ["success", "danger", "warning", "primary"].indexOf(sch)
    }
  }
};
</script>

<style scoped lang="scss">
.settings-item {
  padding: 1.3em 0;
  cursor: pointer;
  border-bottom: 0.5px solid $border-color;
  &:last-child {
    border-bottom: 0;
  }
  &-text {
    padding-right: 1em;
    width: calc(100% - #{$switch-width});

    span {
      display: block;
    }
  }

  &-title {
    font-size: 1.1rem;
  }

  &-description {
    font-size: 0.857em;
    color: $grey-100;
    line-height: 1.2;
  }

  @each $type, $styles in $colorSchemaText {
    &--color-#{$type} {
      @include getStyles($styles);
    }
  }

  &--disabled {
    cursor: default;
    opacity: 0.8;
  }
}
</style>

export enum sortOrders {
  asc = "asc",
  desc = "desk",
  turnOff = ""
}

export const sortOrderNames = {
  asc: "asc",
  desc: "desc",
  turnOff: ""
};

export type sortOrder = sortOrders.turnOff | sortOrders.asc | sortOrders.desc;

export type sortItem = {
  order: sortOrder;
  keyName: string;
  keyNameInModel?: string;
};

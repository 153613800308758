
import { defineComponent, PropType } from "vue";
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";

interface IUpdateBundleModalData {
  valid: boolean;
  name: string;
}
export default defineComponent({
  components: {
    AppButton,
    Dialog,
    AppInput,
    AppInputsValidationProvider
  },

  props: {
    editData: {
      type: Object as PropType<{ name: string }>,
      required: true
    },
    opened: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  emits: {
    hide: null,
    submit: null
  },

  data() {
    return {
      name: "",
      valid: false
    } as IUpdateBundleModalData;
  },

  computed: {},

  watch: {
    editData: {
      immediate: true,
      handler({ name }: IUpdateBundleModalData) {
        this.name = name;
      }
    }
  },

  methods: {
    changeErrorState(error: boolean) {
      this.valid = !error;
    },

    submitHandler() {
      const { name } = this;

      this.$emit("submit", name);
    }
  }
});

import { badgeTypes } from "./../../../../components/ui/AppBadge/interfaces";
import { ITableBodyRow } from "./../../../../components/Table/ts/interfaces/TableStructure";
import { IEsimDirectoryDefault } from "./../../../../api/interfaces/esim-directory/common";
export const generateEsimDirectoriesRows = () => {
  return (directList: IEsimDirectoryDefault[] = []): ITableBodyRow[] => {
    return directList.map((list: IEsimDirectoryDefault) => {
      const { name, title, active, id } = list;
      let cells;
      if (typeof active === "boolean") {
        cells = [
          { label: title ?? name },
          {
            badge: {
              type: active ? badgeTypes.lightSecondary : badgeTypes.danger,
              label: active ? "Active" : "Inactive"
            }
          },
          { userActions: true }
        ];
      } else {
        cells = [{ label: title ?? name }, { userActions: true }];
      }
      return {
        cells,
        id
      };
    });
  };
};


import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import { defineComponent, PropType } from "vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
const initialData = () => ({
  title: "",
  period: 1,
  active: true,
  parentTouched: false,
  valid: false
});
export default defineComponent({
  name: "AddValidityPeriodModal",
  components: {
    AppButton,
    AppCheckbox,
    AppInput,
    Dialog,
    AppInputsValidationProvider
  },
  props: {
    id: { type: Number as PropType<number | null>, default: null },
    modalTitle: { type: String, default: "" },
    editData: {
      type: Object as PropType<IEsimDirectoryDefault | null>,
      required: true
    }
  },
  emits: {
    hide: null,
    submit: null
  },
  data() {
    return initialData();
  },
  watch: {
    period(data) {
      if (data < 1) {
        this.period = 1;
      } else if (data > 365) {
        this.period = 365;
      } else {
        this.period = data;
      }
    },
    editData: {
      handler(data: any) {
        Object.assign(this.$data, data);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cleanData() {
      Object.assign(this.$data, initialData());
      this.$emit("hide");
    },
    decrease() {
      if (Number(this.period) > 1) {
        this.period = Number(this.period) - 1;
      }
    },
    increase() {
      if (Number(this.period) < 366) this.period = Number(this.period) + 1;
    },
    async submitHandler() {
      const { active, title, period: period } = this;
      await this.cleanData();
      await this.$emit("submit", { active, title, period: Number(period) });
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    },
    hideHandler() {
      this.cleanData();
    }
  }
});

<template>
  <div class="content-box">
    <h2 class="mb-3">Account Status</h2>
    <SettingsItem
      id="account-status:active"
      v-model="active"
      title="Active"
      @click="activate"
    />
    <SettingsItem
      id="account-status:blocked"
      v-model="block"
      title="Blocked"
      description="User will not be able to use any of app features."
      @click="blockUser"
    />

    <button class="remove mt-4 mb-2" @click="$emit('delete')">
      Delete account
    </button>
  </div>
</template>

<script>
import SettingsItem from "@/components/Settings/SettingsItem";
export default {
  name: "AccountStatus",
  components: { SettingsItem },
  props: {
    emailVerified: { default: false, type: Boolean },
    blockedUser: { default: false, type: Boolean },
    userId: { default: -1, type: Number }
  },

  emits: "delete",
  data() {
    return {
      active: this.emailVerified,
      block: this.blockedUser
    };
  },
  computed: {},
  watch: {},
  methods: {
    async activate() {
      await this.$store.dispatch("setActive", {
        id: this.userId,
        param: !this.active
      });
    },
    async blockUser() {
      await this.$store.dispatch("setBlocked", {
        id: this.userId,
        param: !this.block
      });
    }
  }
};
</script>

<style scoped lang="scss">
button.remove {
  padding: 0;
  color: $color-danger;
  font-weight: 600;
  font-size: 1.08rem;

  @media (any-hover: hover) {
    transition: 0.2s;
    &:hover {
      color: rgba($color-danger, 0.7);
    }
  }
}
</style>

<template>
  <DarkOverlay :shown="shown" @click="toggle(false)" />

  <div :class="`more-dropdown ${shown ? 'more-dropdown--shown' : ''}`">
    <button class="more" @click="() => toggle()">
      <svg-icon icon="buttonMenu" />
      <MenuTop />
    </button>

    <ul class="more-dropdown-list">
      <li
        v-for="(button, idx) in buttonsList"
        :key="button.text"
        :class="`more-dropdown-item ${button.cls ? button.cls : ''}`"
        @click="clickOptionHandler(idx)"
      >
        {{ button.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
import MenuTop from "@/components/Table/MenuTop";
import { mapGetters, mapMutations } from "vuex";
import DarkOverlay from "@/components/ui/DarkOverlay";
export default {
  name: "MoreMenu",
  components: { MenuTop, SvgIcon, DarkOverlay },
  props: {
    buttonsList: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters(["settingsMenuShown"])
  },

  methods: {
    ...mapMutations(["settingsMenuToggle"]),
    toggle(status = !this.shown) {
      this.shown = status;
    },

    clickOptionHandler(btnInx) {
      const button = this.buttonsList[btnInx];
      button.onClick && button.onClick();
      this.shown = false;
    }
  },
  data() {
    return {
      shown: false
    };
  },

  watch: {
    settingsMenuShown(status) {
      if (!status) {
        this.shown = false;
      }
    }
  }
};
</script>

<style lang="scss">
.more-dropdown {
  position: relative;
  overflow: visible;
  z-index: 99999;

  @include aboveOverlay();
  &:not(.more-dropdown--shown) {
    z-index: 99;
  }
  &-list {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #fff;
    z-index: 99;
    border-radius: 12px 0 12px 12px;
    padding-top: 1.3rem;
    padding-bottom: 1rem;
    box-shadow: 1px 70px 35px 0 rgb(0 0 0 / 7%);
    transform: scale(0);
    transition: 0.25s;
    opacity: 0;
    pointer-events: none;
    transform-origin: top right;
  }

  &-item {
    padding: 0.5em 1.4em;
    cursor: pointer;
    white-space: nowrap;
    &.danger {
      color: $color-danger;
    }
  }

  .more {
    transition: 0.25s;
    color: $grey-100;
    height: 30px;
    width: $moreBtnWidth;
    position: relative;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 14px;
      margin: 0;
    }

    .icon {
      transition: 0.25s;
    }
    .menu-top {
      content: "";
      width: 115%;
      height: 100%;
      position: absolute;
      top: 0.2px;
      right: 0;
      //box-shadow: $shadow-700;
      z-index: -1;

      transform-origin: top;
      opacity: 0;
      transition-duration: 0.2s;
    }
  }
  &--shown {
    .more-dropdown-list {
      transform: scale(1);
      opacity: 1;
      pointer-events: initial;
    }

    .more {
      .icon {
        transform: rotate(90deg);
      }
      .menu-top {
        opacity: 1;
        transition-delay: 0.05s;
      }
    }
  }
}
.overlay-shown {
  .more-dropdown:not(.more-dropdown--shown) {
    .more {
      cursor: default;
    }
  }
}
</style>

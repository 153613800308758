<template>
  <div :class="`status status-${status}`">
    <svg-icon v-if="status === true" icon="checkmark" />
    <svg-icon v-else-if="status === false" icon="line" />
    <svg-icon
      v-else-if="status === 'danger'"
      icon="tick"
      class="color-danger"
    />
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "Status",
  components: { SvgIcon },
  props: {
    status: {
      type: [Boolean, String],
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.status {
  font-size: 1rem;

  &-true {
    color: $color-success;
  }

  &-false {
    color: $grey-100;
  }
}
</style>

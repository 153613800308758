<template>
  <DeleteUserDialog
    :opened="deleteUserDialogShown"
    user-email="sophie.turner@yahoo.com"
    user-name="Sophie Turner"
    :user-photo="require('@/assets/img/sophie.png')"
    @hide="deleteUserDialogShown = false"
  />
  <div class="container">
    <div class="d-flex align-items-center">
      <div class="me-4 pe-1">
        <BackButton />
      </div>
      <h1>{{ name }}</h1>
    </div>

    <div class="page-body mt-4 pt-3 pb-5">
      <div class="row">
        <div class="col-6">
          <div class="content-box user-information">
            <div
              class="d-flex align-items-center justify-content-between user-information-heading"
            >
              <h2 class="user-information-title">Basic Info</h2>
              <div class="user-information-toolbar">
                <transition name="fade" mode="out-in" :duration="150">
                  <div v-if="editable" class="d-flex">
                    <div class="me-2">
                      <Btn
                        type="primary-transparent"
                        size="md"
                        @click="editable = false"
                      >
                        Cancel
                      </Btn>
                    </div>
                    <div>
                      <Btn type="secondary" size="md" @click="editable = false">
                        Save
                      </Btn>
                    </div>
                  </div>

                  <Btn
                    v-else
                    type="primary-transparent"
                    size="md"
                    class="justify-content-end"
                    @click="editable = true"
                  >
                    <SvgIcon icon="pen" />
                  </Btn>
                </transition>
              </div>
            </div>

            <form submit.prevent class="user-information-body mt-4 pt-3">
              <AvatarUpload :disabled="!editable" />

              <div class="mt-5 pb-4">
                <div
                  v-for="(input, idx) in userInfo"
                  :key="input.id"
                  :class="{
                    'mt-4': idx > 0
                  }"
                >
                  <PhoneInput
                    v-if="input.type === 'phone'"
                    :label="input.label"
                    :initial-value="input.model"
                    :readonly="true"
                    :status="input.status"
                    :initial-selected-country="input.countryId"
                    :disabled="!editable"
                  />

                  <AppInput
                    v-else
                    :id="input.id"
                    v-model="input.model"
                    :label="input.label"
                    :status="input.status"
                    :type="input.type"
                    input-type="material"
                    :disabled="!editable"
                  />
                </div>
              </div>
            </form>
          </div>

          <div class="content-box mt-4">
            <h2 class="mb-4 user-information-title">Log In Logs</h2>
            <SecurityLog
              device-name="Apple iPhone 11 Pro · App"
              device-o-s="iOS 13.1"
              device-type="phone"
              connect-location="Kyiv, Kyiv City · 93.456.34.34 "
              connect-time="14.04.2020 at 2:40 am"
              :is-current-session="true"
            />

            <SecurityLog
              device-name="Samsung Galaxy S20 · App"
              device-o-s="Android 10.2"
              device-type="phone"
              connect-location="Kyiv, Kyiv City · 93.456.34.34 "
              connect-time="14.04.2020 at 2:40 am"
              :is-current-session="false"
            />
          </div>
        </div>

        <div class="col-6">
          <AccountStatus @delete="deleteUserDialogShown = true" />

          <div class="mt-4">
            <AppCollapsable :collapsed-height="500">
              <div class="content-box">
                <h3 class="mb-3">Permissions</h3>
                <div class="operator-permissions">
                  <SettingsItem
                    v-for="permission in permissions"
                    :id="permission.id"
                    :key="permission.id"
                    v-model="permission.checked"
                    :title="permission.title"
                    :description="permission.description"
                  />
                </div>
              </div>
            </AppCollapsable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityLog from "../components/User/AppSecurity/SecurityLog.vue";
import SettingsItem from "../components/Settings/SettingsItem.vue";
function getMockPermissions(count) {
  let permissions = [];

  for (let i = 1; i < count; i++) {
    permissions.push({
      id: `permission/${i}`,
      title: `Permission ${i}`,
      checked: i === 1 || i === 3
    });
  }

  return permissions;
}
import AppCollapsable from "@/components/ui/Collapsable/AppCollapsable.vue";
import DeleteUserDialog from "@/components/ui/Modal/Dialog/instances/DeleteUserDialog.vue";
import AccountStatus from "@/components/User/AccountStatus.vue";
import PhoneInput from "@/components/ui/Modal/Dialog/instances/PhoneInput.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AvatarUpload from "@/components/ui/AvatarUpload.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import BackButton from "@/components/ui/Buttons/BackButton.vue";
import Btn from "@/components/ui/Buttons/Button";

export default {
  name: "Operator",
  components: {
    BackButton,
    SvgIcon,
    Btn,
    AvatarUpload,
    AppInput,
    PhoneInput,
    AccountStatus,
    DeleteUserDialog,
    AppCollapsable,
    SettingsItem,
    SecurityLog
  },
  data() {
    return {
      name: "Úrsula Corberó",
      editable: false,
      deleteUserDialogShown: false,
      permissions: getMockPermissions(15),
      userInfo: [
        {
          model: "90847124",
          id: "operator/id",
          label: "Operator ID",
          status: null
        },
        {
          model: "Sophie Turner",
          id: "operator/fullName",
          label: "Full Name",
          status: null
        },
        {
          model: "sophie.turner@gmail.com",
          type: "email",
          id: "operator/email",
          status: {
            label: "verified",
            type: "success"
          },
          label: "Email"
        },
        {
          model: "+1 092 6784 887",
          countryId: "esp",
          type: "phone",
          id: "operator/phone",
          label: "Mobile Number",
          status: {
            label: "verified",
            type: "success"
          }
        },
        {
          model: "qwertyuiop",
          type: "password",
          label: "Password",
          status: {
            label: "change password",
            type: "danger"
          }
        }
      ]
    };
  },

  methods: {}
};
</script>

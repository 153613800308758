
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AvatarUpload from "@/components/ui/AvatarUpload.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
export interface EditData {
  id: number;
  title: string;
  icon: string;
  file: File;
  active: boolean;
}
export default defineComponent({
  name: "CategoryModal",

  components: {
    AppCheckbox,
    AppButton,
    AppInput,
    AvatarUpload,
    Dialog,
    AppInputsValidationProvider
  },
  emits: ["submit", "hide", "cansel"],

  props: {
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: true },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    isShow: { type: Boolean, default: true },
    title: { type: String, default: "" },
    data: {
      type: Object as PropType<EditData>,
      required: false
    },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      buttonTextUpdated: this.buttonText,
      categoryName: "",
      avatar: "",
      isPasswordTouch: false,
      valid: false,
      disabledButton: false,
      isChecked: false,
      editData: {} as EditData,
      isEditCategory: false,
      isCreateCategory: false,
      file: File
    };
  },
  computed: {},
  watch: {
    data(data: EditData): void {
      if (data) {
        this.editData = { ...data };
      } else {
        this.editData = {} as EditData;
      }
    },
    editData: {
      handler(data) {
        if (data?.title?.length > 3) {
          if (data?.icon || data?.file?.name) {
            this.disabledButton = true;
          }
        } else {
          this.disabledButton = false;
        }
      },
      deep: true
    }
  },
  methods: {
    async submit() {
      this.$emit("submit", this.editData);
    },

    onChangeErrorState(error: any) {
      this.valid = !error;
    },

    hide() {
      this.$emit("hide", false);
    },

    fileUpload(file: any) {
      this.editData.file = file;
    }
  },

  inheritAttrs: false
});

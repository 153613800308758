import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";
import { ValidatorResponse } from "@/validator/ValidatorResponse";
import {
  getValidationError,
  validationTypesEnum
} from "@/validator/errorMessages";
import { number } from "@/validator/validators/number";

export function price(value: string): IValidatorResponse {
  const { status: isNumber } = number(value);

  let status = isNumber ? parseInt(value) > 0 : false;
  const message = !isNumber
    ? getValidationError("price", validationTypesEnum.number)
    : null;

  if (status) {
    if (value.startsWith("0") && !value.includes(".")) {
      status = false;
    }
  }

  return new ValidatorResponse(
    status,
    message || getValidationError("price", validationTypesEnum.price)
  );
}

<template>
  <label :for="id" class="app-radio">
    <input
      :id="id"
      type="radio"
      :checked="checked ?? modelValue"
      v-bind="$attrs"
      @change="$emit('update:modelValue', $event.target.checked)"
    />

    <span class="radio"></span>
  </label>
</template>

<script>
export default {
  name: "AppRadio",
  props: {
    id: { type: String, required: true },
    modelValue: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
.app-radio {
  overflow: hidden;
  position: relative;
  input[type="radio"] {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  .radio {
    display: block;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid $grey-100;
    border-radius: 50%;
    background: transparent;
    position: relative;
    transition: 0.25s;
    &:before {
      transition: 0.25s;
      content: "";
      width: 35%;
      height: 35%;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  input:checked {
    & + .radio {
      background-color: $color-primary-blue;
      border-color: $color-primary-blue;
      &:before {
        opacity: 1;
      }
    }
  }
}
//input[type="radio"] {
//  width: 16px;
//  height: 16px;
//}
</style>


import { defineComponent, PropType } from "vue";
import { tableHeadCellsList } from "@/components/Table/ts/AppTable.vue";
import TableRow from "@/components/Table/TableRow.vue";
import TableHeadCell from "@/components/Table/TableHeadCell.vue";
import TableFooter from "@/components/Table/TableFooter.vue";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";

export default defineComponent({
  name: "AppTableLayout",
  components: { TableFooter, TableHeadCell, TableRow },
  inheritAttrs: false,
  props: {
    tableHead: {
      type: Array as PropType<tableHeadCellsList>,
      required: true
    },

    paginationCaptureKey: {
      type: String,
      default: ""
    },

    totalRows: { type: Number, default: 0 },
    perPage: { type: Number, default: 10 },
    currentPage: { type: Number, default: 1 },
    spaceBetween: { type: Boolean, default: true },
    withFooter: {
      type: Boolean,
      default: true
    }
  },
  emits: ["sort", "changeViewConfiguration"],

  data() {
    return {
      viewConfiguration: {
        perPage: this.perPage,
        page: this.currentPage
      }
    } as {
      viewConfiguration: ITableViewConfiguration;
    };
  },

  computed: {
    paginationTotal(): number {
      if (this.totalRows === 0) {
        return 0;
      }

      return Math.ceil(this.totalRows / this.perPage);
    },

    paginationCapture(): string {
      if (!this.paginationCaptureKey) {
        return "";
      }

      const { totalRows = 0 } = this;
      const { perPage, page } = this.viewConfiguration;
      let count = totalRows > perPage ? perPage * page : this.totalRows;

      if (count > totalRows) {
        count = totalRows;
      }

      return `${count} of ${this.totalRows} ${this.paginationCaptureKey}`;
    }
  },

  watch: {
    perPage(perPage) {
      this.viewConfiguration.perPage = perPage;
    },

    currentPage(page) {
      this.viewConfiguration.page = page;
    },

    viewConfiguration: {
      handler({ page, perPage }: ITableViewConfiguration) {
        if (page === this.currentPage && perPage === this.perPage) {
          return;
        }

        this.$emit("changeViewConfiguration", this.viewConfiguration);
      },
      deep: true
    }
  },

  methods: {
    changeCurrentPageHandler(page: number): void {
      this.viewConfiguration.page = page;
    },

    changePerPageHandler(perPage: number): void {
      this.viewConfiguration.perPage = perPage;
    },

    sortHandler(keyName: string): void {
      this.$emit("sort", keyName);
    }
  }
});

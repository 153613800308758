import { EditDataAbouts } from "@/api/interfaces/about-products/common";
import { ITab } from "@/store/interfaces/common";
import { useStore } from "vuex";

type useBaseAppLayoutOptions = {
  selectedTab: {
    id: number | null;
    title: string;
    value: string;
  };
};

export interface optionsAbouts {
  id: number;
  title: string;
  value: string;
}

interface tabData {
  id: number;
  name: string;
  selected: boolean;
}

interface editDeviceData {
  title: string;
  active: boolean;
  brand?: string;
  icon?: string;
  id?: number;
}

export function useAboutProduct(options: useBaseAppLayoutOptions) {
  const { selectedTab } = options;
  const store = useStore();

  async function requestAbouts(): Promise<void> {
    if (!selectedTab?.id) return;
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchAboutsList", {
      value: selectedTab.value
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  async function editAbouts(data: EditDataAbouts): Promise<boolean> {
    const { selectedTab } = options;
    if (!selectedTab?.id) return false;
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("editAbouts", {
      value: selectedTab.value,
      data
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  function tabToSelect(tabs: ITab[]): tabData[] {
    return tabs.map(elem => ({
      id: elem.id,
      name: elem.title,
      selected: false
    }));
  }

  return {
    requestAbouts,
    tabToSelect,
    editAbouts
  };
}

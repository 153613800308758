import { ITab } from "@/store/interfaces/common";
import { packageLocationsArray } from "@/hooks/esim/location-packages/types/locationPackage.types";
import { ref, Ref } from "vue";

export interface ILocationsTabs {
  tabs: ITab[];
  selectedTab: Ref<ITab>;
}

export function locationsTabs(): ILocationsTabs {
  const tabs: ITab[] = [...packageLocationsArray].map((tab: string, index) => {
    return {
      id: index + 1,
      title: tab,
      value: tab.toLowerCase()
    };
  });

  const selectedTab: Ref<ITab> = ref({ ...tabs[0] });

  return { tabs, selectedTab };
}


import SelectInput from "@/components/Form/SelectInput.vue";
import { computed, defineComponent, toRefs } from "vue";
import { useStore } from "vuex";
import {
  ISelectOption,
  useLocationPackagesSelect
} from "@/hooks/esim/location-packages/modules/useLocationPackagesSelect";

export default defineComponent({
  name: "SelectPurchaseType",
  components: { SelectInput },
  props: {
    selectedPurchaseTypeId: { type: Number, default: -1 }
  },

  emits: { select: null },

  setup(props, { emit }) {
    const store = useStore();

    store.dispatch("fetchPurchaseTypes");

    const { selectedPurchaseTypeId } = toRefs(props);

    const purchaseTypes = computed(() => store.getters.purchaseTypes);

    const { optionsList, selectOption } = useLocationPackagesSelect({
      parentSelectedOptionId: selectedPurchaseTypeId,
      initialOptions: purchaseTypes,
      onOptionChanged(option: ISelectOption) {
        emit("select", option);
      }
    });

    return { optionsList, selectOption };
  }
});

<template>
  <DarkOverlay :shown="shown" :is-insert="inserted" @click="hide" />
  <transition :css="false" @enter="onEnter" @leave="onLeave">
    <div v-if="shown" class="modal-wrapper" @click="hide">
      <div ref="modal" class="modal-sidebar" @click.stop>
        <div class="modal-sidebar--header ">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <button v-if="onBack" class="back" @click="onBack">
                <svg-icon icon="back-arrow" />
              </button>
              <h2 v-if="title">{{ title }}</h2>
            </div>

            <slot name="heading" />

            <button class="close" @click="hide">
              <svg-icon icon="close" />
            </button>
          </div>
        </div>

        <div class="modal-sidebar--body">
          <slot />
        </div>

        <div class="modal-sidebar-tabs">
          <slot name="tabs" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";
import DarkOverlay from "@/components/ui/DarkOverlay";
import SvgIcon from "@/components/ui/SvgIcon";

const duration = 0.2;
const modalDuration = 0.3;
const leaveCoeficient = 0.9;

export default {
  name: "Sidebar",

  components: { SvgIcon, DarkOverlay },
  props: {
    opened: { type: Boolean, default: false },
    onBack: { type: Function, required: false },
    title: { type: String, required: false },
    inserted: { type: Boolean, default: false },
    useBodyPadding: { type: Boolean, default: true }
  },
  emits: ["hide"],

  data() {
    return {
      shown: this.opened,
      modal: null
    };
  },

  watch: {
    opened() {
      this.shown = this.opened;
    }
  },

  mounted() {
    gsap.to(this.$refs.modal, { x: "100%" });
  },

  methods: {
    hide() {
      this.shown = false;
      this.$emit("hide");
    },

    onEnter(modalWrapper, done) {
      gsap.to(modalWrapper, { autoAlpha: 1, duration });
      let modal = this.$refs.modal;
      this.modal = modal;

      gsap.to(modal, {
        x: 0,
        y: 0,
        z: 1,
        duration: modalDuration,
        delay: duration / 2,
        onComplete: done
      });
    },

    onLeave(modalWrapper, done) {
      gsap.to(this.modal, {
        x: "100%",
        y: 0,
        z: 0,
        duration: modalDuration * leaveCoeficient
      });

      gsap.to(modalWrapper, {
        autoAlpha: 0,
        duration,
        delay: modalDuration / 2,
        onComplete: done
      });
    }
  }
};
</script>

<style lang="scss">
$sidebar-modal-padding-x: 40px;

.modal-wrapper {
  background-color: rgba(#000, 0.1);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $aboveOverlay;
  overflow-y: auto;
  overflow-x: hidden;
  @extend %hideScrollBar;
  opacity: 0;
}

.modal-sidebar {
  position: absolute;
  min-height: 100%;
  width: 700px;
  background-color: #fff;
  box-shadow: -10px 0px 35px rgba(0, 0, 0, 0.07);
  z-index: $aboveOverlay + 1;

  top: 0;
  right: 0;
  transform: translate3d(100%, 0, 0);

  overflow: hidden;

  &--header {
    padding: 30px $sidebar-modal-padding-x;
    .back {
      font-size: 2em;
      margin-right: 15px;
    }

    .close {
      font-size: 2.7rem;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $grey-400;
      @media (any-hover: hover) {
        transition: 0.2s;
        &:hover {
          background-color: darken($grey-400, 5px);
        }
      }
      .icon {
        font-size: 0.3em;
      }
    }

    h2 {
      font-size: 2rem;
      font-weight: 700 !important;
    }
  }

  &--body {
    padding: 0 $sidebar-modal-padding-x;
  }

  .sidebar--padding {
    padding: {
      left: $sidebar-modal-padding-x;
      right: $sidebar-modal-padding-x;
    }
  }
}
</style>

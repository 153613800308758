
import { defineComponent, PropType } from "vue";
import { IObjectKeys } from "@/interfaces/IObjectKeys";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import HeadToolBar from "@/components/Table/HeadToolBar.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import { ITab } from "@/store/interfaces/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import { mapGetters } from "vuex";
import AppPreloader from "@/components/ui/AppPreloader.vue";

const tableStatuses: IObjectKeys = {
  verified: "base",
  unverified: "warn",
  blocked: "error"
};

export type tableHeadCellsList = ITableHeadCell[];
export type tableBodyList = ITableBodyRow[];

type appTableData = {
  viewConfiguration: ITableViewConfiguration;
  selectedTab: ITab;
  selectedTabIdx: number;
};

export default defineComponent({
  name: "AppToolbarTable",
  components: {
    AppPreloader,
    AppButton,
    HeadToolBar,
    PageMainHeading,
    AppTable,
    BtnContent
  },

  props: {
    paginationCaptureKey: {
      type: String,
      default: ""
    },

    showHeadToolbar: {
      type: Boolean,
      default: true
    },

    spaceBetween: { type: Boolean, default: true },
    totalRows: { type: Number as PropType<number>, default: 0 },
    perPage: { type: Number, default: 10 },
    currentPage: { type: Number, default: 1 },

    hideButton: {
      type: Boolean,
      default: false
    },

    tableHeadTabs: {
      type: Array as PropType<ITab[]>,
      required: true
    },

    tableHeadTable: {
      type: Array as PropType<ITableHeadCell[]>,
      required: true
    },
    tableBody: {
      type: Array as PropType<tableBodyList | ITableBodyRow[]>,
      required: true
    },

    tableTitle: { type: String, default: "" },
    parentSelectedTab: {
      type: Object as PropType<ITab>,
      default: null
    },
    withFooter: {
      type: Boolean,
      default: true
    }
  },

  emits: {
    changeViewConfiguration: null,
    sort: null,
    changeTab: null,
    openForm: null,
    removeItem: null,
    editItem: null
  },

  data() {
    return {
      selectedTab: this.parentSelectedTab
        ? this.parentSelectedTab
        : this.tableHeadTabs[0],

      viewConfiguration: {
        perPage: this.perPage,
        page: this.currentPage
      },

      selectedTabIdx: 0
    } as appTableData;
  },

  computed: {
    ...mapGetters(["appLoading"]),
    withMoreMenu(): boolean {
      return !!this.tableBody[0]?.actions;
    },
    currentTab() {
      return (
        this.tableHeadTabs.find(el => el.id === this.selectedTab?.id) ||
        this.tableHeadTabs[0]
      );
    },

    updatedBodyRows(): tableBodyList {
      return this.tableBody.map(row => {
        const status = row.status || "base";

        return {
          ...row,
          withAction: !!row.onClick,
          status: tableStatuses[status] || "base"
        };
      });
    },

    paginationTotal(): number {
      if (this.totalRows === 0) {
        return 0;
      }

      return Math.ceil(this.totalRows / this.perPage);
    },

    paginationCapture(): string {
      if (!this.paginationCaptureKey) {
        return "";
      }

      const { totalRows = 0 } = this;
      const { perPage, page } = this.viewConfiguration;
      let count = totalRows > perPage ? perPage * page : this.totalRows;

      if (count > totalRows) {
        count = totalRows;
      }

      return `${count} of ${this.totalRows} ${this.paginationCaptureKey}`;
    },

    useSelect(): boolean {
      return true;
    },

    tabsList() {
      return this.tableHeadTabs.map(tab => tab.title);
    }
  },

  watch: {
    selectedTab: {
      handler(data: ITab) {
        if (data?.id !== this.parentSelectedTab?.id) {
          this.$emit("changeTab", data);
        }
      },

      deep: true
    },

    parentSelectedTab: {
      deep: true,
      immediate: true,
      handler(tab: ITab) {
        if (!this.selectedTab || !tab) {
          return;
        }

        if (tab.id !== this.selectedTab.id) {
          this.selectedTab = tab;
        }
      }
    }
  },

  methods: {
    changeViewConfiguration(newViewConfiguration: ITableViewConfiguration) {
      this.$emit("changeViewConfiguration", newViewConfiguration);
    },
    changeCurrentPageHandler(page: number): void {
      this.viewConfiguration.page = page;
    },

    changePerPageHandler(perPage: number): void {
      this.viewConfiguration.perPage = perPage;
    },

    changeSorting(keyName: string): void {
      this.$emit("sort", keyName);
    },
    addItem() {
      this.$emit("openForm");
    },
    changeTab(idx: number) {
      this.selectedTabIdx = idx;

      const findTab: ITab | undefined = this.tableHeadTabs[idx];

      if (findTab) {
        this.selectedTab = findTab;
      }
    },
    editItem(id: number) {
      this.$emit("editItem", id);
    },
    removeItem(id: number) {
      this.$emit("removeItem", id);
    }
  }
});

import { brandRequest, IBrands } from "@/api/interfaces/brands/IBrands";

import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";

import { categoriesQuery } from "@/api/interfaces/accounts/filters";
import {
  apiRequestFileType,
  apiRequestType,
  IApiResponse
} from "@/api/interfaces/IApi";

export const brandsService = {
  async fetchBrandsService(
    request: apiRequestType,
    query: categoriesQuery
  ): Promise<brandRequest> {
    const queryString: string = queryObjectToUrlString(query);
    const response = await request(
      `/supported-devices/brands-admin?${queryString}`,
      "get"
    );

    const data: IBrands = <IBrands>response.data;

    return {
      ...response,
      data
    };
  },

  async addBrandsService(
    request: apiRequestType,
    data: IBrands
  ): Promise<IApiResponse> {
    const response = await request(`supported-devices/brand`, "post", data);
    return response;
  },

  async editBrandService(
    request: apiRequestType,
    data: IBrands,
    id: number
  ): Promise<IApiResponse> {
    const response = await request(
      `supported-devices/brand/${id}`,
      "patch",
      data
    );

    return response;
  },

  async removeBrandService(
    request: apiRequestType,
    id: number
  ): Promise<brandRequest> {
    const response = await request(`supported-devices/brand/${id}`, "delete");
    const data: IBrands = <IBrands>response.data;

    return {
      ...response,
      data
    };
  }
};

<template>
  <button
    :class="
      `button button-${type} ${active ? 'active' : ''} ${
        size ? 'button-size' + '--' + size : ''
      }`
    "
  >
    <slot />
  </button>
</template>

<script>
const buttonSizes = [
  "icon",
  "xs",
  "sm",
  "smL",
  "md-fluid",
  "md",
  "lg",
  "xl",
  "fluid"
];

export default {
  name: "Btn",
  props: {
    type: {
      type: String,
      validator: type => {
        return [
          "primary",
          "secondary",
          "danger",
          "success",
          "default",
          "primary-border",
          "primary-border-dashed"
        ].indexOf(type);
      },

      default: "default"
    },

    size: {
      type: String,
      validator(size) {
        return buttonSizes.indexOf(size);
      }
    },

    active: { type: Boolean, default: false }
  },
  data() {
    return {
      styles: this.styles
    };
  }
};
</script>

<style lang="scss">
.button {
  color: $color-contrast-light;
  font-weight: 500;
  @include shadowBlock();
  padding-right: 1.2rem;
  transition: $transition;
  @extend %flex-center;
  &[disabled="true"],
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

@each $type, $styles in $buttonTypes {
  $style: map-get($styles, "base");
  $active: map-get($styles, "active");
  $hover: map-get($styles, "hover");
  $focus: map-get($styles, "focus");
  $activeState: map-get($styles, "activeState");

  .button {
    &-#{$type} {
      @include getButtonStyles($type);
    }
  }
}

@each $size, $styles in $buttonSizes {
  .button-size--#{$size} {
    @include getStyles($styles);
  }
}
</style>

<template>
  <div :class="`pseudo-table-row ${status}`" @click="$emit('click')">
    <div
      class=" table-row-content d-flex align-items-center"
      :class="{ 'justify-content-between': spaceBetween }"
    >
      <slot name="content" />
    </div>
    <div v-if="moreMenu" class="table-more-menu" @click.stop>
      <MoreMenu :buttons-list="moreMenu" />
    </div>
  </div>
</template>

<script>
import { statuses, accountStatuses } from "@/constants/statuses";
import MoreMenu from "@/components/Table/MoreMenu";

const { active, inactive, blocked } = statuses;
const { verified, unverified } = accountStatuses;

export default {
  name: "TableRow",
  components: { MoreMenu },
  props: {
    spaceBetween: { type: Boolean, default: true },
    status: {
      type: String,
      validator(val) {
        return (
          [active, inactive, blocked, verified, unverified, "base"].indexOf(
            val
          ) !== -1
        );
      },
      default: active
    },

    moreMenu: {
      type: Array
    }
  }
};
</script>

<style lang="scss">
.table-more-menu {
  flex-basis: $moreBtnWidth;
}

.pseudo-table.withMoreMenu .pseudo-table--head .pseudo-table-row:after {
  content: "";
  display: block;
  height: 1px;
  width: 35px;
}

.pseudo-table-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  padding: 1rem 1.5rem 1rem 2rem;
  background-color: #fff;
  color: $color-contrast-dark;
  text-decoration: none;
  position: relative;
  min-height: 70px;

  &.status {
    &-warning {
      background: $color-warning-light;

      .statusText {
        color: $color-warning;
        font-weight: var(--semibold);
      }
    }

    &-danger {
      background: $color-danger-light;
      .statusText {
        color: $color-danger;
        font-weight: var(--semibold);
      }
    }
  }

  &:not(:last-child) {
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $border-color;

      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .more-dropdown {
    margin-left: auto;
  }

  * {
    text-decoration: none;
  }

  &.blocked {
    background-color: $color-danger-light;

    .account-status {
      color: $color-danger;
    }
  }

  &.unverified,
  &.inactive {
    background-color: $color-warning-light;
  }
}

.pseudo-table--head {
  .pseudo-table-row {
    padding-top: 1.428rem;
    padding-bottom: 1.428rem;
    //min-height: 75px;
  }
}

.inline-table {
  .pseudo-table--head {
    .pseudo-table-row {
      min-height: 75px;
    }
  }
}

.table-row-content {
  //flex: 1;
  //justify-content: space-between;
  width: 100%;
}

.table-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-contrast-dark;

  &:hover {
    color: $color-contrast-dark;
  }
}

.pseudo-table-row.hover-scale {
  cursor: pointer;
}

.app-controller:not(.overlay-shown) {
  .pseudo-table--body {
    .pseudo-table-row.hover-scale {
      box-shadow: 0 0 15px rgba(#000, 0);
      position: relative;

      @media (any-hover: hover) {
        transition: 0.3s;
        transform-origin: center;

        &:hover {
          transform: scale(1.02);
          box-shadow: $shadow-200;
          border-radius: 12px;
          z-index: 9999 !important;
        }
      }
    }
  }
}
.height-auto {
  .pseudo-table--head {
    .pseudo-table-row {
      min-height: max-content;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.pseudo-table-row {
  strong.xl {
    font-size: 1.07rem;
  }
}
</style>

import { IBaseQuery } from "@/api/interfaces/IApi";
import { useStore } from "vuex";

export type useBaseAppLayoutOptions = {
  viewConfiguration: IBaseQuery;
  userId: number;
};

type UserEditData = {
  email: string;
  fullName: string;
  id: number;
};
export function useAccount(options: useBaseAppLayoutOptions) {
  const { userId } = options;
  const store = useStore();
  // Get user information
  async function getUserInfo(): Promise<void> {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchUserInfo", {
      id: userId
    });
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    // await store.dispatch("setAppLoading", false);
  }

  // Get user logins history
  async function getLoginHistory(viewConfiguration: {
    page: number;
    perPage: number;
  }): Promise<void> {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchLoginHistory", {
      id: userId,
      query: viewConfiguration
    });
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    // await store.dispatch("setAppLoading", false);
  }

  // Upload User Avatar
  async function uploadAvatar(avatar: File | string): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("uploadUserAvatar", {
      id: userId,
      avatar
    });
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  // Edit User
  async function editUser(data: UserEditData): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("editUser", {
      data
    });
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  // Delete User
  async function removeUser(): Promise<boolean> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("removeUser", {
      id: userId
    });
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function getPaymentHistoryById(): Promise<boolean> {
    // await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch(
      "fetchPaymentHistoryById",
      userId
    );
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    // await store.dispatch("setAppLoading", false);
    return success;
  }

  return {
    getUserInfo,
    removeUser,
    editUser,
    uploadAvatar,
    getLoginHistory,
    getPaymentHistoryById
  };
}

<template>
  <div :class="`app-controller ${overlayIsShown ? 'overlay-shown' : ''}`">
    <PageSidebar ref="sidebar" />
    <PageHeader />
    <main class="page-content">
      <slot />
    </main>
  </div>
</template>

<script>
import PageSidebar from "@/components/Layout/PageSidebar";
import PageHeader from "@/components/Layout/Header/Header";
import { mapGetters } from "vuex";

let resizeTimeout = null;
export default {
  name: "Layout",

  components: { PageHeader, PageSidebar },
  props: {
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      sidebarWidth: 0,
      scrollLeft: window.scrollX
    };
  },

  computed: {
    ...mapGetters(["overlayIsShown"])
  },

  mounted() {
    this.$nextTick(() => {
      this.setSidebarWidth();
      window.addEventListener("resize", this.setSidebarWidth);
      window.addEventListener("scroll", this.scrollHandler);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.setSidebarWidth);
    window.removeEventListener("scroll", this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.scrollLeft = window.scrollX;
    },

    setSidebarWidth(evt) {
      let timeout = evt ? 300 : 0; // set timeout when func called from window resize cb
      clearTimeout(resizeTimeout);

      resizeTimeout = setTimeout(() => {
        const { sidebar } = this.$refs;
        const el = sidebar?.$el || null;

        if (el) {
          this.sidebarWidth = el.getBoundingClientRect().width;
        }
      }, timeout);
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.page-content {
  padding-top: $main-content-padding-top;
}
.app-controller {
  position: relative;
  $rows-count: 2;
  display: grid;

  grid-template-columns: $sidebar-width calc(100% - #{$sidebar-width});
  grid-template-rows: $header-height 1fr;
}

aside {
  grid-row-start: 1;
  grid-row-end: 3;
}

:root {
  --main-height: calc(100vh - 80px);
}

main {
  grid-column-start: 2;
  min-height: var(--main-height);
  position: relative;
  z-index: 99;
}
</style>

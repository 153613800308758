
import AppTableLayout from "@/components/Table/ts/AppTableLayout.vue";
import { PropType } from "vue/dist/vue";
import { tableHeadCellsList } from "@/components/Table/ts/AppTable.vue";
import { tableBodyList } from "@/components/Table/ts/AppTable.vue";
import { defineComponent } from "vue";
import AppExpendedTableBody from "@/components/Table/ts/AppExpandedTableBody.vue";
export default defineComponent({
  components: { AppExpendedTableBody, AppTableLayout },
  emits: {
    changeViewConfiguration: null,
    sort: null,
    accordionOpen: null
  },
  props: {
    paginationCaptureKey: {
      type: String,
      default: ""
    },
    totalRows: { type: Number as PropType<number>, default: 0 },
    perPage: { type: Number, default: 10 },
    currentPage: { type: Number, default: 1 },
    tableHead: {
      type: Array as PropType<tableHeadCellsList>,
      required: true
    },

    tableBody: {
      type: Array as PropType<tableBodyList>,
      required: true
    }
  },

  computed: {
    updatedTableBodyRows() {
      return this.tableBody.map(({ cells, ...row }) => {
        return {
          ...row,
          cells: cells.map((cell, idx) => {
            return { ...cell, size: this.tableHead[idx].size };
          })
        };
      });
    }
  },

  methods: {
    onAccordionOpen(id: number) {
      this.$emit("accordionOpen", id);
    }
  }
});

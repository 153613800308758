
import { defineComponent, h, Transition, VNode } from "vue";
import gsap from "gsap";

const TAB_DURATION = 0.55;
const HEIGHT_DURATION = 0.4;

export default defineComponent({
  name: "AppTabsBody",
  props: {
    selectedTabIdx: { type: Number, default: 0 },
    animate: { type: Boolean, default: true }
  },

  data(): {
    prevSelectedIdx: number;
    activeTabHeight: number;
    tabDuration: number;
    rootElDuration: number;
    loaded: boolean;
    rootEl: VNode | null;
    resizeObserver: ResizeObserver | null;
    activeTabContent: Element | null;
    processTimeout: ReturnType<typeof setTimeout> | null;
    currentTabHeight: number;
    heightAlreadySet: boolean;
  } {
    return {
      prevSelectedIdx: -1,
      rootEl: null,
      activeTabHeight: 0,
      resizeObserver: null,
      activeTabContent: null,
      tabDuration: this.animate ? TAB_DURATION : 0,
      rootElDuration: this.animate ? HEIGHT_DURATION : 0,
      loaded: false,
      processTimeout: null,
      currentTabHeight: 0,
      heightAlreadySet: false
    };
  },

  watch: {
    currentTabHeight: {
      handler(height) {
        this.setRootElHeight(height, this.rootElDuration);
      }
    },

    activeTabContent: {
      handler(current, prev) {
        // Observe / unobserve active tab height
        if (this.resizeObserver) {
          if (prev) {
            this.resizeObserver.unobserve(prev);
          }

          this.resizeObserver.observe(current, {
            box: "border-box"
          });
        }
      }
    },

    selectedTabIdx: {
      immediate: true,
      handler(idx, prevIdx) {
        if (this.rootEl?.el) {
          this.rootEl.el.style.overflow = "hidden";
        }

        if (prevIdx !== undefined) {
          this.prevSelectedIdx = prevIdx;
        }
      }
    },

    animate(animate: boolean) {
      this.tabDuration = animate ? TAB_DURATION : 0;
      this.rootElDuration = animate ? HEIGHT_DURATION : 0;
    }
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.activeTabContent) {
          const { height } = this.activeTabContent.getBoundingClientRect();
          this.currentTabHeight = height;
        }
      }, 200);
    });
  },
  created() {
    // Create active tab resize observer
    this.resizeObserver = new ResizeObserver(([{ target }]) => {
      if (target) {
        this.heightAlreadySet = true;
        this.currentTabHeight = target.getBoundingClientRect().height;
      }
    });
  },

  beforeUnmount() {
    this.heightAlreadySet = false;

    // Kill active tab resize observer
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    this.resizeObserver = null;
  },

  unmounted() {
    this.currentTabHeight = 0;
  },

  methods: {
    setRootElHeight(
      height: number,
      duration?: number,
      onComplete: () => void = () => {}
    ) {
      this.heightAlreadySet = true;
      if (!this.rootEl?.el) return;

      gsap.to(this.rootEl.el, {
        height,
        delay: 0,
        duration: duration ?? this.rootElDuration,
        onComplete
      });
    },

    beforeAppear(el: Element) {
      this.activeTabContent = el;
      gsap.set(el, { autoAlpha: 1, xPercent: 0 });
    },

    beforeEnter(el: Element) {
      if (this.rootEl?.el) {
        this.rootEl.el.style.overflow = "";
      }

      this.activeTabContent = el;

      // Animate active tab enter
      gsap.set(el, {
        autoAlpha: 0,
        xPercent: this.prevSelectedIdx > this.selectedTabIdx ? -100 : 100
      });

      if (this.rootEl?.el) {
        const { height } = el.getBoundingClientRect();
        this.currentTabHeight = height;
      }
    },

    enter(el: Element, done: () => void) {
      this.$nextTick(() => {
        if (this.rootEl?.el) this.rootEl.el.style.overflow = "hidden";
        gsap.to(el, {
          autoAlpha: 1,
          xPercent: 0,
          easing: "expo.inOut",
          duration: this.tabDuration,
          onComplete: () => {
            this.processTimeout = setTimeout(() => {
              if (this.rootEl?.el) {
                this.rootEl.el.style.overflow = "visible";
              }
            }, this.tabDuration);

            done();
          }
        });
      });
    },

    leave(el: Element, done: () => void) {
      this.$nextTick(() => {
        gsap.to(el, {
          autoAlpha: 0,
          duration: this.tabDuration,
          easing: "expo.outIn",
          xPercent: this.prevSelectedIdx > this.selectedTabIdx ? 100 : -100,
          onComplete: done
        });
      });
    }
  },

  render() {
    const tabs: any = this.$slots.default?.() || [];
    const root = tabs?.[0]?.children;

    if (!Array.isArray(root)) {
      return h("");
    }

    let target = "";

    if (root.length === 1) {
      target = root?.[0]?.children[this.selectedTabIdx];
    } else if (root.length > 0) {
      target = root[this.selectedTabIdx];
    }

    const rootEl = h(
      "div",
      {
        class: "app-tabs__body__wrapper"
      },
      h(
        "div",
        {
          class: "app-tabs__body__controller"
        },
        h(
          Transition,
          {
            css: false,
            appear: true,
            onAppear: this.beforeAppear,
            onBeforeEnter: this.beforeEnter,
            onEnter: this.enter,
            onLeave: this.leave
          },
          target
        )
      )
    );

    this.rootEl = rootEl;
    return rootEl;
  }
});

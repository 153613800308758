import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";

export type IValidator = {
  name: string;
  validator: (value: string, toEqualValue?: string) => IValidatorResponse;
};

export interface IAppValidators {
  email: (value: string) => IValidatorResponse;
  equal: (value: string, toEqualValue?: string) => IValidatorResponse;
  password: (value: string) => IValidatorResponse;
  name: (value: string) => IValidatorResponse;
  phone: (value: string) => IValidatorResponse;
  required: (value: string) => IValidatorResponse;
}

export function combineValidators(validators: IValidator[]): IAppValidators {
  return <IAppValidators>validators.reduce(
    (acc, { name, validator }: IValidator): object => {
      return { ...acc, [name]: validator };
    },
    {}
  );
}

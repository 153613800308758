import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";
import {
  getValidationError,
  inputTypesEnum,
  validationTypesEnum
} from "@/validator/errorMessages";
import { ValidatorResponse } from "@/validator/ValidatorResponse";

const { email: emailType } = inputTypesEnum;
const { required, base } = validationTypesEnum;

const reg = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export function email(value: string): IValidatorResponse {
  if (!value.length) {
    return new ValidatorResponse(
      false,
      getValidationError(emailType, required)
    );
  }

  const isValid = reg.test(value);

  return new ValidatorResponse(
    isValid,
    isValid ? "" : getValidationError(emailType, base)
  );
}

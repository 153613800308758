<template>
  <Dialog
    id="remove-modal-confirm"
    title="null"
    :use-header="false"
    v-bind="$attrs"
    size="sm"
    class="delete-location"
    :class="{ canDelete }"
  >
    <div class="d-flex flex-column align-items-center text-center">
      <div v-if="locationIcon" class="delete-location--icon">
        <img :src="locationIcon" :alt="locationName" />
      </div>

      <h3 class="mt-4">{{ locationName }}</h3>

      <p
        class="mt-3 font-15 delete-location--description"
        v-html="description"
      />

      <div class="delete-location--footer">
        <div v-if="canDelete" class="can-remove-buttons">
          <AppButton
            size="xl"
            type="danger"
            class="w-100"
            :loading="loading"
            @click="submit"
          >
            Yes, delete it!
          </AppButton>

          <AppButton
            size="xl"
            type="default-transparent"
            class="w-100"
            @click="cancel"
          >
            Cancel
          </AppButton>
        </div>

        <AppButton
          v-else
          size="xl"
          type="secondary"
          class="w-100"
          :loading="loading"
          @click="submit"
        >
          Ok, got it
        </AppButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/components/ui/Modal/Dialog/Dialog";
import AppButton from "@/components/ui/Buttons/AppButton";
const cantDeleteText =
  "You can not delete this country since it is associated with eSIM and Virtual Number packages.";
const canDeleteText = "Are you sure you want to delete <br> this location?";

export default {
  name: "DeleteLocationConfirm",
  components: { AppButton, Dialog },
  inheritAttrs: false,
  props: {
    locationName: { type: String, required: true },
    locationIcon: { type: String, required: true },
    canDelete: { type: Boolean, default: true },
    loading: { type: Boolean, default: false }
  },
  emits: {
    submit: null,
    cancel: null
  },
  computed: {
    description() {
      return this.canDelete ? canDeleteText : cantDeleteText;
    }
  },

  methods: {
    submit() {
      this.$emit("submit");
    },

    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss">
.delete-location {
  padding-bottom: 20px !important;
  &--icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--footer {
    margin-top: 39px;
  }

  .modal-body {
    padding-bottom: 0 !important;
    padding-left: 40px;
    padding-right: 40px;
  }

  &:not(.canDelete) {
    padding-bottom: 43px !important;
    .delete-location--footer {
      margin-top: 30px;
    }
  }
}
</style>

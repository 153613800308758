import { IFetchDirectoriesParams } from "./../types/esimDirectories.types";
import { IApiResponse } from "@/api/interfaces/IApi";

export const fetchEsimDirectories = async ({
  directoryType,
  store
}: IFetchDirectoriesParams): Promise<IApiResponse> => {
  await store.dispatch("setAppLoading", true);
  const { success, message } = await store.dispatch(
    "fetchDataDirectory",
    directoryType
  );

  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }

  await store.dispatch("setAppLoading", false);

  return success;
};

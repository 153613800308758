
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "DeleteConfirmDialog",
  components: { AppButton, Dialog },
  inheritAttrs: false,
  props: {
    loading: { type: Boolean, default: false }
  },
  emits: ["confirm", "cancel"]
});

<template>
  <header class="d-flex">
    <div class="container d-flex">
      <div class="d-flex align-items-center w-100">
        <FormController>
          <SearchControl
            id="app-search"
            :on-search="searchHandler"
            placeholder="Search for anything..."
            class="fluid-form-control"
            name="global-search"
          />
        </FormController>

        <HeaderToolbar />

        <UserInfo
          :name="currentUserData.fullName"
          :avatar="currentUserData.image"
          :description="currentUserData.type"
        />

        <button type="button" class="logout" @click="logoutHandler">
          <svg-icon icon="logout" />
        </button>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
import FormController from "@/components/Form/FormController";
import SearchControl from "@/components/Form/SearchControl";
import HeaderToolbar from "@/components/Layout/Header/HeaderToolbar";
import UserInfo from "@/components/UserInfo/UserInfo";
import SvgIcon from "@/components/ui/SvgIcon";

export default {
  name: "PageHeader",
  components: {
    SvgIcon,
    UserInfo,
    HeaderToolbar,
    SearchControl,
    FormController
  },

  computed: {
    ...mapGetters(["userInfo", "overlayIsShown", "currentUserData"])
  },

  methods: {
    searchHandler(value) {
      console.log("search", value);
    },
    logoutHandler() {
      this.$store.dispatch("logout");
    }
  }
};
</script>
<style lang="scss">
header {
  background-color: #fff;
  position: sticky;
  top: 0;
  box-shadow: $shadow-300;
  z-index: $underOverlay;
  transition: transform 0.3s;

  form {
    flex: 1;
    .form-control {
      font-weight: 500;
      font-size: 1.1rem;
      height: $header-height;

      &-wrapper {
        .icon {
          color: $grey-200;
          font-size: 1.3em !important;
          transition: $transition;
        }

        &[data-focused="true"] {
          .icon {
            color: $color-contrast-dark;
          }
        }
      }
    }

    input::placeholder {
      color: $grey-200;
    }
  }

  .user-info {
    margin-left: 2.5rem;
    .user-avatar {
      box-shadow: $shadow-100;
    }
  }

  .logout {
    color: $color-light-primary;
    font-size: 14px;
    padding: 1em;
    margin-left: 2.5em;
    @media (any-hover: hover) {
      transition: 0.25s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.overlay-shown {
  header {
    transform: translateY(-100%);
    //z-index: -1;
    //position: relative;
  }
}
</style>

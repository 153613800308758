import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { httpType } from "@/api/interfaces/IApi";
import { appStorage } from "@/storage/storage";
import { IAccessCredentials, token } from "@/store/interfaces/auth/common";
import { getTokenString } from "@/utills/getTokenString";
import store from "@/store";
import { statusCodes } from "@/api/contants/statusCodes";

let newTokenRequested = false;
function createAxios(): httpType {
  const $axios: AxiosInstance = axios.create({});
  $axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;

  $axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token: token = appStorage.get("token");

      if (token && !config.headers.isRefresh) {
        config.headers.Authorization = getTokenString(token);
      }

      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );

  $axios.interceptors.response.use(
    response => {
      newTokenRequested = false;
      return response;
    },
    async (error: AxiosError) => {
      if (!error.response) {
        return Promise.reject(error);
      }

      async function onRefreshTokenFail(): Promise<AxiosError> {
        await store.dispatch("onAuthExpired");
        return Promise.reject(error);
      }

      const statusCode: number = error.response.data.error.code;

      if (statusCode === statusCodes.loggedOnAnotherDevice) {
        await onRefreshTokenFail();
        return Promise.reject(error);
      }

      if (statusCode === statusCodes.tokenDied && !newTokenRequested) {
        newTokenRequested = true;

        const refreshToken: token = appStorage.get("refreshToken");

        if (!refreshToken) {
          return Promise.reject(error);
        }

        const credentials: IAccessCredentials | null = await store.dispatch(
          "refreshToken",
          refreshToken
        );

        if (!credentials || !credentials.token) {
          await onRefreshTokenFail();
          return Promise.reject(error);
        }

        error.response.config.headers["Authorization"] = getTokenString(
          credentials.token
        );

        return $axios(error.response?.config);
      }

      return Promise.reject(error);
    }
  );

  return $axios;
}

export const $axios = createAxios();

<template>
  <div class="button-content d-flex align-items-center">
    <div v-if="icon" class="button-content--icon">
      <svg-icon :icon="icon" />
    </div>

    <div class="button-content--label">{{ label }}</div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "BtnContent",
  components: { SvgIcon },
  props: {
    icon: { type: String, required: false },
    label: { type: String, required: true }
  }
};
</script>

<style scoped lang="scss">
.button-content {
  &--label {
  }
  &--icon {
    display: block;
    margin-right: 0.714em;
  }
}
</style>

<template>
  <span :class="`app-input-status app-input-status-${type}`">
    <svg-icon v-if="icon" :icon="icon" class="app-input-status-icon" />
    <span class="app-input-status-text">{{ label }}</span>
  </span>
</template>

<script>
import SvgIcon from "../ui/SvgIcon.vue";
export default {
  name: "AppInputStatus",
  components: { SvgIcon },
  props: {
    label: { type: String, required: true },
    type: {
      type: String,
      default: "success"
    }
  },

  computed: {
    icon() {
      switch (this.type) {
        case "success":
          return "checkmark";

        case "danger":
          return null;

        default:
          return null;
      }
    }
  }
};
</script>

<style lang="scss">
.app-input-status {
  font-size: 13px;
  display: flex;
  align-items: center;

  &-icon {
    margin-right: 0.86em;
  }

  &-danger {
    color: $color-danger;
  }

  &-success {
    color: $color-success;
  }
}

.app-input {
  &-material {
    .app-input-status {
      position: absolute;
      top: $material-input-height / 2;
      right: $field-padding-x;
      transform: translate(0, -50%);
      &-text {
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>


import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import {
  ComputedRef,
  defineComponent,
  PropType,
  computed,
  toRefs,
  watch
} from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AppToolbarTable from "@/components/Table/ts/AppToolbarTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { IQuestions } from "@/api/interfaces/questions/IQuestions";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";

type tableHeadCells = ITableHeadCell[];

export type questionsTableSort = {
  byName: sortItem;
};

export default defineComponent({
  name: "QuestionTable",
  components: { AppToolbarTable },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null,
    removingQuestion: null,
    editingQuestion: null,
    openForm: null
  },
  setup(props, { emit }) {
    // base

    const { byName } = toRefs(props.sort);

    const initialSortOptions: questionsTableSort = {
      byName: { order: sortOrders.turnOff, keyName: "title" }
    };

    // table model
    const questionsList: ComputedRef<IQuestions[]> = computed(
      () => props.questionsList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.parentViewConfiguration },
      initialSortOptions,
      model: questionsList,
      generateRows(questionsList: IQuestions[]): ITableBodyRow[] {
        return questionsList.map(
          (question: IQuestions): ITableBodyRow => {
            const { title, active, id } = question;
            return {
              cells: [
                {
                  sort: {
                    keyName: byName.value.keyName || "",
                    value: title,
                    order: byName.value.order
                  },
                  label: title
                },
                {
                  badge: {
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,
                    label: active ? "Active" : "Inactive"
                  }
                },
                {
                  userActions: true
                }
              ],
              id: id
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });
    const categoriesData: ComputedRef<ICategories[]> = computed(
      () => props.categoriesList
    );

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    watch(
      props.parentViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort,
      categoriesData
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },
    categoriesList: {
      type: Array as PropType<ICategories[]>,
      required: true
    },
    questionsList: {
      type: Array as PropType<IQuestions[]>,
      required: true
    },
    tableTitle: {
      type: String,
      default: ""
    },
    sort: {
      type: Object as PropType<questionsTableSort>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },

  computed: {
    tableHead(): tableHeadCells {
      const { byName } = this.currentSort;
      return [
        {
          sort: {
            keyName: byName.keyName,
            order: byName.order
          },
          size: "fluid",
          label: "name",
          id: "question-name"
        },
        {
          size: "sm",
          label: "Status",
          id: "question-status"
        },
        {
          size: "xs",
          label: "Action",
          id: "question-action"
        }
      ];
    }
  },

  methods: {
    deletingInfo(questionId: number) {
      this.$emit("removingQuestion", questionId);
    },
    openForm() {
      this.$emit("openForm");
    },

    editingInfo(questionId: number) {
      this.$emit("editingQuestion", questionId);
    }
  }
});

<template>
  <nav class="navigation">
    <ul class="main-menu">
      <li
        v-for="{ id, name, group, path, icon, active } in router"
        :key="id"
        class="main-menu-item"
      >
        <router-link v-if="!group" :to="path">
          <NavItem :icon="icon" :name="name" />
        </router-link>

        <Dropdown
          v-else
          :vars-hidden="toplevelDropdownHiddenStyles"
          :vars-shown="toplevelDropdownShownStyles"
          :parent-opened="active"
        >
          <template #dropdown-title>
            <NavItem :icon="icon" :name="name" />
          </template>

          <template #dropdown-content>
            <ul class="nested-menu">
              <li
                v-for="route in group"
                :key="route.id"
                class="nested-menu-item"
              >
                <router-link v-if="!route.group" :to="route.path">
                  <NavItem :is-top-level="false" :name="route.name" />
                </router-link>

                <Dropdown
                  v-else
                  :vars-hidden="nestedDropdownHiddenStyles"
                  :vars-shown="nestedDropdownShownStyles"
                  :parent-opened="route.active"
                >
                  // level 2 dropdown
                  <template #dropdown-title>
                    <NavItem :is-top-level="false" :name="route.name" />
                  </template>

                  <template #dropdown-content>
                    <ul class="nested-menu">
                      <li
                        v-for="nestedRoute in route.group"
                        :key="nestedRoute.id"
                        class="nested-menu-item"
                      >
                        <router-link
                          v-if="!nestedRoute.group"
                          :to="nestedRoute.path"
                        >
                          <NavItem
                            :is-top-level="false"
                            :name="nestedRoute.name"
                            @click="tempHandler(nestedRoute.name)"
                          />
                        </router-link>

                        <Dropdown v-else :parent-opened="route.active">
                          //** level 3 dropdown
                          <template #dropdown-title>
                            <NavItem
                              :is-top-level="false"
                              :name="nestedRoute.name"
                            />
                          </template>
                          <template #dropdown-content>
                            <ul class="nested-menu">
                              <li
                                v-for="thirdRoute in nestedRoute.group"
                                :key="thirdRoute.id"
                                class="nested-menu-item"
                              >
                                {{ thirdRoute.name }}
                              </li>
                            </ul>
                          </template>
                        </Dropdown>
                      </li>
                    </ul>
                  </template>
                </Dropdown>
              </li>
            </ul>
          </template>
        </Dropdown>
      </li>
    </ul>
  </nav>
</template>

<script>
import NavItem from "@/components/Navigation/NavItem";
import Dropdown from "@/components/ui/Dropdown/Dropdown";

export default {
  name: "NavigationList",
  components: { NavItem, Dropdown },
  props: {
    navigationList: {
      type: [Array, null],
      default: null
    }
  },

  data() {
    return {
      isRates: false,
      toplevelDropdownShownStyles: {
        background: this.styles.activeMenuItemGradient,
        color: this.styles.fontColor
      },
      toplevelDropdownHiddenStyles: {
        background: "transparent",
        color: this.styles.lightColor
      },

      nestedDropdownShownStyles: {
        background: this.styles.gradientPrimaryLight
      },
      nestedDropdownHiddenStyles: {
        background: "transparent"
      }
    };
  },

  computed: {
    router() {
      const routes = [...this.navigationList];
      let topLevel = routes.filter(route => !route.parentId);
      let secondLevel = routes.filter(route => {
        return topLevel.some(r => r.id === route.parentId);
      });

      routes.forEach(route => {
        const secondLevelParent = secondLevel.find(
          ({ id }) => id === route.parentId
        );

        if (secondLevelParent) {
          secondLevelParent.group
            ? secondLevelParent.group.push(route)
            : (secondLevelParent.group = [route]);
        }
      });

      secondLevel.forEach(route => {
        const topLevelParent = topLevel.find(({ id }) => id === route.parentId);

        topLevelParent.group
          ? topLevelParent.group.push(route)
          : (topLevelParent.group = [route]);
      });

      return topLevel;
    }
  },
  methods: {
    tempHandler(data) {
      this.isRates = true;
    }
  }
};
</script>

<style lang="scss">
nav {
  a {
    display: block;
    color: #fff;
    text-decoration: none;
  }
}

.main-menu-item,
.nested-menu-item .dropdown {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  overflow: hidden;
}

.main-menu-item {
  box-sizing: border-box;

  .dropdown {
    &-heading {
      padding-right: $sidebar-inner-padding-right;
    }

    .dropdown {
      .dropdown {
        &-body {
          padding-right: $sidebar-inner-padding-right;
        }
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  .nav-item--top-level {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  & > .dropdown {
    & > .dropdown-body {
      padding-left: $sidebar-item-padding-x;
      padding-bottom: 0;
    }

    &.shown {
      & > .dropdown-body {
        padding-bottom: 1em;
      }
    }
  }
}

.nested-menu-item {
  .dropdown .dropdown-body {
    padding-left: 0;
  }
}

.nested-menu {
  .dropdown,
  &-item > a {
    padding-left: $sidebar-item-padding-x;
  }

  .dropdown {
    transition: padding 0.25s, margin 0.25s;

    &.shown {
      padding-top: 0.7em;
      padding-bottom: 0.7em;

      margin-bottom: 1em;
      margin-top: 1em;
    }
  }

  &-item:first-child {
    .dropdown.shown {
      margin-top: 0;
    }
  }
}

.nav-item {
  &:not(.nav-item--top-level) {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
}

.main-menu-item {
  & > a {
    display: block;
  }

  & > * {
    padding-left: $sidebar-item-padding-x;
  }
}

.dropdown {
  & > .dropdown-heading {
    .nav-item {
      @include NavItem(false);
    }
  }
  &.shown {
    & > .dropdown-heading {
      .nav-item {
        @include NavItem(true);
        font-weight: 600;
      }
    }
  }
}

.nested-menu-item {
  color: $grey-100;
  a {
    color: $grey-100;
    background: transparent !important;
    &.router-link-active {
      color: $color-contrast-dark;
    }
  }

  .dropdown {
    color: $grey-100;

    &.shown {
      .dropdown-heading {
        color: $color-contrast-dark;
      }
    }
  }
}
</style>

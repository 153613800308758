<template>
  <Dialog
    id="remove-modal-confirm"
    title="null"
    :use-header="false"
    size="sm"
    class="delete-user-dialog"
    :opened="opened"
    @hide="$emit('hide')"
  >
    <div class="d-flex flex-column align-items-center text-center">
      <div class="avatar-wrap">
        <img :src="avatar" :alt="userName" />
      </div>

      <h3 class="user-name mt-3">{{ userName }}</h3>
      <p class="user-email">{{ userEmail }}</p>

      <p class="confirm-text mt-3 pt-2  ">
        You will loose all account data! <br />
        Are you sure you want to delete it?
      </p>

      <div class="w-100 mt-4 pt-3">
        <AppButton
          size="xl"
          type="danger"
          :loading="loading"
          @click="$emit('confirm')"
        >
          Yes, Delete
        </AppButton>
      </div>
      <div class="w-100">
        <AppButton size="xl" type="default-transparent">Cancel</AppButton>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/ui/Modal/Dialog/Dialog";
import AppButton from "@/components/ui/Buttons/AppButton";
export default {
  name: "DeleteUserDialog",
  components: { AppButton, Dialog },
  inheritAttrs: false,
  props: {
    opened: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    userPhoto: {
      type: [String, null],
      required: true
    },
    userName: { type: [String, null], required: true },
    userEmail: { type: [String, null], required: true }
  },
  emits: ["confirm", "delete"],
  computed: {
    avatar() {
      return this.userPhoto;
    }
  }
};
</script>

<style lang="scss">
.delete-user-dialog {
  .avatar-wrap {
    width: 4.28em;
    height: 4.28em;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .modal-body {
    padding-bottom: 0 !important;
  }
}
</style>

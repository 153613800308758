import { apiRequestType, IApiResponse } from "@/api/interfaces/IApi";
import { IDeviceBody } from "@/store/interfaces/suported-device/IDevice";
import {
  EditDataAbouts,
  IAboutsResponse
} from "@/api/interfaces/about-products/common";
import { IAbout } from "@/store/interfaces/about-product/IAbouts";

export const aboutProductsService = {
  async fetchAboutsList(
    request: apiRequestType,
    value: string
  ): Promise<IAboutsResponse> {
    const response = await request(`/${value}`, "get");
    const data: IAbout[] = <IAbout[]>response.data;

    return {
      ...response,
      data
    };
  },
  async editAbouts(
    request: apiRequestType,
    value: string,
    editData: EditDataAbouts
  ): Promise<IApiResponse> {
    const response = await request(
      `/${value}/${editData.id}`,
      "patch",
      editData
    );
    const data: IDeviceBody = <IDeviceBody>response.data;
    return {
      ...response,
      data
    };
  }
};


import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent, PropType } from "vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
const initialData = () => ({
  name: "",
  key: "",
  valid: false,
  parentTouched: false
});
export default defineComponent({
  name: "AddDirectoryServers",
  components: { AppInput, Dialog, AppButton, AppInputsValidationProvider },
  props: {
    modalTitle: { type: String, required: true },
    type: { type: String, default: "" },
    editData: {
      type: Object as PropType<IEsimDirectoryDefault | null>,
      required: true
    }
  },
  emits: {
    submit: null,
    hide: null
  },
  data() {
    return initialData();
  },
  watch: {
    editData: {
      handler(data: any) {
        if (!data) {
          Object.assign(this.$data, initialData());
        } else {
          Object.assign(this.$data, data);
        }
      },
      immediate: true
    }
  },
  methods: {
    cleanData() {
      Object.assign(this.$data, initialData());
      this.$emit("hide");
    },
    submitHandler() {
      this.parentTouched = true;
      const { name } = this;
      this.$emit("submit", { name });
      this.cleanData();
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    }
  }
});

<template>
  <Sidebar
    v-bind="$props"
    title="Usage logs"
    :opened="opened"
    :use-body-padding="false"
  >
    <template #tabs>
      <tabs v-model="selectedTab" class="sidebar--padding">
        <tab
          v-for="(tab, i) in tabs"
          :key="`t${i}`"
          class="tab"
          :val="tab"
          :label="tab"
          :indicator="true"
        />
      </tabs>

      <tab-panels
        v-model="selectedTab"
        :animate="false"
        class="sidebar--padding pt-4"
      >
        <tab-panel :val="tabs[0]">
          <div class="pb-5 mb-5 w-100">
            <div class="content-box ">
              <div class="d-flex align-items-center justify-content-between">
                <h2>Total calls</h2>

                <CalendarSelect />
              </div>
              <div ref="totalCallsChart" class="calls-chart"></div>
            </div>

            <div class="content-box mt-4">
              <div class="d-flex align-items-center justify-content-between">
                <h2>Calls by days</h2>

                <CalendarSelect />
              </div>
              <div ref="callsByDays" class="calls-chart"></div>
            </div>

            <div class="content-box mt-4 mb-5">
              <div class="d-flex align-items-center justify-content-between">
                <h2>Spent on calls</h2>

                <CalendarSelect />
              </div>
              <div ref="spentOnCalls" class="calls-chart">
                <div class="row align-items-center pt-5 mt-3">
                  <div class="col-4">
                    <CircleProgressbar
                      :count="callsSpentData.current"
                      :total="callsSpentData.total"
                      :stroke-width="15"
                      :tail-width="15"
                      tail-color="#4DBD7C"
                      stroke-fill="#438CEF"
                    >
                      <template #content>
                        <div
                          class="d-flex justify-content-center align-items-center circle-content"
                        >
                          <strong class="text-center">
                            <b class="lg d-block">134 mins</b>
                            <span class="secondary-text">total spent</span>
                          </strong>
                        </div>
                      </template>
                    </CircleProgressbar>
                  </div>
                  <div class="col-8 ps-5">
                    <div class="chart-legend">
                      <div class="row">
                        <div class="col-5">
                          <div
                            class="chart-legend-item d-flex align-items-center"
                          >
                            <div class="chart-legend-item--color bg-primary" />
                            <strong>Free</strong>
                          </div>
                          <strong class="mt-1">
                            <b>$ 0 </b><span class="secondary-text">(80 minutes)</span>
                          </strong>
                        </div>
                        <div class="col-5">
                          <div
                            class="chart-legend-item d-flex align-items-center"
                          >
                            <div
                              class="chart-legend-item--color bg-secondary"
                            />
                            <strong>Paid</strong>
                          </div>
                          <strong class="mt-1">
                            <b>$ 106 </b><span class="secondary-text">(54 minutes) </span>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-panel>

        <tab-panel :val="tabs[1]"></tab-panel>
        <tab-panel :val="tabs[2]"></tab-panel>
        <tab-panel :val="tabs[3]"></tab-panel>
      </tab-panels>
    </template>
  </Sidebar>
</template>

<script>
import Sidebar from "@/components/ui/Modal/Sidebar/Sidebar";
import { init } from "echarts";
import {
  callsYAxisConfig,
  GetTotalCallsChartConfig,
  legendConfig,
  tooltipConfig,
  xAxisStyle
} from "@/components/User/VirtualNumbers/NumberUsageModal/CallChart/GetCallsChartConfig";
import CalendarSelect from "@/components/ui/Calendar/CalendarSelect";
import { getChartGradient } from "@/utills/charts/styles/gradients";
import {
  primaryGradient,
  secondaryGradient
} from "@/utills/charts/styles/constants";
import { chartTooltipTemplate } from "@/components/User/VirtualNumbers/NumberUsageModal/CallChart/tooltip.tpl";
import CircleProgressbar from "@/components/ui/CircleProgressbar";

const tabs = ["Calls", "SMS", "MMS", "Fax"];

export default {
  name: "NumberUsageModal",
  components: { CircleProgressbar, CalendarSelect, Sidebar },
  props: { opened: { type: Boolean, required: true } },
  data() {
    return {
      tabs,
      selectedTab: tabs[0],
      totalCallsChart: null,
      callsData: [
        {
          name: "Outgouing",
          data: [82],
          callsDuration: 7
        },
        {
          name: "Incoming",
          data: [30],
          callsDuration: 12
        }
      ],

      callsSpentData: {
        current: 20,
        total: 100
      },
      charts: []
    };
  },

  computed: {
    totalCallsChartNode() {
      return this.$refs.totalCallsChart;
    }
  },

  watch: {
    opened(isOpen) {
      if (isOpen) {
        setTimeout(() => {
          if (Object.keys(this.$refs).length) {
            this.chartsInit();
          }
        }, 100);
      } else {
        this.chartsDestroy();
      }
    }
  },
  unmounted() {
    this.chartsDestroy();
  },

  methods: {
    chartsInit() {
      const { totalCallsChart, callsByDays } = this.$refs;

      if (totalCallsChart) {
        const callChartInstance = init(totalCallsChart, null, {
          renderer: "svg"
        });

        this.charts.push(callChartInstance);

        callChartInstance.setOption(
          GetTotalCallsChartConfig(
            this.callsData,
            this.callsData.map(({ name }) => name)
          )
        );
      }

      if (callsByDays) {
        const callByDaysInstance = init(callsByDays, null, {
          renderer: "svg"
        });

        this.charts.push(callByDaysInstance);

        callByDaysInstance.setOption({
          legend: legendConfig(["Outgoing", "Incoming"]),
          yAxis: {
            ...callsYAxisConfig,
            splitNumber: 4,
            interval: 10
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              color: "#828282"
            },
            ...xAxisStyle
          },

          tooltip: {
            ...tooltipConfig,
            formatter: ({ data }) => {
              return chartTooltipTemplate(`${data} minutes`);
            }
          },

          series: [
            {
              name: "Outgoing",
              data: [20, 15, 5, 30, 25, 3, 18],
              type: "bar",
              barWidth: 10,
              barGap: 0.1,
              itemStyle: {
                color: getChartGradient(...secondaryGradient),
                borderRadius: [12, 12, 0, 0]
              }
            },
            {
              name: "Incoming",
              data: [10, 25, 15, 20, 15, 7, 12],
              type: "bar",
              barWidth: 10,
              barGap: 0.1,
              itemStyle: {
                color: getChartGradient(...primaryGradient),
                borderRadius: [12, 12, 0, 0]
              }
            }
          ]
        });
      }
    },

    chartsDestroy() {
      if (this.charts.length) {
        setTimeout(() => {
          this.charts.forEach(chart => {
            chart.dispose();
          });

          this.charts = [];
        }, 200);
      }
    }
  }
};
</script>

<style lang="scss">
.calls-chart {
  min-height: 280px;
}

.small-text {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 10px;
  line-height: 1.2;
  max-width: 100%;

  * {
    max-width: 100%;
    display: block;
  }
  b + * {
    margin-top: 0.5em;
  }
}
.chart-tooltip {
  border: none !important;
  max-width: 103px;
  white-space: normal !important;
  padding: 0 !important;
  box-shadow: none !important;
  & > div:not(.chart-tooltip-content) {
    display: none !important;
  }

  .chart-tooltip-content {
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.2) !important;
    border-radius: 12px !important;
    padding: 1rem 1rem 1.2rem 1rem !important;
  }

  $arrow: "data:image/svg+xml,%3Csvg width='41' height='19' viewBox='0 0 41 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='5' y='7' width='31' height='9'%3E%3Crect x='5' y='7' width='31' height='9' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath d='M-222 26.9005C-222 20.2731 -216.627 14.9005 -210 14.9005H5.03549C7.61006 14.9005 10.1164 14.0724 12.1842 12.5387L18.6171 7.76742C20.0323 6.71775 21.9677 6.71775 23.3829 7.76742L29.8158 12.5387C31.8836 14.0724 34.3899 14.9005 36.9645 14.9005H46C52.6274 14.9005 58 20.2731 58 26.9005V415C58 421.627 52.6274 427 46 427H-210C-216.627 427 -222 421.627 -222 415V26.9005Z' fill='white'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='41' height='19' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='-2'/%3E%3CfeGaussianBlur stdDeviation='2.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A";

  .tooltip-arrow {
    content: "";
    background: url("#{$arrow}") no-repeat center / cover;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%) rotate(180deg);
    transform-origin: center;
    width: 13px;
    height: 4px;
    display: block;
  }
}

.circle-content {
  color: $color-contrast-dark;
  line-height: 1.2;

  b {
    font-size: 20px;
  }

  span {
    font-size: 12px;
    display: block;
  }
}

.chart-legend-item--color {
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 3px;
  margin-right: 5px;
}
</style>

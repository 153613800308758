import { INetworkGenerationItem } from "@/api/services/packages/esim/location-packages/types";
import { sortController } from "@/utills/sort";
import { sortOrders } from "@/enums/main/sortOrders";

const sortByAsc = sortController(sortOrders.asc);

export function networkGenerationsToString(
  networkGenerations: INetworkGenerationItem[]
): string {
  return (
    networkGenerations
      ?.map(
        ({ networkGeneration }: INetworkGenerationItem) =>
          networkGeneration.title
      )
      ?.sort((a: string, b: string) => {
        return sortByAsc(a, b);
      })
      ?.join(" ") || ""
  );
}

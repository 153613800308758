
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import { defineComponent, PropType } from "vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import CountrySelect from "@/components/ui/Country/CountrySelect.vue";
import { IEsimServer } from "@/api/services/profiles/profiles.types";
import { IDataType } from "@/api/services/packages/esim/location-packages/types";
import { mapDropDownSelect } from "@/hooks/dropdown/mapDropDown";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
const initialData = () => ({
  title: "",
  checkedDataTypes: [],
  checkedEsimServers: []
});
export default defineComponent({
  name: "AddEsimTypeModal",
  components: { AppInput, Dialog, AppButton, CountrySelect },
  props: {
    modalTitle: { type: String, required: true },
    type: { type: String, default: "" },
    esimServersFree: {
      type: Array as PropType<IEsimServer[]>,
      required: true
    },
    esimServersAll: {
      type: Array as PropType<IEsimServer[]>,
      required: true
    },
    dataTypes: {
      type: Array as PropType<IDataType[]>,
      required: true
    },
    editData: {
      type: Object as PropType<IEsimDirectoryDefault | null>,
      required: true
    }
  },
  emits: {
    submit: null,
    hide: null
  },
  data() {
    return { ...initialData(), mapedServers: this.esimServersFree };
  },
  computed: {
    getDataTypes() {
      return mapDropDownSelect(this.dataTypes, this.checkedDataTypes);
    },
    getServers() {
      return mapDropDownSelect(this.mapedServers, this.checkedEsimServers);
    }
  },
  watch: {
    editData: {
      handler(data: any) {
        if (!data) {
          Object.assign(this.$data, initialData());
          this.mapedServers = this.esimServersFree;
        } else {
          const mapedServers = this.esimServersFree.concat(data.server);
          Object.assign(this.$data, {
            title: data.title,
            mapedServers,
            checkedDataTypes:
              data.dataType?.map((el: IDataType) => el.id) ?? [],
            checkedEsimServers:
              data.server?.map((el: IEsimServer) => el.id) ?? []
          });
        }
      },
      immediate: true
    },
    esimServersFree: {
      handler(data: IEsimServer[]) {
        this.mapedServers = data;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    cleanData() {
      Object.assign(this.$data, initialData());
      Object.assign(this.$data.mapedServers, this.esimServersFree);
      this.$emit("hide");
    },
    selectDataTypeHandler(data: any) {
      this.checkedDataTypes = data;
    },
    selectEsimServerHandler(data: any) {
      this.checkedEsimServers = data;
    },
    submitHandler() {
      const { checkedDataTypes, checkedEsimServers, title } = this;
      this.$emit("submit", {
        title,
        servers: checkedEsimServers.map(el => ({ id: el })),
        dataType: checkedDataTypes.map(el => ({ id: el }))
      });
      this.cleanData();
    }
  }
});

import { ComputedRef, reactive, ref, Ref, watch } from "vue";
import { computed } from "@vue/reactivity";

export type pageFilter = {
  name: string;
  id: string;
  selected: boolean;
};

export type useBaseAppLayoutOptions = {
  initialSearchQuery?: string;
  filterOptions?: string[];
  initialSelectedFilterOptionIdx?: number;
  onFiltersChange?: () => void;
  onChangeSearchQuery?: () => void;
};

export interface IUseBaseAppLayout {
  filter: pageFilter[];
  selectedFilterOption: ComputedRef<pageFilter | null>;
  searchQuery: Ref<string>;

  searchHandler: (evt: Event) => void;
  onFilterSelect: (id: string) => void | Promise<void>;
}

function mapFilterOptions(
  options: string[],
  selectedIdx: number | undefined = 0
): pageFilter[] {
  return options.map(
    (option: string, idx: number): pageFilter => ({
      name: option,
      id: option,
      selected: idx === selectedIdx
    })
  );
}

export function useBaseAppLayout(
  options: useBaseAppLayoutOptions
): IUseBaseAppLayout {
  const {
    initialSearchQuery = "",
    filterOptions,
    initialSelectedFilterOptionIdx,
    onFiltersChange,
    onChangeSearchQuery
  } = options;

  const searchQuery: Ref<string> = ref(initialSearchQuery);
  let filter: pageFilter[] | null = null;

  if (filterOptions) {
    filter = reactive(
      mapFilterOptions(filterOptions, initialSelectedFilterOptionIdx)
    );
  }

  if (onFiltersChange instanceof Function) {
    const watchable = [searchQuery, filter];

    watchable.forEach((ref: any, idx: number) => {
      watch(ref, () => {
        if (idx === 0 && onChangeSearchQuery) {
          onChangeSearchQuery();
        }

        onFiltersChange();
      });
    });
  }

  return {
    filter: filter || [],
    searchQuery,
    selectedFilterOption: computed(() => {
      if (!filter) {
        return null;
      }

      return filter.find(({ selected }) => !!selected) || null;
    }),

    searchHandler: ({ target }: Event) => {
      if (target) {
        searchQuery.value = (target as HTMLInputElement).value;
      }
    },

    onFilterSelect: (id: string): void => {
      if (filter) {
        filter.forEach((option: pageFilter) => {
          option.selected = option.id === id;
        });
      }
    }
  };
}

export const TableCellMixin = {
  props: {
    id: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: true
    },

    size: {
      type: String,
      default: "xs"
    }
  }
};

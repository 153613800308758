import { IBaseQuery } from "@/api/interfaces/IApi";
import { Store, useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";

export type useBaseAppLayoutOptions = {
  viewConfiguration: IBaseQuery;
  filter: any;
};

type UserEditData = {
  email: string;
  fullName: string;
  id: number;
};
export const getAllUsers = async (store: any) => {
  await store.dispatch("fetchAllUsersList");
};
export function useUsers(options: useBaseAppLayoutOptions) {
  const { viewConfiguration, filter } = options;
  const store = useStore();
  function changeViewConfigurationHandler({
    page,
    perPage
  }: ITableViewConfiguration): void {
    if (
      page === viewConfiguration.page &&
      perPage !== viewConfiguration.perPage
    ) {
      viewConfiguration.page = 1;
    } else {
      viewConfiguration.page = page;
    }

    viewConfiguration.perPage = perPage;
  }
  async function requestAccountsHandler(
    showLoading = true,
    fullFilter?: any
  ): Promise<void> {
    showLoading && (await store.dispatch("setAppLoading", true));
    const { success, message } = await store.dispatch("fetchAccountsList", {
      query: viewConfiguration,
      filter: fullFilter || filter
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }
  return {
    changeViewConfigurationHandler,
    requestAccountsHandler
  };
}


import { defineComponent, h, PropType } from "vue";
import {
  ITableBodyRow,
  tableContentItem
} from "@/components/Table/ts/interfaces/TableStructure";
import TableRow from "@/components/Table/TableRow.vue";
import { mapTableRowsToTableContent } from "@/hooks/tables/modules/baseTable/mapTableRowsToTableContent";
import Accordion from "@/components/ui/Accourdion/Accordion.vue";
import AppTableBodyCell from "@/components/Table/ts/AppTableBodyCell.vue";

export default defineComponent({
  name: "AppExpendedTableBody",

  emits: {
    accordionOpen: null
  },

  props: {
    tableBody: {
      type: Array as PropType<ITableBodyRow[]>,
      required: true
    }
  },

  render() {
    const [slot]: any = this.$slots.default?.() || [];
    const [root] = slot?.children;
    const rowsList = root?.children || [];

    const slotsIsArray = Array.isArray(rowsList);

    const rows = mapTableRowsToTableContent(this.tableBody).map(
      (row, idx: number) => {
        return h(
          TableRow,
          {
            key: `expanded-row-${idx}`
          },
          {
            content: () => {
              return h(
                Accordion,
                { identifier: Number(rowsList[idx]?.props?.["data-id"]) },
                {
                  heading: () => {
                    return h(
                      "div",
                      {
                        class:
                          "d-flex align-items-center justify-content-between accordion-header"
                      },
                      row.cells.map(
                        (
                          { componentName, componentContent, cellProps },
                          cellIdx
                        ) => {
                          const componentInfo: {
                            name: tableContentItem;
                            content: tableContentItem | undefined;
                          } = {
                            name: componentName,
                            content: componentContent as tableContentItem
                          };

                          return h(AppTableBodyCell, {
                            ...cellProps,
                            key: `${idx}--${cellIdx}`,
                            componentInfo
                          });
                        }
                      )
                    );
                  },
                  body: () => {
                    return h(
                      "div",
                      { class: "table-accordion-body pb-5 pt-4" },
                      [slotsIsArray ? rowsList[idx] : ""]
                    );
                  }
                }
              );
            }
          }
        );
      }
    );

    return h("div", rows);
  },

  provide() {
    const onOpen = (id: number) => {
      this.$emit("accordionOpen", id);
    };

    return {
      onAccordionOpen(id: number) {
        onOpen(id);
      }
    };
  }
});

import { IValidatorResponse } from "@/validator/interfaces/IValidatorResponse";

export class ValidatorResponse implements IValidatorResponse {
  public status: boolean;
  public message: string;

  constructor(status: boolean, message: string) {
    this.status = status;
    this.message = message;
  }
}

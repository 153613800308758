
import SvgIcon from "../../../../ui/SvgIcon.vue";
import UserInfo from "@/components/UserInfo/UserInfo.vue";
import AppBadge from "../../../../ui/AppBadge.vue";
import { defineComponent, PropType } from "vue";
import AppTableBodyCell from "@/components/Table/ts/AppTableBodyCell.vue";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";

export default defineComponent({
  name: "AppPaymentsHeading",
  components: { AppTableBodyCell, AppBadge, UserInfo, SvgIcon },
  props: {
    date: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    paymentType: {
      type: String,
      required: true
    },
    user: {
      type: Object as PropType<IUserAccount>,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  }
});

<template>
  <div class="app-security-log d-flex">
    <div class="app-security-log--device">
      <svg-icon :icon="deviceType" />
      <div class="app-security-log--device--info">
        <h4>{{ deviceName }}</h4>
        <span class="d-block">{{ connectLocation }}</span>
        <span class="d-block">{{ connectTime }}</span>
      </div>
    </div>

    <div class="app-security-log--os d-flex flex-column align-items-end">
      <div class="app-security-log--os--name">{{ deviceOS }}</div>
      <div v-if="isCurrentSession" class="mt-1">
        <AppBadge type="base-primary" size="sm">
          current session
        </AppBadge>
      </div>
    </div>

    <div class="d-flex justify-content-end app-security-log--logout">
      <Btn
        v-if="isCurrentSession"
        type="danger-transparent"
        size="sm"
        @click="$emit('logout')"
      >
        Log Out
      </Btn>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
import AppBadge from "@/components/ui/AppBadge";
import Btn from "@/components/ui/Buttons/Button";
export default {
  name: "SecurityLog",
  components: { Btn, AppBadge, SvgIcon },
  props: {
    deviceType: {
      type: String,
      default: "phone",
      validator: device => {
        return ["phone", "table", "desktop"].indexOf(device);
      }
    },
    deviceName: {
      type: String,
      required: true
    },

    deviceOS: { type: String, required: true },
    isCurrentSession: { type: Boolean, default: false },
    connectLocation: {
      type: String,
      required: true
    },
    connectTime: {
      type: String,
      required: true
    }
  },
  emits: ["logout"]
};
</script>

<style scoped lang="scss">
$iconWidth: 40px;
.app-security-log {
  position: relative;
  padding: 20px 0;

  &:before {
    content: "";
    width: calc(100% - #{$iconWidth} - #{$content-box-x});
    height: 1px;
    position: absolute;
    bottom: 0;
    left: calc(#{$iconWidth} + #{$content-box-x});
    background-color: $border-color;
    display: block;
  }

  &:last-child {
    &:before {
      display: none;
    }
  }

  & > * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &--device {
    max-width: 270px;
    width: 55%;
    display: flex;
    .icon {
      font-size: 20px;
      margin-right: 0.8em;
    }
    h4 {
      font-size: 15px;
      margin-bottom: 0.3em;
      font-weight: 600;
    }

    span {
      font-size: 12px;
      color: $grey-100;
    }
  }
  &--os {
    width: 26%;
    padding-right: 25px;
  }
  &--logout {
    margin-left: auto;
    width: 16%;
  }
}
</style>

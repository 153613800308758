<template>
  <span
    :class="
      `${
        rounded ? 'badge-rounded' : ''
      } badge badge-type-${type} badge-size-${size}`
    "
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "AppBadge",
  props: {
    size: {
      type: String,
      validator: s => ["sm", "md"].indexOf(s),
      default: "sm"
    },
    type: {
      type: String,
      validator: t =>
        [
          "base",
          "base-primary",
          "light-primary",
          "secondary",
          "light-secondary",
          "danger",
          "primary",
          "success"
        ].indexOf(t),
      default: "base"
    },
    rounded: { type: Boolean, default: true }
  }
};
</script>

<style lang="scss">
.badge {
  padding: 0.3em 1em;
  width: max-content;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.5em;
  white-space: nowrap;
  &-size {
    &-sm {
      font-size: 10px;
    }

    &-md {
      font-size: 0.92rem;
      padding: 3px 1.25em;
      height: 25px;
    }
  }
  &.badge-rounded {
    border-radius: 100px;

    &.badge-size {
      &-sm {
        font-size: 10px;
      }

      &-md {
        font-size: 0.857rem;
        padding: 0.83em 1.25em;
      }
    }
  }
  span b {
    font-weight: 600;
    font-size: 1.25em;
  }

  &-type {
    &-base {
      background-color: rgba(#e0e0e0, 0.5);
      color: rgba($color-contrast-dark, 0.5);

      &-primary {
        background-color: rgba(#e0e0e0, 0.5);
        color: $color-primary-blue;
      }
    }
    &-secondary {
      color: $color-contrast-light;
      background-color: $color-success;
      line-height: 1;
    }
    &-danger {
      background-color: rgba($color-danger, 0.15);
      color: $color-danger;
    }
    &-primary {
      color: $color-contrast-light;
      background-color: $color-primary-blue;
    }
    &-light-primary {
      color: $color-primary-blue;
      background-color: rgba($color-primary-blue, 0.1);
    }

    &-light-secondary {
      color: $color-success;
      background-color: rgba($color-success, 0.1);
    }

    &-success {
      color: $color-success;
      background-color: rgba($color-success, 0.1);
    }
  }
}
</style>

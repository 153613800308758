
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent, PropType } from "vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
const initialData = () => ({
  title: "",
  amount: 0,
  parentTouched: false,
  valid: false
});
export default defineComponent({
  name: "AddTrafficAmountModal",
  components: {
    AppInput,
    Dialog,
    AppButton,
    AppInputsValidationProvider
  },
  props: {
    editData: {
      type: Object as PropType<IEsimDirectoryDefault | null>,
      required: true
    },
    modalTitle: { type: String, required: true },
    type: { type: String, default: "" }
  },
  emits: {
    submit: null,
    hide: null
  },
  data() {
    return initialData();
  },
  watch: {
    editData: {
      handler(data: any) {
        if (!data) {
          Object.assign(this.$data, initialData());
        } else {
          Object.assign(this.$data, data);
        }
      },
      immediate: true
    }
  },
  methods: {
    cleanData() {
      Object.assign(this.$data, initialData());
      this.$emit("hide");
    },
    submitHandler() {
      this.parentTouched = true;
      const { title, amount } = this;
      this.cleanData();
      this.$emit("submit", { title, amount: Number(amount) });
      this.cleanData();
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    }
  }
});


import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AvatarUpload from "@/components/ui/AvatarUpload.vue";
import { defineComponent, PropType } from "vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import TabsDialog from "@/components/ui/Modal/Dialog/TabsDialog.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
const tabs = ["Details", "Icon 1", "Icon 2"];

const initialData = () => ({
  tabs: tabs.map((el, idx) => ({ id: idx, title: el })),
  selectedTab: 0,
  active: false,
  name: "",
  planType: "",
  description: "",
  icon1: "",
  icon2: "",
  parentTouched: false,
  valid: false
});
export default defineComponent({
  name: "AddPlanModal",
  components: {
    AvatarUpload,
    AppInput,
    AppCheckbox,
    AppButton,
    TabsDialog,
    AppInputsValidationProvider
  },
  props: {
    opened: { type: Boolean, default: false },
    modalTitle: { type: String, default: "" },
    uploadHandler: { type: Function, required: true },
    editData: {
      type: Object as PropType<IEsimDirectoryDefault | null>,
      required: true
    }
  },
  emits: {
    submit: null,
    hide: null
  },
  data() {
    return initialData();
  },
  watch: {
    editData: {
      handler(data: any) {
        if (!data) {
          Object.assign(this.$data, initialData());
        } else {
          Object.assign(this.$data, data);
        }
      },
      immediate: true
    }
  },
  methods: {
    cleanData() {
      Object.assign(this.$data, initialData());
      this.$emit("hide");
    },
    async uploadFileHandler1(file: File) {
      const url = await this.uploadHandler({ file, type: "icon1" });
      this.icon1 = url;
    },
    async uploadFileHandler2(file: File) {
      const url = await this.uploadHandler({ file, type: "icon2" });
      this.icon2 = url;
    },
    submitHandler() {
      const { active, description, icon1, icon2, name, planType } = this;
      this.$emit("submit", {
        active,
        description,
        icon1,
        icon2,
        name,
        planType
      });
      this.cleanData();
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    }
  }
});

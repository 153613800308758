export function getDaysByDateTitle(title: string): number {
  const numberPart = parseInt(title);
  let daysCount = numberPart;
  let coef = 0;

  if (!numberPart) {
    return 0;
  }

  if (title.includes("hours")) {
    daysCount = 1;
  } else if (title.includes("month")) {
    coef = 30;
  } else if (title.includes("year")) {
    coef = 365;
  }

  if (coef > 0) {
    daysCount = numberPart * coef;
  }

  return daysCount;
}


import { defineComponent } from "vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { baseStoreActionResponse } from "@/store/interfaces/common";

type LoginData = {
  email: string;
  password: string;
  loading: boolean;
  valid: boolean;
};

export default defineComponent({
  name: "Login",
  components: { AppInputsValidationProvider, AppButton, AppInput },
  inject: ["showErrorNotification"],

  data() {
    return {
      email: "",
      password: "",
      loading: false,
      valid: true
    } as LoginData;
  },

  methods: {
    showNotification(message: string): void {
      this.$store.dispatch("showErrorNotification", message);
    },

    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    },

    async submitHandler() {
      if (!this.valid) {
        return;
      }

      this.loading = true;

      const {
        success,
        message
      }: baseStoreActionResponse = await this.$store.dispatch("login", {
        email: this.email,
        password: this.password
      });

      if (success) {
        this.$store.commit("resetRoutes");
        await this.$router.push("/accounts");
      } else if (message) {
        this.showNotification(message);
      }

      this.loading = false;
    }
  }
});

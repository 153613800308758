import { PromoCodesDto } from "./../../interfaces/promo-codes/promo-codes.dto";
import { apiRequestType, IBaseQuery } from "@/api/interfaces/IApi";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";

export const promoCodesSevice = {
  async fetchPromoCodes(request: apiRequestType, query: IBaseQuery) {
    const reqQuery = queryObjectToUrlString(query);
    return await request(`/promo-code/getAllPromoCodes?${reqQuery}`, "get");
  },
  async createPromo(request: apiRequestType, data: PromoCodesDto) {
    return await request(`/promo-code/createPromo`, "post", data);
  },
  async updatePromo(request: apiRequestType, id: number, data: PromoCodesDto) {
    return await request(`/promo-code/editPromoCode/${id}`, "patch", data);
  },
  async deletePromo(request: apiRequestType, id: number) {
    return await request(`/promo-code/deletePromoCode/${id}`, "delete");
  }
};

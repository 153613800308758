import {
  combineValidators,
  IAppValidators
} from "@/validator/combineValidators";
import {
  IField,
  IValidationResult,
  FormValidator,
  ValidatorInput
} from "@/validator/interfaces/common";
import { email } from "@/validator/validators/email";
import { equalTo } from "@/validator/validators/equalTo";
import { password } from "@/validator/validators/password";
import { name } from "@/validator/validators/name";
import { phone } from "@/validator/validators/phone";
import { required } from "@/validator/validators/required";
import { IValidatorResponse } from "./interfaces/IValidatorResponse";
import { notEmptyString } from "@/validator/validators/notEmptyString";
import { number } from "@/validator/validators/number";
import { price } from "@/validator/validators/price";

const validators: IAppValidators = combineValidators([
  { name: "email", validator: email },
  { name: "equal", validator: equalTo },
  { name: "password", validator: password },
  { name: "name", validator: name },
  { name: "phone", validator: phone },
  { name: "required", validator: required },
  { name: "notEmptyString", validator: notEmptyString },
  { name: "number", validator: number },
  { name: "price", validator: price }
]);

class ValidationResult implements IValidationResult {
  public error: boolean;
  public touched: boolean;
  public errorMessage: string;

  constructor(valid: boolean, touched: boolean, errorMessage: string) {
    this.error = valid;
    this.touched = touched;
    this.errorMessage = errorMessage;
  }
}

class Field implements IField {
  public node: HTMLInputElement;
  public rule: string;
  public value: string;
  public errorMessage: string;
  public id: string;
  public touched: boolean;
  public error: boolean;
  public equalToNode: HTMLElement | null;
  public required: boolean;
  public onChange: (valid?: IValidationResult) => void;
  public bindedOnChange: (evt: any) => any;
  public equalValue: string;

  constructor(
    inputNode: HTMLInputElement,
    onChange: (valid?: IValidationResult) => void = () => {}
  ) {
    const equalToId = inputNode.dataset.equalTo || "";
    this.node = inputNode;
    this.rule = inputNode.dataset.rule || "";
    this.value = inputNode.value;
    this.errorMessage = "";
    this.id = inputNode.id;
    this.touched = !!inputNode.value.length;
    this.error = false;
    this.required = inputNode.dataset.required === "true";

    this.equalToNode = document.getElementById(equalToId);
    this.equalValue = "";
    this.bindedOnChange = this.changeHandler.bind(this);
    this.node.addEventListener("input", this.bindedOnChange);
    this.node.addEventListener("focusout", () => {
      this.focusoutHandler();
    });

    if (this.equalToNode) {
      this.equalToNode.addEventListener("input", evt => {
        this.equalValue = (evt.target as HTMLInputElement).value;
        this.validate();
      });
    }

    this.onChange = onChange;

    this.onChange(this.validate());
  }

  changeHandler(event: InputEvent) {
    const target: EventTarget | null = event.target;

    if (target === null) {
      return;
    }

    this.value = (<HTMLInputElement>target).value || "";
    const valid = this.validate();
    this.onChange(valid);
  }

  private focusoutHandler(): void {
    this.touch();
    const valid = this.validate();
    this.onChange(valid);
  }

  get valid(): IValidationResult {
    return new ValidationResult(this.error, this.touched, this.errorMessage);
  }

  touch() {
    this.touched = true;
    this.onChange(this.validate());
  }

  private validate(): IValidationResult {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const validator = validators[this.rule];
    const emptyResult: IValidatorResponse = validators.required(this.value);

    if (!emptyResult.status) {
      if (!this.required) {
        this.error = false;
        this.errorMessage = "";
        return this.valid;
      }

      this.error = !emptyResult.status;
      this.errorMessage = emptyResult.message;

      return this.valid;
    }

    if (this.equalToNode) {
      if (this.equalValue === this.value) {
        this.error = false;
        this.errorMessage = "";
        return this.valid;
      } else {
        const { status, message } = validators.equal(
          this.value,
          this.equalValue
        );
        this.error = !status;
        this.errorMessage = message;
        if (!status) {
          return this.valid;
        }
      }
    }

    if (validator) {
      const { status, message } = validator(this.value);

      this.error = !status;
      this.errorMessage = message;
    }

    return this.valid;
  }

  public destroy() {
    this.node.removeEventListener("focusout", this.focusoutHandler);
    this.node.removeEventListener("input", this.bindedOnChange);
  }
}

type attachInputsType = (
  input: HTMLInputElement,
  onChange: (valid?: IValidationResult) => void
) => void;

export class Validator implements FormValidator {
  protected fields: ValidatorInput[];

  constructor() {
    this.fields = [];
  }

  shakeTree() {
    this.fields.forEach(field => {
      field.input.touch();
    });
  }

  attachInput(
    input: HTMLInputElement,
    onChange: (valid?: IValidationResult) => void
  ): void {
    const id = input.id;

    this.fields.push({
      id,
      input: new Field(input, onChange)
    });
  }

  removeInput(id: string): void {
    const { input: field } = this.fields.find(field => field.id === id) || {};
    this.fields = this.fields.filter(field => field.id !== id);

    if (field) {
      field.destroy();
    }
  }
}

export const validator = new Validator();

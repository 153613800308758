<template>
  <AppCalendar />
</template>

<script>
import AppCalendar from "@/components/ui/Calendar/AppCalendar";
export default {
  name: "CalendarSelect",
  components: { AppCalendar }
};
</script>

<style scoped></style>

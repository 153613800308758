export const phoneCodes = {
  state() {
    return [
      {
        code: "+1",
        countryId: "usa"
      },
      {
        code: "+233",
        countryId: "arg"
      },
      {
        code: "+155",
        countryId: "aus"
      },
      {
        code: "+987",
        countryId: "bra"
      },
      {
        code: "+123",
        countryId: "can"
      },
      {
        code: "+111",
        countryId: "ch"
      }
    ];
  },
  getters: {
    phoneCodes: state => state
  }
};
